import store from '../store'
import {isEmpty} from './validations';
import React from "react";
import moment from "moment";
import {APP_CONST} from "../core/AppConstants";
import {showToastMessage} from "../core/actions/appActions";
import PencilDisable from "../assets/img/pencilDisable.png";
import Pencil from "../assets/img/pencil.png";

export const searchOnWordBoundary = (input, options) => {
    let foundData = [];
    for (let i = 0; i < options.length; i++) {
        let label = options[i].label;
        let labelArr = label.split(" ");

        if (label.toLowerCase().startsWith(input.trim().toLowerCase())) {
            foundData.push(options[i])
        } else {
            let data = labelArr.find((element) => {
                return (element.toLowerCase().startsWith(input.trim().toLowerCase()))
            });
            if (!isEmpty(data)) {
                foundData.push(options[i])
            }
        }
    }

    return foundData
};

export const generateInboxUrlEndPoint = (inboxFilter) => {
    // const {handleFilterCount} = this.props
    let urlBuilder = [];
    let filterCount = 0;
    if (isEmpty(inboxFilter.account)) {

    } else {
        urlBuilder.push('accountId=' + inboxFilter.account.value);
        filterCount++
    }
    if (isEmpty(inboxFilter.toFilter)) {

    } else {
        urlBuilder.push('to=' + inboxFilter.toFilter);
        filterCount++
    }
    if (isEmpty(inboxFilter.fromFilter)) {

    } else {
        urlBuilder.push('from=' + inboxFilter.fromFilter);
        filterCount++
    }
    if (isEmpty(inboxFilter.startDate)) {
        urlBuilder.push('startDate=' + APP_CONST.FILTER_MIN_LIMIT_DATE);

    } else {

        let formatterSD = moment(inboxFilter.startDate).format('YYYY-MM-DD');
        urlBuilder.push('startDate=' + formatterSD);
        filterCount++
    }
    if (isEmpty(inboxFilter.endDate)) {
        urlBuilder.push('endDate=' + APP_CONST.FILTER_MAX_LIMIT_DATE);

    } else {
        let formatterED = moment(inboxFilter.endDate).format('YYYY-MM-DD');
        urlBuilder.push('endDate=' + formatterED);
        filterCount++
    }
    console.log('endpointUrl', inboxFilter.endDate);
    return {
        filterCount: filterCount,
        endpointUrl: urlBuilder.join('&')
    }

};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


export const isDesktopDevice = () => {
    let isDesktop = navigator.userAgent.match(/(Macintosh)|(Windows)/i);

    if (!isEmpty(isDesktop)) {
        return true;
    }
    return false;
};


const combineRecords = (data) => {
    // Initialize an empty array to store all records
    let allRecords = [];

    // Iterate over the data and combine records from each category
    for (const category in data) {
        allRecords.push.apply(allRecords, data[category]);
    }
    return allRecords;
};

export const getContentListName = (contents,listType) => {
    const allRecords = combineRecords(contents);

    if(listType === 'LIST'){
        return allRecords
    }else{
        let contentWithLabelValue = [];
        contentWithLabelValue.push({label: 'All', value: 'ALL'});
        allRecords.forEach((item, index) => {
            contentWithLabelValue.push({...item, label: item?.name, value: item?.name})
        });
        return contentWithLabelValue
    }
};

export const getRandomId = (idLength) => {
    let stringList = '0123456789abcdefghijklmnopqrstuvwxyz';
    return stringList.split('').map(function (v, i, a) {
        return i > idLength ? null : a[Math.floor(Math.random() * stringList.length)]
    }).join('');
};

// export const generateRandomNumber = (min, max) => {
//   return Math.floor((Math.random() * max) + min)
// };

//
// export const getUserAgent = () => {
//   return (window.navigator.userAgent) ? window.navigator.userAgent : 'user agent not available';
// };

export const isIosDevice = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroidDevice = () => {
    let platform = window.navigator.platform.toLowerCase();
    return /linux/.test(platform);
};

export const isWindowsSystem = () => {
    let platform = window.navigator.platform.toLowerCase();
    return /win32/.test(platform);
};

export const isChromeBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isChrome = /chrome/.test(userAgent);
    let isVender = (window.navigator.vendor === 'Google Inc.');
    return (isChrome && isVender && !isOperaBrowser());
};

export const isSafariBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isSafari = /safari/.test(userAgent);
    let isVender = (window.navigator.vendor === 'Apple Computer, Inc.');
    return (isSafari && isVender);
};

export const isOperaBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /opr/.test(userAgent);
};

export const isFirefoxBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /firefox/.test(userAgent);
};

export const isSafariOnIphoneDevice = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone/.test(userAgent) && /safari/.test(userAgent) && !(/crios/.test(userAgent));
};

export const isMobileInLandscape = () => {
    const isMobile = store.getState().appState.deviceInfo.isMobileDevice;
    if (isMobile) {
        return window.innerWidth > (1.5 * window.innerHeight);
    }

    return false;
};

export const isLandscape = () => {
    const isMobile = store.getState().appState.deviceInfo.isMobileDevice;
    if (isMobile) {
        return window.innerWidth > window.innerHeight;
    }

    return false;
};
export const getValuesInKBMB = (inputValue) => {
    let simulatedKBMBValue = inputValue;

    if (value === '0') {
        return ''
    }

    if (inputValue >= 1000 && inputValue < 1000000) {
        simulatedKBMBValue = (inputValue / 1000) + 'K';
    }

    if (inputValue >= 1000000 && inputValue < 100000000) {
        simulatedKBMBValue = (inputValue / 1000000) + 'M';
    }

    return simulatedKBMBValue
};

// export const isInStandaloneMode = () => {
//   return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
// };

// export const sleepThread = (ms) => {
//   return new Promise(resolve => setTimeout(resolve, ms || 1000));
// };

export const isValidEmailWithDot = (email, excludeSpecialCharValidation) => {
    let flag = false;
    let localEmail = email?.trim();
    if (isEmpty(localEmail)) {
        return false;
    }

    if (localEmail.includes(" ")) {
        flag = true;
    }

    if (excludeSpecialCharValidation) {
        // ignore special char validation
    } else {
        if (!(/^[a-zA-Z\d0-9@._-]*$/.test(localEmail))) {
            flag = true;
        }
    }

    if(!localEmail.includes('.')){
        return  false;
    } if(!localEmail.includes('@')){
        return  false;
    }

    if (localEmail.includes('@')){
        let name = localEmail.split('@')
        if(!name[0].includes('.')){
            return false
        }
    }

    let domainType = localEmail.split('@');

    if (isEmpty(domainType[0])) {
        return false
    }

    if (isEmpty(domainType[1])) {
        return false
    }

    return !flag;
};

export const isValidEmail = (email, excludeSpecialCharValidation) => {
    let flag = false;
    let localEmail = email?.trim();
    if (isEmpty(localEmail)) {
        return false;
    }

    if (localEmail.includes(" ")) {
        flag = true;
    }

    if (excludeSpecialCharValidation) {
        // ignore special char validation
    } else {
        if (!(/^[a-zA-Z\d0-9@._-]*$/.test(localEmail))) {
            flag = true;
        }
    }

    if (localEmail.includes('@')) {
        //do nothing
    } else {
        return false
    }

    // if(!localEmail.includes('.')){
    //   return  false;
    // } if(!localEmail.includes('@')){
    //   return  false;
    // }

    // let domainName = localEmail.substring(localEmail.indexOf("@") + 1, localEmail.lastIndexOf("."));
    // if(isEmpty(domainName)){
    //   return false;
    // }

    // let donaimType = localEmail.substring(localEmail.lastIndexOf(".") + 1,localEmail.length);
    let domainType = localEmail.split('@');

    if (isEmpty(domainType[0])) {
        return false
    }

    if (isEmpty(domainType[1])) {
        return false
    }

    // if(donaimType.length < 2) {
    //   return false
    // }
    return !flag;
};

//
// export const getDropdownSelectedValues = (value, isMulti) => {
//   if (isMulti) {
//     return value.map(item => {
//       return item.value
//     })
//   } else {
//     return value.value
//   }
// };

// export const formatValueForDropdown = (prefix, value, isMulti) => {
//   if (isMulti) {
//     if (isEmpty(value)) {
//       return [];
//     }
//     return value.map(item => {
//       return {
//         label: prefix + item,
//         value: item
//       }
//     })
//   } else {
//     if (isEmpty(value)) {
//       return null;
//     }
//     return {
//       label: prefix + value,
//       value: value
//     }
//   }
// };


// export const getBookmarkValue = (value) => {
//   if (value) {
//     return "myBookmark=true"
//   } else {
//     return "";
//   }
// };
//
// export const getKeywordValue = (value) => {
//   if (isEmpty(value)) {
//     return ""
//   } else {
//     return 'keyword=' + encodeURIComponent(value);
//   }
// };
//
// export const getSortByValue = (value) => {
//   if (isEmpty(value)) {
//     return ""
//   } else {
//     // return 'sortOrder=' + value;
//     return 'sortBy=' + value;
//   }
// };


export const getSortedObjectArray = (arr, sortBy) => {
    if (isEmpty(arr)) {
        return arr;
    }
    return arr.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        return 0;
    });
};


export const generateUrlEndpointsForListing = (isFilterDrawerOpen, filterUrl, keywordFilter, sortBy, bookmarkFilter, prevUrlEndpoints) => {

    let urlBuilder = [];

    if (isFilterDrawerOpen) {

        return prevUrlEndpoints;

    }

    if (isEmpty(filterUrl)) {
        // do nothing
    } else {
        urlBuilder.push(filterUrl);
    }

    if (bookmarkFilter) {
        urlBuilder.push('myBookmark=true');
    }

    if (isEmpty(keywordFilter)) {
        // do nothing
    } else {
        urlBuilder.push('keyword=' + encodeURIComponent(keywordFilter));
    }

    if (isEmpty(sortBy)) {
        // do nothing
    } else {
        urlBuilder.push('sortBy=' + sortBy);
    }

    return "" + urlBuilder.join('&');

};


export const isGroupAdmin = groupId => {
    let myManagedGroups = store.getState().groupState.myManagedGroups;
    let groupDetails = myManagedGroups.find(group => group.id === groupId);
    return !isEmpty(groupDetails);
    // let flag = false;
    // if (!isEmpty(myManagedGroups)) {
    //   for (group of myManagedGroups) {
    //     if (group.id === groupId) {
    //       flag = true;
    //     }
    //   }
    // } else {
    //   flag = false;
    // }
    // return flag;
};

export const getFormattedUserName = (user) => {

    if (isEmpty(user)) {
        return "";
    }

    let userName = "";

    if (isEmpty(user?.firstName)) {
        return userName;
    }

    userName = userName + (user?.firstName).trim();

    if (isEmpty(user?.lastName)) {
        return userName;
    }

    userName = userName + " " + (user?.lastName).trim();

    return userName;

};

// export const getCurrencySymbol = (currency) => {
//   let symbol;
//   switch (currency) {
//     case 'eur':
//     case 'EUR':
//       symbol = '€';
//       break;
//     case 'usd':
//     case 'USD':
//       symbol = '$';
//       break;
//     case 'gbp':
//     case 'GBP':
//       symbol = '£';
//       break;
//     case 'chf':
//     case 'CHF':
//       symbol = 'CHF';
//       break;
//     default:
//       symbol = '';
//       break;
//   }
//   return symbol;
// };

export const isValidPassword = (password) => {
    let flag = true;
    if (/^([^0-9]*)$/.test(password)) {
        flag = false;
    }
    if (!(/[^a-zA-Z\d]/.test(password))) {
        flag = false;
    }
    if (!/[A-Za-z]+/.test(password)) {
        flag = false;
    }
    return flag;
    // return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8}$/.test(password);
    ///^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
};

// export const getExternalUrl = (url) => {
//   // let urlLink = '';
//   if (!("" + url).toLowerCase().startsWith('http')) {
//     url = 'http://' + url
//   }
//   return url;
// };


// export const getCommaSeperatedString = (array) => {
//   let string = "";
//   let length = (array.length - 1);
//   array.forEach((value, index) => {
//     string = string + value;
//     if (index < length) {
//       string = string + ', ';
//     }
//   });
//   return string;
// };

export const trimFormValues = (dataObj) => {

    if (isEmpty(dataObj)) {
        return dataObj;
    }

    if (typeof dataObj === 'string') {
        return dataObj.trim();
    }


    if (typeof dataObj === 'object') {

        let newObj = dataObj;

        Object.keys(dataObj).forEach(function (key) {
            let oldString = dataObj[key];
            if (typeof oldString === 'string') {
                if (isEmpty(oldString)) {
                    newObj[key] = oldString;
                } else {
                    newObj[key] = oldString.trim();
                }
            }
        });

        return newObj;
    }

    return dataObj;

};

export const trimStringAttributes = (dataObj) => {

    if (isEmpty(dataObj)) {
        return dataObj;
    }

    if (typeof dataObj === 'string') {
        return dataObj.trim();
    }


    if (typeof dataObj === 'object') {

        Object.keys(dataObj).forEach(function (key) {
            if (typeof dataObj[key] === 'string' && !isEmpty(dataObj[key])) {
                dataObj[key] = dataObj[key].trim();
            }
        });

    }

    return dataObj;

};

// export const customDelay = async (ms) => {
//   return new Promise(resolve => setTimeout(resolve, ms));
// };
//
//
// export const getSplittedCountryCode = (value) => {
//   if (isEmpty(value)) {
//     return ""
//   }
//   if (typeof value !== 'string') {
//     return ""
//   }
//   return value.split('###')[1];
// };

export const getFileExtension = (fileNameOnServer) => {
    if (isEmpty(fileNameOnServer)) {
        return "";
    }
    return fileNameOnServer.split('.').pop().toLowerCase();
};


// export const getValidText = (string) => {
//   if (isEmpty(string)) {
//     return "";
//   }
//   return /^[A-z]+$/.test(string)
// };


export const isShowAppbar = (pathname) => {
    return !(pathname?.includes("/login") ||
        pathname?.includes("/emailVerification") ||
        pathname?.includes("/forgot-password") ||
        pathname?.includes("/reset-password") ||
        pathname?.includes("/register"));
};

// "#ff9999",
//     "#2c698d",
//     "#ffdb99",
//     "#f3c6a5",
//     "#ffb3bf",
//     "#cda5f3",
//     "#9999ff",
//     "#99ff99",
//     "#1ac0c6"

export const getRandomColors = (name) => {

    if (isEmpty(name)) {
        return "#ffdb99"
    }

    let firstLetter = name?.substring(0, 1).toLowerCase();

    switch (firstLetter) {
        case "a":
        case "o":
            return "#4080bf";
        case "b":
        case "p":
            return "#0099cc";
        case "c":
        case "q":
            return "#33cccc";
        case "d":
        case "r":
            return "#44B892";
        case "e":
        case "s":
            return "#B89244";
        case "f":
        case "t":
            return "#6C98FB";
        case "g":
        case "u":
            return "#FB6C98";
        case "h":
        case "v":
            return "#1DBD3E";
        case "i":
        case "w":
            return "#BD1D4C";
        case "j":
        case "x":
            return "#4E8BBE";
        case "k":
        case "y":
            return "#4EBEB9";
        case "l":
        case "z":
            return "#9A3499";
        case "m":
            return "#9A3466";
        case "n":
            return "#BA8156";
        default:
            return "#cda5f3";
    }
};


// export const handleExternalAppNavigation = (route) => {
//   let {REACT_APP_HOST_URL} = getAppEnvironmentVariables();
//   window.open(REACT_APP_HOST_URL + route, "_self");
// };

export const makeRandomName = () => {
    let text;

    let possible = [
        "Lorem ipsum dolor amet",
        "Lorem ipsum sit",
        "Lorem ipsum dolor",
        "Lorem ipsum dolor sit",
        "Loremipsum",
        "Lorem ipsum dolor amet",
        "Lorem ipsum dolor consectetur",
        "Lorem dolor sit amet",
        "Lorem ipsum",
        "Ipsum dolor sit amet",
    ];
    const random = Math.floor(Math.random() * possible.length);
    text = possible[random];
    return text;
};

export const priceWithCommas = (x) => {
    if (isEmpty(x)) {
        return "";
    }
    x = x.toString().split('.');
    let rsString = '';
    rsString += x[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (x[1]) {
        rsString += '.' + x[1].toString();
    } else {
        rsString += '.00'
    }
    return rsString;
};


export const getAlteredFormat = (format) => {
    // for date time format editor data
    if (isEmpty(format)) {
        return "";
    }
    if (format.includes('dd')) {
        format = format.replace('dd', 'DD');
    }
    if (format.includes('yyyy')) {
        format = format.replace('yyyy', 'YYYY');
    } else {
        if (format.includes('yy')) {
            format = format.replace('yy', 'YY');
        }
    }
    if (format.includes('hh') && !format.includes(' a')) {
        format = format + ' a';
    }
    return moment('01-01-2022').format(format);
};

export const getTrimmedUserName = (user) => {
    let name = getFormattedUserName(user);
    if (isEmpty(name)) {
        return ''
    }
    if (name?.length > 30) {
        return name.slice(0, 30) + '...'
    }
    return name
};

export const numberWithCommas = (x) => {
    if (x === 0) {
        return x;
    }
    if (!x) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//Data sorting by date
export const sortArray = (dataArray, aggregationType, columnName) => {
    if (!columnName) {
        return;
    }
    switch (aggregationType) {
        case 'DAILY':
        case 'WEEKLY':
            return sortDaily(dataArray.data, columnName);
        case 'MONTHLY':
            return sortMonthly(dataArray.data, columnName);
        case 'QUARTERLY':
            return sortQuarterly(dataArray.data, columnName);
        case 'ANNUALLY':
            return sortAnnually(dataArray.data, columnName);
        default:
            return dataArray;
    }
};

export const sortDaily = (data, columnName) => {
    data.sort((a, b) => {
        let x = moment(a[columnName]);
        let y = moment(b[columnName]);
        if (x.isSameOrBefore(y)) {
            return -1
        } else {
            return 1
        }
    });
    return data
};

export const sortMonthly = (data, columnName) => {
    let dataArray = data;
    dataArray.sort((a, b) => {
        let x = moment(a[columnName]);
        let y = moment(b[columnName]);
        if (x.isSameOrBefore(y)) {
            return -1
        } else {
            return 1
        }
    });
    return dataArray
};

export const sortAnnually = (data, columnName) => {
    let dataArray = data;
    dataArray.sort((a, b) => {
        let x = moment(a[columnName]);
        let y = moment(b[columnName]);
        if (x.isSameOrBefore(y)) {
            return -1
        } else {
            return 1
        }
    });
    return dataArray
};

export const sortQuarterly = (data, columnName) => {
    data.sort((a, b) => {
        if (a[columnName] > b[columnName]) {
            return 1
        }
        return -1
    })
};


export const getTrimmedValue = (value, trimChars) => {
    if (isEmpty(value)) {
        return ''
    }
    if (value.length > trimChars) {
        return value.slice(0, trimChars) + '...'
    }
    return value
};


export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const refreshWindowScrollElement = () => {
    window.scrollTo(0, 0)
};

export const getHeight = (windowHeight, isMobileView) => {
    if (isMobileView) {
        return windowHeight - 111
    } else {
        return windowHeight - 55
    }
};

export const getPermissions = (pageName, role) => {
    let permissionObj = {
        isDisabled: false,
        isAccessDeniedPage: false
    };

    switch (pageName) {
        case 'CAMPAIGN_LISTING' :
            if (role === 'ANALYST' || role === 'DESIGNER' || role === 'IT' || role === 'VIEW') {
                permissionObj = {
                    isDisabled: true
                }
            }
            break;
        case 'CAMPAIGN_VIEW' :
            if (role === 'ANALYST' || role === 'DESIGNER' || role === 'IT' || role === 'VIEW') {
                permissionObj = {
                    isDisabled: true
                }
            }
            break;
        case 'CONTENT_LISTING' :
            if (role === 'ANALYST' || role === 'MARKETING' || role === 'VIEW') {
                permissionObj = {
                    isDisabled: true
                }
            }
            break;
        case 'CONTENT_VIEW' :
            if (role === 'ANALYST' || role === 'MARKETING' || role === 'VIEW') {
                permissionObj = {
                    isDisabled: true
                }
            }
            break;
        case 'SEGMENT_LISTING' :
            break;
        case 'SEGMENT_VIEW' :
            break;
        case 'EMAIL_TEMPLATE_LISTING' :
            break;
        default:

    }

    return permissionObj
};

export const handleFileError = (uploadFileStatus, fileSize, fileLimit) => {

    if (uploadFileStatus === 'error_file_size') {
        let _size = fileSize;
        let sizeType = ['Bytes', 'KB', 'MB', 'GB'],
            i = 0;
        while (_size > 900) {
            _size /= 1024;
            i++;
        }

        let exactSize = (Math.round(_size * 100) / 100) + ' ' + sizeType[i];

        store.dispatch(showToastMessage('warning', 'You have exceeded maximum size of ' + exactSize))
    } else if (uploadFileStatus === 'rejected_max_files') {
        if (fileLimit < 2) {
            store.dispatch(showToastMessage('warning', 'You can upload only ' + fileLimit + ' file at a time.'))
        } else {
            store.dispatch(showToastMessage('warning', 'You can upload only ' + fileLimit + ' file\'s at a time.'))
        }
    } else if (uploadFileStatus === 'rejected_file_type') {
        store.dispatch(showToastMessage('warning', 'This file format is not supported. Please check file again.'))
    }
};

export const getTimeFromUtcDateFormat = (date, format) =>{
    return moment.utc(date).format(format)
};

export const convertUTCToLocalTime = (date, format) =>{
    if(isEmpty(format)){
        return moment.utc(date).local().format()
    }
    return moment.utc(date).local().format(format)
};

export const convertLocalToUTCTime = (date, format) =>{
    if(isEmpty(format)){
        return moment.utc(moment(date)).format()
    }
    return moment.utc(moment(date)).format(format)
};

export const getPencil = (isReadOnlyAccess,isPlanExpired) =>{
    if(isReadOnlyAccess || isPlanExpired){
        return PencilDisable;
    }
    else {
        return Pencil
    }
};

export const getListCount = (count, subText) => {
    if (isEmpty(count)) {
        return '0' + subText
    }
    if (count <= 1) {
        return  count + ' ' + subText
    } else {
        return  count + ' ' + subText + 's'
    }
};

export const getMaxValueForGraph = (data) => {
    if (isEmpty(data)) {
        return 0;
    }

    let maxValueFromArray = Math.max.apply(Math, data);

    let maxValueForAxis = maxValueFromArray * 1.025;

    if (maxValueForAxis <= 10) {
        return Math.ceil(maxValueForAxis / 10) * 10;
    } else {
        const magnitude = Math.pow(10, Math.floor(Math.log10(maxValueForAxis)));
        const rounded = Math.ceil(maxValueForAxis / magnitude) * magnitude;
        return rounded === maxValueForAxis ? rounded + magnitude : rounded;
    }

};

export const isInStandaloneMode = () => {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
};

export const isWhatsAppEnabled = () => {

    if(process.env.REACT_APP_WHATSAPP_CONFIGURATION === 'ENABLED') {
        return true
    }
    if(process.env.REACT_APP_WHATSAPP_CONFIGURATION === 'DISABLED') {
        return false
    }
    return false;
};


export const copyData = (value, message) => {
    navigator.clipboard.writeText(value);
    store.dispatch(showToastMessage('success', message));

};

export const setAccountIdForGAEvent = (account_id) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'account_id': account_id
    });
};

export const clearAccountIdForGAEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'account_id': ""
    });
};

export const checkTextHasDoubleNewLine = (text) => {
    text = '<div>'+text+'</div>';

    let matches = text.match(/(<p>(&nbsp;|\s)*<\/p>\s*){2,}/g);
    if (matches && matches.length > 0) {
        console.log("Found consecutive empty <p> tags:", matches);
        return true
    } else {
        console.log("No consecutive empty <p> tags found.");
        return false
    }
};

export const getDevice = () => {
    const userAgent = navigator.userAgent ||  navigator.vendor || window.opera;
    let device = '';

    if (/android/i.test(userAgent)) {
        device = 'Android';
    } else if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
        device = 'iPad';
    } else if (/iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        device = 'iOS';
    } else {
        device = 'unknown';
    }

    return device;
};

export const revertReplacedContentAttr = (input, personalizedAttributes) =>{
    if(isEmpty(input) || isEmpty(personalizedAttributes)){
        return input
    }
    personalizedAttributes.forEach(attr => {
        const regex = new RegExp('{{'+attr.value+'}}', 'g');
        input = input.replace(regex, attr.label);
    });
    return input;
};

export const replacePersonalizedAttributes = (input, personalizedAttributes) =>{
    if(isEmpty(input) || isEmpty(personalizedAttributes)){
        return input
    }
    personalizedAttributes.forEach(attr => {
        const regex = new RegExp(attr.label, 'g');
        input = input.replace(regex, '{{' + attr.value + '}}');
    });
    return input;
};

export const getformatedData = (input) => {
    if (isEmpty(input)) {
        return []
    }
    const output = Object.entries(input).map(([key, value]) => {
        return {label: '{{' + value + '}}', value: key};
    });

    output.sort((a, b) => a.label.localeCompare(b.label));

    return isEmpty(output) ? [] : output
};

export function removePersonalizedAttributes(str) {
    // Define a regular expression pattern to match triple curly braces {{{some_content}}}
    const tripleBracesPattern = /\{\{\{[^{}]*?\}\}\}/g;

    // Match triple curly brace content and store it in an array
    const tripleBracesMatches = str.match(tripleBracesPattern);

    // If there is triple brace content, replace it with unique placeholders
    if (tripleBracesMatches && tripleBracesMatches.length > 0) {
        // Replace each triple curly brace content with a unique placeholder
        for (let i = 0; i < tripleBracesMatches.length; i++) {
            const placeholder = `__TRIPLE_BRACES_${i}__`;
            str = str.replace(tripleBracesMatches[i], placeholder);
        }
    }

    // Define a regular expression pattern to match double curly braces {{some content}}
    const doubleBracesPattern = /\{\{[^{}]*?\}\}/g;

    // Replace any remaining double curly brace content with an empty string
    str = str.replace(doubleBracesPattern, '');

    // If there was triple brace content, restore it from the placeholders
    if (tripleBracesMatches && tripleBracesMatches.length > 0) {
        for (let i = 0; i < tripleBracesMatches.length; i++) {
            const placeholder = `__TRIPLE_BRACES_${i}__`;
            str = str.replace(placeholder, tripleBracesMatches[i]);
        }
    }

    return str;
};