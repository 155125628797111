import {isEmpty} from "../utils/validations";
import {createSlice} from '@reduxjs/toolkit';
import {getRandomId} from "../utils/helper";
import moment from "moment";

const appSlice = createSlice({
    name: 'inboxReducer',
    initialState: {
        inboxFilter: {
            account: null,
            fromFilter: "",
            toFilter: "",
            startDate: null,
            endDate: null,
        },
        FilterCount: 0,
        filteredEmails: {
            data: [],
            skipCount: 0,
            count: "",
            limitCount: 20,
            isReachedToEnd: false,
            apiInProgress: false
        },
        isShowLoader: false
    },
    reducers: {
        setFilterMails(state, action) {
            if (action.payload?.apiInProgress) {
                state.isShowLoader = true;
                state.filteredEmails = {
                    ...state.filteredEmails,
                    ...action.payload,
                };

                return;
            }
            if (action.payload.statusCode === 204) {
                state.isShowLoader = false;
                state.filteredEmails = {
                    ...state.filteredEmails,
                    data: [],
                    // skipCount: 0,
                    // count: "",
                    // limitCount: 10,
                    isReachedToEnd: true,
                    apiInProgress: false
                };
                return;
            }

            state.isShowLoader = false;
            state.filteredEmails = {
                ...state.filteredEmails,
                data: (action.payload.skipCount === 0)
                    ? action.payload.emails : state.filteredEmails.data.concat((isEmpty(action.payload.emails) ? [] : action.payload.emails)),
                skipCount: action.payload.skipCount,
                limitCount: action.payload.limitCount,
                isReachedToEnd: (isEmpty(action.payload.emails) || (action.payload?.emails?.length < action.payload.limitCount)),
                count: action.payload.count,
                apiInProgress: action.payload.apiInProgress,
            }
        },

        handleSelectedFilter(state, action) {

            let val2 = '';
            if ((action.payload.name === 'startDate') || (action.payload.name === 'endDate')) {
                if (isEmpty(val2)) {
                    val2 = action.payload.value;
                } else {
                    val2 = moment(action.payload.value).format('YYYY-MM-DD');
                }
            } else {
                val2 = action.payload.value;
            }
            state.inboxFilter = {
                ...state.inboxFilter,
                [action.payload.name]: val2
            }
        },

        refreshFilterMails(state) {

            state.isShowLoader = true;
            state.filteredEmails = {
                ...state.filteredEmails,
                data: [],
                skipCount: 0,
                count: "",
                limitCount: 10,
                isReachedToEnd: false,
                apiInProgress: false,
                reset: getRandomId(8),
            };
            state.inboxFilter = {
                account: null,
                fromFilter: "",
                toFilter: "",
                startDate: null,
                endDate: null,
            };
            state.isShowLoader = false;
        },

        handleFilterCount(state) {
            state.FilterCount.action.payload;
            state.isShowLoader = false;
        },

    }
});

export const {setFilterMails, handleSelectedFilter, refreshFilterMails, handleFilterCount} = appSlice.actions;
export default appSlice.reducer
