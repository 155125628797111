export const campaignContentKeays = [
  {
    "key": "LABEL-CAMPAIGNS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Campaigns",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN",
    "areaOfUsage": "campaign",
    "value": {
      en: "Campaign",
      hi: ""
    }
  },

  {
    "key": "LABEL-REMOVE-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "Are you sure you want to remove this template?",
      hi: ""
    }
  },
  {
    "key": "LABEL-DRAFT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Draft",
      hi: ""
    }
  },
  {
    "key": "LABEL-SCHEDULED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Scheduled",
      hi: ""
    }
  },
  {
    "key": "LABEL-PAUSED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Paused",
      hi: ""
    }
  },
  {
    "key": "LABEL-SENT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Sent",
      hi: ""
    }
  },
  {
    "key": "LABEL-CREATE-FIRST-CAMPAIGN",
    "areaOfUsage": "campaign",
    "value": {
      en: "Create your first campaign",
      hi: ""
    }
  },
  {
    "key": "LABEL-CREATE-A-CAMPAIGN",
    "areaOfUsage": "campaign",
    "value": {
      en: "Create a campaign",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-TITLE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Campaign title",
      hi: ""
    }
  },
  {
    "key": "LABEL-STATUS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Status",
      hi: ""
    }
  },
  {
    "key": "LABEL-RECIPIENTS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Recipients",
      hi: ""
    }
  },
  {
    "key": "LABEL-TOTAL-READ",
    "areaOfUsage": "campaign",
    "value": {
      en: "Total Read",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIONS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Actions",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIONS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Actions",
      hi: ""
    }
  },
  {
    "key": "LABEL-SEND-TEST-EMAIL",
    "areaOfUsage": "campaign",
    "value": {
      en: "Send Test Email",
      hi: ""
    }
  },
  {
    "key": "LABEL-PREVIEW",
    "areaOfUsage": "campaign",
    "value": {
      en: "Preview",
      hi: ""
    }
  },
  {
    "key": "LABEL-FROM",
    "areaOfUsage": "campaign",
    "value": {
      en: "From ",
      hi: ""
    }
  },
  {
    "key": "LABEL-TO",
    "areaOfUsage": "campaign",
    "value": {
      en: "To ",
      hi: ""
    }
  },
  {
    "key": "LABEL-SUBJECT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Subject ",
      hi: ""
    }
  },
  {
    "key": "LABEL-CLOSE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Close",
      hi: ""
    }
  },
  {
    "key": "LABEL-SEND",
    "areaOfUsage": "campaign",
    "value": {
      en: "Send",
      hi: ""
    }
  },
  {
    "key": "LABEL-SAVE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Save",
      hi: ""
    }
  },
  {
    "key": "LABEL-CANCEL",
    "areaOfUsage": "campaign",
    "value": {
      en: "Cancel",
      hi: ""
    }
  },
  {
    "key": "LABEL-ENTER-EMAIL-ADDRESS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Enter email address",
      hi: ""
    }
  },
  {
    "key": "LABEL-PREPARE-TO-SEND",
    "areaOfUsage": "campaign",
    "value": {
      en: "Prepare to Send",
      hi: ""
    }
  },
  {
    "key": "LABEL-SENDER-NAME",
    "areaOfUsage": "campaign",
    "value": {
      en: "Sender Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-SENDER-EMAIL",
    "areaOfUsage": "campaign",
    "value": {
      en: "Sender Email Address",
      hi: ""
    }
  },
  {
    "key": "LABEL-SENDER-EMAIL-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "Setup a new sender email address",
      hi: ""
    }
  },
  {
    "key": "LABEL-DESIGN",
    "areaOfUsage": "campaign",
    "value": {
      en: "Design",
      hi: ""
    }
  },
  {
    "key": "LABEL-CHOOSE-ANOTHER-TEMPLATE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Choose another template",
      hi: ""
    }
  },
  {
    "key": "LABEL-CHOOSE-A-TEMPLATE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Choose Template",
      hi: ""
    }
  },
  {
    "key": "LABEL-CHOOSE-ANOTHER-DESIGN",
    "areaOfUsage": "campaign",
    "value": {
      en: "Choose another design",
      hi: ""
    },
  },
  {
    "key": "LABEL-PREVIEW-TEXT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Preview Text",
      hi: ""
    }
  },
  {
    "key": "LABEL-PREVIEW-TEXT-CONTENT",
    "areaOfUsage": "campaign",
    "value": {
      en: "It is a short description of an email that appears in the recipient's inbox next to the subject line. It's one of the first things a recipient sees when an email arrives, and it can help grab their attention and encourage them to open the email.",
      hi: ""
    }
  },
  {
    "key": "LABEL-END-BY",
    "areaOfUsage": "campaign",
    "value": {
      en: "End by",
      hi: ""
    }
  },
  {
    "key": "LABEL-TIME-SLOTS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Time slots",
      hi: ""
    }
  },
  {
    "key": "LABEL-START-TIME",
    "areaOfUsage": "campaign",
    "value": {
      en: "Start time",
      hi: ""
    }
  },
  {
    "key": "LABEL-END-TIME",
    "areaOfUsage": "campaign",
    "value": {
      en: "End time",
      hi: ""
    }
  },
  {
    "key": "LABEL-TOTAL-CUSTOMERS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Total Customers in Segment",
      hi: ""
    }
  },
  {
    "key": "LABEL-SPLIT-BATCHES",
    "areaOfUsage": "campaign",
    "value": {
      en: "Split in Batches of",
      hi: ""
    }
  },
  {
    "key": "LABEL-EACH",
    "areaOfUsage": "campaign",
    "value": {
      en: "each",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "When do you want to send this campaign?",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-FOR",
    "areaOfUsage": "campaign",
    "value": {
      en: "Campaign For",
      hi: ""
    }
  },
  {
    "key": "LABEL-START-FROM",
    "areaOfUsage": "campaign",
    "value": {
      en: "Start from",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIVATE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Activate",
      hi: ""
    }
  },
  {
    "key": "LABEL-TOTAL-RECIPIENTS",
    "areaOfUsage": "campaign",
    "value": {
      en: "Total Recipients",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIVATED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Activated",
      hi: ""
    }
  },
  {
    "key": "LABEL-DURATION",
    "areaOfUsage": "campaign",
    "value": {
      en: "Duration",
      hi: ""
    }
  },
  {
    "key": "LABEL-FROM-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "Who is sending this campaign?",
      hi: ""
    }
  },
  {
    "key": "LABEL-TO-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "Who are you sending this campaign to?",
      hi: ""
    }
  },
  {
    "key": "LABEL-BYDAY",
    "areaOfUsage": "campaign",
    "value": {
      en: "By Day",
      hi: ""
    }
  },
  {
    "key": "LABEL-BYBATCH",
    "areaOfUsage": "campaign",
    "value": {
      en: "By Batch",
      hi: ""
    }
  },
  {
    "key": "LABEL-TOTAL-EMAIL",
    "areaOfUsage": "campaign",
    "value": {
      en: "Total Emails",
      hi: ""
    }
  },
  {
    "key": "LABEL-SENT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Sent",
      hi: ""
    }
  },
  {
    "key": "LABEL-DELIVERED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Delivered",
      hi: ""
    }
  },
  {
    "key": "LABEL-VIEWED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Viewed",
      hi: ""
    }
  },
  {
    "key": "LABEL-CLICKED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Clicked",
      hi: ""
    }
  },
  {
    "key": "LABEL-BOUNCED",
    "areaOfUsage": "campaign",
    "value": {
      en: "Bounced",
      hi: ""
    }
  },
  {
    "key": "LABEL-DATE",
    "areaOfUsage": "campaign",
    "value": {
      en: "Date",
      hi: ""
    }
  },
  {
    "key": "LABEL-BACTH",
    "areaOfUsage": "campaign",
    "value": {
      en: "Batch",
      hi: ""
    }
  },
  {
    "key": "LABEL-DAYTIME",
    "areaOfUsage": "campaign",
    "value": {
      en: "Day and Time",
      hi: ""
    }
  },
  {
    "key": "LABEL-ENTER-NAME",
    "areaOfUsage": "campaign",
    "value": {
      en: "Enter Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-ENTER-SUBJECT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Enter subject",
      hi: ""
    }
  },
  {
    "key": "LABEL-LIMIT_ERROR",
    "areaOfUsage": "campaign",
    "value": {
      en: 'Please enter only 30 characters.',
      hi: ""
    }
  },
  {
    "key": "LABEL-NAME-LIMIT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Only 50 characters allowed.",
      hi: ""
    }
  },
  {
    "key": "LABEL-SUBJECT-LIMIT",
    "areaOfUsage": "campaign",
    "value": {
      en: 'Please enter only 100 characters.',
      hi: ""
    }
  },
  {
    "key": "LABEL-PREVIEW-LIMIT",
    "areaOfUsage": "campaign",
    "value": {
      en: 'Please enter only 60 characters.',
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-OBJ",
    "areaOfUsage": "campaign",
    "value": {
      en: "Enter campaign name",
      hi: ""
    }
  },
  {
    "key": "LABEL-ENTER-EMAIL",
    "areaOfUsage": "campaign",
    "value": {
      en: "Enter email",
      hi: ""
    }
  },
  {
    "key": "LABEL-EMAIL-LIMIT",
    "areaOfUsage": "campaign",
    "value": {
      en: "10 test emails are allowed.",
      hi: ""
    }
  },
  {
    "key": "LABEL-EDIT",
    "areaOfUsage": "campaign",
    "value": {
      en: "Edit",
      hi: ""
    }
  },
  {
    "key": "LABEL-EDIT-MSG",
    "areaOfUsage": "campaign",
    "value": {
      en: "This campaign is already active. If you edit it, it will move to draft status again. Are you sure you want to edit the campaign?",
      hi: ""
    }
  },

];
