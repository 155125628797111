import {
    getAppEnvironmentVariables,
    getAuthorizationToken,
    httpDelete,
    httpGet,
    httpPost,
    httpPut
} from "../utils/httpHelper";
import {APP_CONST} from "../core/AppConstants";
import store from "../store";
import {isEmpty} from "../utils/validations";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import * as log from "loglevel";
import {
    getAccountDetails2Data,
    getAllAccountsData, getMyPendingInvitationData, refreshAccount, resetAccount,
    resetAllAccount,
    resetGroupListData,
    setAccountAdminData, setAccountSelected, setAccountUser, setFireAccountCall, setSwitchBtn, setYearAndMonth
} from "./accountManagementReducer";
import {joinAccountRoom} from "../utils/socket";
import {setAccountIdForGAEvent} from "../utils/helper";
//Account Api's ..

export const getAllAccounts = () => () => {
    let url = '/service/provisioning/account/all?skipCount=0&limitCount=' + APP_CONST.MAX_LIMIT_COUNT;

    return httpGet(url)
        .then(res => {
            if(res.success){
                if(!isEmpty(res.data)){
                    store.dispatch(getAllAccountsData(res.data));
                }
            }
            return res;

        })
};

export const getAllCampaignMatrix = (accountId, handleSystemErrorInComponent,typeOfCampaign,mode) => {
    let url;
    if(typeOfCampaign === "EMAIL"){
        url = '/service/campaignManagement/campaign/dashboard/campaignMetrics?mode='+mode+"&type="+typeOfCampaign;
    }else{
        url = '/service/campaignManagement/campaign/dashboard/campaignMetrics?mode=LIVE&type='+typeOfCampaign;
    }

    return httpGet(url,accountId, false, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getPerformanceOfPast2Campaigns = (accountId, handleErrorInComponent, handleSystemErrorInComponent) => {
    let url = '/service/campaignManagement/campaign/completedStats?mode=LIVE&skipCount=0&limitCount=2';

    return httpGet(url, accountId, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getPerformanceOfPastCampaignsByMonth = (accountId, handleErrorInComponent, handleSystemErrorInComponent) => {
    let url = '/service/campaignManagement/campaign/dashboard/campaignsPerformance?interval=MONTH&mode=LIVE';

    return httpGet(url, accountId, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getCreateAccountValidation = () => () => {
    let url = '/service/provisioning/account/creation';

    return httpGet(url)
        .then(res => {
            return res;
        })
};

export const getMyPendingInvitation = () => {
    let url = '/service/provisioning/account/invite/pending/all';
    // let url = '/service/provisioning/account/invite/pending/all?skipCount=0&limitCount='+APP_CONST.MAX_LIMIT_COUNT;

    return httpGet(url)
        .then(res => {
            if (res?.success) {
                store.dispatch(getMyPendingInvitationData(res.data));
            }
            return res;
        })
        .catch(err => {
                log.error({function: 'getMyPendingInvitation', error: err, errorDescription: 'Error caught in Catch'});
                store.dispatch(showSystemErrorBlock());
            }
        );
};

export const acceptInvite = (data) => {
    let url = '/service/provisioning/account/invite/accept';

    return httpPost(url, data, '').then((res) => {
        return res;
    })
};

//Member List API's

export const getUserList = (id, filterUrl) => {

    let url = '/service/provisioning/member/all?skipCount=0&limitCount=' + APP_CONST.MAX_LIMIT_COUNT;

    if (isEmpty(filterUrl)) {
        // do nothing
    } else {
        url = url + '&' + filterUrl
    }

    return httpGet(url, id)
        .then(res => {
            return res;

        }).catch(err => {
            log.error({function: 'getUserList', error: err, errorDescription: 'Error caught in Catch'});
            store.dispatch(showSystemErrorBlock());
            return null;

        });
};


export const inviteUser = (data, id) => {
    return httpPost('/service/provisioning/account/invite', data, id)
        .then((res) => {
            return res
        })
};

export const resendInvite = (data, id) => {
    return httpPost('/service/provisioning/account/invite/resend', data, id)
        .then((res) => {
            return res
        })
};

export const deleteUser = (memberId, accountId) => {
    let url = '/service/provisioning/member?email=' + memberId;

    return httpDelete(url, '', accountId)
        .then(res => {
            return res
        })
        .catch((err) => {
            log.error({function: 'deleteUser', error: err, errorDescription: 'Error caught in Catch'});
            store.dispatch(showSystemErrorBlock());
        })
};

export const editAccount = (accountId, data) => {
    return httpPut('/service/provisioning/account', data, accountId)
        .then(res => {
            return res;
        })

};

export const resetAccountsList = () => (dispatch) => {
    dispatch(resetAllAccount());
};

export const setAccountAdmin = () => (dispatch) => {
    dispatch(setAccountAdminData());
};


export const getAccountDetails = (id) => {
    let url = '/service/provisioning/account?id=' + id;
    return httpGet(url, id)
};

export const resetAccountData = () => (dispatch) => {
    dispatch(resetAccount());
};


export const getUsersInfo = (id, accountId) => {
    let url = '/service/provisioning/member?id=' + id;
    return httpGet(url, accountId)
};

export const assignRoleToUser = (data, accountId) => {
    return httpPost('/service/provisioning/memberRole/add', data, accountId)
};
export const assignGroupToUser = (data, accountId) => {
    return httpPost('/service/provisioning/groupMember', data, accountId)
        .then((res) => {
            return res;
        })
};

//role
export const getRolesData = (accountId) => {
    let url = '/service/provisioning/role/all?skipCount=0&limitCount=' + APP_CONST.MAX_LIMIT_COUNT;
    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const updateRole = (data, accountId) => {
    let url = '/service/provisioning/role';
    return httpPut(url, data, accountId)
        .then((res) => {
            return res;
        })
};

export const getRoleDetails = (roleId, accountId) => {
    let url = '/service/provisioning/role?id=' + roleId;
    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};
export const assignGroupToRole = (data, accountId) => {
    return httpPost('/service/provisioning/groupRole', data, accountId)
        .then((res) => {
            return res;

        })
        .catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
            return null;
        })
};

export const deleteRole = (roleId, accountId) => {
    let url = '/service/provisioning/role?id=' + roleId;
    return httpDelete(url, '', accountId)
        .then((res) => {
            return res;
        })

};

export const removeRoleFromUser = (memberId, roleId, accountId) => {
    let url = '/service/provisioning/memberRole?memberId=' + memberId + '&roleId=' + roleId;
    return httpDelete(url, '', accountId)
};

export const removeGroupFromRole = (groupId, roleId, accountId) => {
    let url = '/service/provisioning/groupRole?roleId=' + roleId + '&groupId=' + groupId;
    return httpDelete(url, '', accountId)
        .then((res) => {
            return res;

        })
        .catch(err => {
            store.dispatch(showSystemErrorBlock(err))
        });
};

export const removeGroupFromUser = (memberId, groupId, accountId) => {
    let url = '/service/provisioning/groupMember?memberId=' + memberId + '&groupId=' + groupId;
    return httpDelete(url, '', accountId)
        .then((res) => {
            return res;
        })
        .catch(err => {
            store.dispatch(showSystemErrorBlock(err));
            return null;
        });
};


export const getGroupList = (id) => {

    return httpGet('/service/provisioning/group/all?skipCount=0&limitCount=' + APP_CONST.MAX_LIMIT_COUNT, id)
        .then(res => {
            return res;
        })
};

export const getLatestSegments = (id, handleErrorInComponent, handleSystemErrorInComponent) => {

    return httpGet('/service/segmentation/segment/all?limitCount=3', id, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getAllSegments = (id, handleErrorInComponent, handleSystemErrorInComponent) => {

    return httpGet('/service/segmentation/segment/all', id, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const activeCampaigns = (id, handleErrorInComponent) => {

    return httpGet('/service/campaignManagement/campaign/dashboard/campaignsInExecution', id, handleErrorInComponent)
        .then(res => {
            return res;

        })
};


export const getUpcomingEvents = (accountId, handleErrorInComponent, handleSystemErrorInComponent) => {
    let url = '/service/provisioning/account/dashboard/upcomingEvents';

    return httpGet(url, accountId, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;

        })
};

export const deleteGroup = (id, accountId) => {
    return httpDelete('/service/provisioning/group?id=' + id, '', accountId);
};


export const createGroup = (data, accountId) => {
    let url = '/service/provisioning/group';
    return httpPost(url, data, accountId)
        .then((res) => {
            if(res.success && !isEmpty(res.data)){
                resetGroupList();
            }
            return res;

        }).catch(err => {
            log.error({function: 'createGroup', error: err, errorDescription: 'Error caught in Catch'});
            store.dispatch(showSystemErrorBlock());
            return null
        });
};


export const resetGroupList = () => {
    store.dispatch(resetGroupListData)
};


export const getGroupById = (groupId, accountId) => {

    return httpGet('/service/provisioning/group?id=' + groupId, accountId)
        .then((res) => {
            return res;

        })

};

export const editGroup = (data, accountId) => {
    return httpPut('/service/provisioning/group', data, accountId)
        .then(res => {
            return res;
        })

};


export const getAccountDetails2 = (accountId) => {
    let profile = store.getState().userState.profile;
    let API = '/service/provisioning/account?id=' + accountId;

    return httpGet(API, accountId)
        .then(res => {
            if (res.success){
                const {profile} = store.getState().userState;
                setAccountIdForGAEvent(accountId);
                store.dispatch(getAccountDetails2Data({
                        data: res.data,
                        isAccountAdmin: (res.data?.memberRole?.name === 'OWNER')
                    }
                ));
                joinAccountRoom(res.data?.id)
            }
            if (res.statusCode===403){
                localStorage.removeItem("account_" + [profile?.email]);
            }
            return res;
        })
};


export const leaveAccountData = (accountId) => {
    let url = '/service/provisioning/member/leave';
    return httpPost(url, undefined, accountId);
};

export const removeAccount = (accountId) => {
    let url = '/service/provisioning/account';
    return httpDelete(url, '', accountId).then(res => {
        return res;
    })
};


export const isShowAccountRelatedUI = (ignoreRoute) => {

    const {currentPathname} = store.getState().appState;

    let isDrawerVisible = false;

    if (ignoreRoute) {
        isDrawerVisible = true;
        return isDrawerVisible;
    }


    if (currentPathname.startsWith('/accounts')) {
        isDrawerVisible = true;
    }

    let modifiedRouteName = currentPathname.replace('/accounts', "");

    if (isEmpty(modifiedRouteName)) {
        isDrawerVisible = false;
    } else {
        let routes = [
            // '/invite/accept',
            '/users-roles-groups',
            '/roles',
            '/groups',
            '/subscriptions'
        ];

        routes.forEach(route => {
            if (currentPathname.includes(route)) {
                isDrawerVisible = true;
            }
        });

    }


    return isDrawerVisible;

};

export const getMySubscriptions = (accountId, latest, handleErrorOutside) => {
    let url;
    if (!isEmpty(latest)) {
        url = '/service/provisioning/subscription/all?latest=' + latest;
    } else {
        url = '/service/provisioning/subscription/all';
    }
    return httpGet(url, accountId, handleErrorOutside)
        .then((res) => {
            return res;
        })
};

export const setSwitchButton = (button) => {
    store.dispatch(setSwitchBtn(button))
};

//get organisation type and organisation industry
export const getOrganisationData = (accountId) => {
    let url = '/service/provisioning/account/kyc/types';
    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })

};

const uploadFile = async (binaryFile, fileType, accountId) => {
    for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL;
    if (fileType.includes('image')) {
        url += '/service/provisioning/account/media/image';
    } else {
        url += '/service/provisioning/account/media/document';
    }

    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response => {
            statusCode = response.status;
            return response.json()
        })
        .then((res) => {

            if (res) {

                if (statusCode === 200) {
                    return {
                        filePath: (res.filePath) ? res.filePath : '',
                        fileName: (res.fileName) ? res.fileName : '',
                    }
                }
                if (statusCode === 400) {
                    store.dispatch(showToastMessage('warning', "Upload only .pdf, .png, .jpeg and .docx format" +
                        " files."));
                    return false;
                } else {
                    store.dispatch(showToastMessage('danger', res.__error));
                    return null;
                }
            }
        }).catch(() => {
            store.dispatch(showSystemErrorBlock());
        });
};


export async function uploadFiles(files, accountId) {
    let res = [];
    if (files) {
        // store.dispatch(setAppLoader(true));
        for (let index = 0; index < files.length; index++) {
            if (typeof document != 'undefined') {
                // I'm on the web!
                const formData = new FormData();
                formData.append('file', files[index]);

                let filePathVal;
                if (typeof files[index] === 'string') {
                    filePathVal = files[index];
                } else {
                    filePathVal = await uploadFile(formData, files[index].type, accountId);
                }
                if (filePathVal) {
                    res.push(filePathVal);
                } else {
                    if (filePathVal === false){
                        res = false;
                        break;
                    }
                    store.dispatch(showToastMessage('danger', "Failed to upload"));
                    res = false;
                    break;
                }
            }


        }
    }
    // store.dispatch(setAppLoader(false));
    return res;
}


export const submitKyc = (accountData, accountId) => {
    let url = '/service/provisioning/account/kyc';

    return httpPost(url, accountData, accountId)
        .then((res) => {
            return res;
        })
};

export const submitAccountContactAdd = (accountId, accountData) => {

    let url = '/service/provisioning/account/contact';

    return httpPost(url, accountData, accountId)
        .then((res) => {
            return res;

        })
};

export const submitAccountContactEdit = (EditId, accountId, accountData) => {
    let url = '/service/provisioning/account/contact';
    return httpPut(url, accountData, accountId)
        .then((res) => {
            return res;

        })
};

export const onAccountSelection = (isAccountSelect) => {
    store.dispatch(
        setAccountSelected(isAccountSelect)
    )
};

export const onYearToMonthChange = (isAccountSelect) => {
    store.dispatch(
        setYearAndMonth(isAccountSelect)
    )
};


//quickstart
export const importData = (accountId, data) => {
    let url = '/service/customerData/contentImport/quickImport';
    return httpPost(url,data,accountId)
        .then((res) => {
            return res;
        })
};

export async function uploadCSVFile(data,accountId){

    let res = [];
    let files = data.attachments;
    if (files) {

        for (let index = 0; index < files.length; index++) {

            if (typeof document != 'undefined') {
                // I'm on the web!
                const formData = new FormData();
                formData.append('file', files[index]);

                let filePathVal;
                if( typeof files[index] === 'string'){
                    filePathVal = files[index];
                } else {
                    filePathVal = await uploadFileData(formData, files[index].type, accountId, data);
                }
                if (filePathVal) {
                    res.push(filePathVal);
                } else {
                    // store.dispatch(showToastMessage('danger',"Failed to upload"));
                    res = false;
                    break;
                }
            }
        }
    }

    return res;
}

const uploadFileData = async (binaryFile, fileType,accountId, data) =>  {

    for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL+'/service/customerData/templateInference/quickInference?contentId='+data.contentId+'&modelDefinitionId='+data?.modelDefinitionId;


    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        success:true,
                        ...res
                    }
                }
                if (statusCode > 299 && statusCode <500){
                    store.dispatch( showToastMessage('warning',res.__error));
                    return null;
                }
            }
        })
};

export const raisePostLoginTicket = (data, accountId) => {
    let url = '/service/provisioning/support/postLoginTicketRaised';
    return httpPost(url,data,accountId)
        .then((res) => {
            return res;
        })

};

export const getTicketFor = (accountId) => {
    let url = '/service/provisioning/support/ticketFor';

    return httpGet(url, accountId)
        .then(res => {

            return res;

        })
};


export async function uploadTicketRaiseFile(data,accountId){

    let res = [];
    let files = data.attachments;
    if (files) {

        for (let index = 0; index < files.length; index++) {

            if (typeof document != 'undefined') {
                // I'm on the web!
                const formData = new FormData();
                formData.append('file', files[index]);

                let filePathVal;
                if( typeof files[index] === 'string'){
                    filePathVal = files[index];
                } else {
                    filePathVal = await uploadTicketRaiseFileData(formData, files[index].type, accountId, data);
                }
                if (filePathVal) {
                    res.push(filePathVal);
                } else {
                    // store.dispatch(showToastMessage('danger',"Failed to upload"));
                    res = false;
                    break;
                }
            }
        }
    }

    return res;
}

const uploadTicketRaiseFileData = async (binaryFile, fileType,accountId) =>  {

    for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL+'/service/provisioning/support/media/'+fileType;


    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        success:true,
                        ...res
                    }
                }
                if (statusCode > 299 && statusCode <500){
                    store.dispatch( showToastMessage('warning',res.__error));
                    return null;
                }
            }
        })
};

export const setAccountUserList = (users, ownerName) => {
    store.dispatch(setAccountUser({
        data: users,
        ownerName:ownerName
    }));
};


export const getTimeZoneValues = (accountId, handleErrorInComponent) => {
    let url = '/service/provisioning/account/timezones';

    return httpGet(url, accountId, handleErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getBetaRequestData = (handleErrorInComponent) => {
    let url = '/service/provisioning/betaRequest/getForUser';

    return httpGet(url, '', handleErrorInComponent)
        .then(res => {
            return res;
        })
};

export const getStateOfIndia = () => {
    let url = '/service/provisioning/country';

    return httpGet(url)
        .then(res => {
            return res;
        })
};

export const createBetaAccount = (requestId) => {
    let url = '/service/provisioning/betaRequest/account?id='+requestId;

    return httpPost(url)
        .then(res => {
            return res;
        })
};


export const getAllContentImportData = (contentId, accountID) => {
    let url = '/service/customerData/contentImport/all/contentId='+contentId;

    return httpGet(url, accountID)
        .then(res => {
            return res;
        })
};


export const refreshAccountData = (e) => {
    store.dispatch(refreshAccount(e))
};
