import React from 'react';
import LoggedOutUserView from "./LoggedOutUserView";
import {connect} from "react-redux";
import {logoutUser} from "../../../user/userActions";
import {withRouter} from "react-router-dom";


function LoggedOutUser(props) {
    return (
        <LoggedOutUserView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    account: state.accountManagementState.account,
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
});

LoggedOutUser.propTypes = {

};

export default connect(mapStateToProps, {
    logoutUser,
})(withRouter(LoggedOutUser))