import React, {Component} from 'react';
import {DLText, DLButton} from 'dlcomponent';
import {isEmpty} from "../../../utils/validations";
import {isMobileInLandscape} from "../../../utils/helper";
import BottomMenuOption from "../../layout/bottomMenuOptions/BottomMenuOption";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

class PageNotAccessibleView extends Component {

    renderBottomFooter = () => {
        if (!isMobileInLandscape()) {
            return (
                <div className='d-flex d-lg-none w-100' style={{position: 'fixed', bottom: 0, zIndex: 101}}>
                    <BottomMenuOption history={this.props.history}
                                      account={this.props?.account}/>
                </div>
            )
        }
    };

    render() {
        const {isMobileDevice, isMobileView, fullUrl, prevPath, onHomeClick} = this.props;

        return (
            <div className={"bg-light h-100 w-100"} style={{
                minHeight: isMobileDevice ? 'calc(100vh - 55px)' : isMobileView ? 'calc(100vh - 111px)' : 'calc(100vh - 55px)',
                maxHeight: isMobileDevice ? '' : isMobileView ? 'calc(100vh - 111px)' : 'calc(100vh - 55px)',
                paddingBottom: (isMobileDevice && !isMobileInLandscape()) ? 56 : 0,
                overflowY: 'auto',
            }}>
                <div className='h-100'>
                    <div className={" bg-white  d-flex align-items-center justify-content-center flex-column  h-100"}>
                        <div className={"pt-3 text-center"}>
                            <DLText id={''}
                                    text={'Oops!'}
                                    marginBottom={"md"}
                                    type={"normal"}
                                    fontSize={"xl"}
                                    fontWeight={"bold"}/>

                            <DLText id={''}
                                    text={'Access Denied'}
                                    marginBottom={"sm"}
                                    type={"normal"}
                                    fontSize={"lg"}
                                    fontWeight={"semi-bold"}
                                    fontColor={'gray'}/>
                            <DLText id={''}
                                    text={'You dont have privilege to access this page '}
                                    type={"normal"}
                                    marginBottom={"md"}
                                    alignText={"center"}
                                    fontSize={"sm"}
                                    fontWeight={"semi-bold"}
                                    fontColor={'gray'}/>
                                    <div className={'mx-4'}>
                                        {!isEmpty(fullUrl) &&
                                        <DLText id={''}
                                                text={<span className='text-break'>{fullUrl}</span>}
                                                type={"normal"}
                                                isClickable={true}
                                                onClick={() => this.props.history.push(prevPath)}
                                                marginBottom={"md"}
                                                fontSize={"sm"}/>
                                        }
                                    </div>
                        </div>
                        <div className={"pb-3 justify-content-center d-flex align-item-center"}>
                            <img
                                src={s3BaseURL + '/images/general/pageNotFound.jpg'}
                                alt={'img'}
                                 className={'img-fluid'}
                                 width={'300px'}
                                 height={'200px'}/>
                        </div>
                        <div style={{minWidth: '150px', width: 'max-content'}}
                             className=' d-grid mt-2 mb-4 mx-auto text-center'>
                            <DLButton id={''}
                                      history={this.props.history}
                                      buttonSize={'sm'}
                                      type={'primary'}
                                      onClick={() => onHomeClick()}
                                      label={'Home'}/>
                        </div>
                    </div>
                </div>
                {this.renderBottomFooter()}
            </div>
        );
    }
}

PageNotAccessibleView.propTypes = {};

export default PageNotAccessibleView;
