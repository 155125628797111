import React, {Component} from 'react';
import {DLText, DLButton} from 'dlcomponent';
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

class PageNotFoundView extends Component {
    render() {
        const {account} = this.props;
        const pathname = this.props.location.pathname;
        const origin = window.location.origin;

        return (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
               <div>
                   <div>
                       <DLText id={''}
                               text={'Oops!'}
                               customWrapperStyle={{textAlign:'center'}}
                               marginBottom={"sm"}
                               type={"normal"}
                               fontSize={"xl"}
                               fontWeight={"bold"} />
                       <DLText id={''}
                               text={'Page Not Found!'}
                               customWrapperStyle={{textAlign:'center'}}
                               marginBottom={"sm"}
                               type={"normal"}
                               fontSize={"lg"}
                               fontWeight={"semi-bold"}
                               fontColor={'gray'}/>

                       <DLText id={''}
                               text={origin+pathname +" " + 'link is probably broken or the page has been removed.'}
                               type={"normal"}
                               marginBottom={"sm"}
                               customWrapperStyle={{textAlign:'center'}}
                               fontSize={"sm"} fontWeight={"semi-bold"} fontColor={'gray'}/>

                   </div>
                   <div className="d-flex justify-content-center align-item-center">
                       <img
                           src={s3BaseURL + '/images/general/pageNotFound.jpg'}
                           alt={'img'}
                           className={'img-fluid'}
                           width={'300px'}
                           height={'200px'}/>
                   </div>
                   <div className='text-center'>
                       <DLButton id={''}
                                 history={this.props.history}
                                 buttonSize={'sm'}
                                 fontSize={"md"}
                                 type={'primary'}
                                 onClick={() => this.props.history.push('/accounts/'+account?.id+'/dashboard')}
                                 label={'Home'}/>
                   </div>
              </div>
           </div>
        );
    }
}

PageNotFoundView.propTypes = {};

export default PageNotFoundView;
