import React from 'react';
import success from "../../assets/img/success.PNG";
import DLText from "dlcomponent/components/DLText/DLText";
import DLButton from "dlcomponent/components/DLButton/DLButton";
import declinedRequest from "../../assets/img/declinedRequest.png";
import Tick from "../../assets/img/success.PNG";

function BetaRequestApprovedView(props) {
    const {onStartUsing,isMobileView} = props;


    const renderApprovedUI = () => {
        return (
            <div  className='w-100'>
                <div className='text-center' style={{marginTop: 150}}>
                    <div style={{marginBottom: 15}}>
                        <img src={success}
                             alt={'img'}
                             className={'img-fluid'}
                             width={'80px'}
                             height={'80px'}/>
                    </div>
                    <DLText text={'Your request for Beta access is approved now!'}
                            marginBottom={'none'}/>
                    <div style={{marginTop: 20}}>
                        <DLButton id={''}
                                  type={"primary"}
                                  fontSize={'md'}
                                  sideMargin={"sm"}
                                  buttonSize={'sm'}
                                  onClick={() => onStartUsing()}
                                  label={'Start Using'}/>
                    </div>
                </div>
            </div>
        )
    };

    const renderPendingUI = () => {
        return (
            <div className="w-100 pageContainer d-flex align-items-center "
                 style={{height: 'calc(100vh - 56px)'}}>
                <div className={"w-100"}>

                    <div className={"bg-white  p-3 m-3 d-flex flex-column justify-content-center"}
                         style={{height: 'calc(100vh - 150px)'}}>
                        <div className={"pt-3"}>
                            <DLText id={'title'}
                                    text={'Thank You'}
                                    customWrapperStyle={{textAlign: 'center'}}
                                    customTextStyle={{
                                        color: '#E18600',
                                        font: 'normal normal medium 26px/31px Barlow',
                                        opacity: 1,
                                    }}
                                    marginBottom={"sm"}
                                    type={"normal"}
                                    fontSize={"lg"}
                                    fontWeight={"bold"}/>
                            <div className={"pb-3 justify-content-center d-flex align-item-center"}>
                                <img id={'PurchaseSuccessful-icon'}
                                     src={Tick}
                                     alt={'icon'}
                                     className='img-fluid '
                                     style={{maxHeight: '100px'}}/>
                            </div>

                            <DLText id={'message-2'}
                                    text={"Thank you for submitting your request."}
                                    type={"normal"}
                                    marginBottom={"none"}
                                    customWrapperStyle={{textAlign: 'center'}}
                                    fontSize={"md"} fontWeight={"semi-bold"} fontColor={'gray'}
                            />
                            <DLText id={'message-2'}
                                    text={"Our team will contact you shortly."}
                                    type={"normal"}
                                    marginBottom={"md"}
                                    customWrapperStyle={{textAlign: 'center'}}
                                    fontSize={"md"} fontWeight={"semi-bold"} fontColor={'gray'}
                            />

                        </div>


                    </div>
                </div>
            </div>
        );

    };

    const renderDeclineUI = () => {
        return (
            <div className='text-center' style={{marginTop: 150}}>
                <div style={{marginBottom: 10}}>
                    <img src={declinedRequest}
                         alt={'img'}
                         className={'img-fluid'}
                         width={'160px'}
                         height={'160px'}/>
                </div>
                <DLText text={'To access the full functionality of SCuiDO, please create an account.'}
                        marginBottom={'none'}
                        isInline={isMobileView}/>
                <DLText text={'This will enable you to continue using SCuiDO and enjoy all it’s features.'}
                        marginBottom={'none'}
                        isInline={isMobileView}/>
                <div style={{marginTop: 20}}>
                    <DLButton id={''}
                              type={"primary"}
                              fontSize={'md'}
                              sideMargin={"sm"}
                              buttonSize={'sm'}
                              onClick={() => props.history.push('/subscription/plans')}
                              label={'Create Account'}/>
                </div>
            </div>
        )

    };

    const renderUI = () => {
        const {UIState} = props;

        if (UIState === 'approved') {
            return renderApprovedUI()
        }
        if (UIState === "pending") {
            return renderPendingUI()
        }
        if (UIState === 'declined') {
            return renderDeclineUI()
        }
    };

    return (
        <div className='w-100'>
            {renderUI()}
        </div>
    );

}

BetaRequestApprovedView.propTypes = {

};
export default BetaRequestApprovedView;