
import {isEmpty} from "../utils/validations";
import {getRandomId} from "../utils/helper";
import {createSlice} from "@reduxjs/toolkit";


let allFilter = {
  keyword: '',
  sortBy: 'recentlyUpdated',
  limitcount: 0,
  scrollFromTop: 0,
  elementSelected:'',
  reportType:'',
  contentType:'ALL',
};

let campFilter = {
  keyword: '',
  campaignType:'ALL',
  campaignMode:'ALL'
};

const appSlice = createSlice({
  name: 'campaignReducer',
  initialState: {
    screen: '',
    emailTemplateFilter: {
      ...allFilter
    },
    campaignStatsFilter: {
      ...allFilter
    },
    campaignFilter: {
      ...allFilter
    },
    campaignReportsScroll: {
      ...allFilter
    },
    segmentListingScroll:{
      ...allFilter
    },
    contentFilter: {
      ...allFilter
    },
    campaignListingFilter: {
      ...campFilter
    },
    campaignListingScroll: {
      ...allFilter
    },
    importHistoryListingScroll: {
      ...allFilter
    },
    customerFilter: {
      keyword: '',
    },
    statisticsFilter: {
      sortBy: '',
      sortOrder: ''
    },
    FilterCount: 0,
    isShowLoader: true,
    isSegmentsLoaded: false,
    segmentData: {
      data: [],
      skipCount: 0,
      count: null,
      limitCount: 10,
      isReachedToEnd: false,
      apiInProgress: false,
      availableRecords: 0
    },
    segmentFilter: {
      type: 'ALL',
      keyword: "",
      dataEmpty: false
    },
    quickStartData : {
      isFromQuickStart:false,
      segment:{},
      content:{}
    },
    latestSegment:{},
    rankey: "",
    isCampaignPageRefresh:false,
    campaignData:{},
    configurations:{}
  },
  reducers: {
    setSegmentsData(state, action) {
      if (action.payload?.apiInProgress) {
        return {
          ...state,
          isShowLoader: true,
          segmentData: {
            ...state.segmentData,
            ...action.payload,
          }
        };
      }
      if (action.payload.statusCode === 204) {
        return {
          ...state,
          isShowLoader: false,
          isSegmentsLoaded: true,
          segmentData: {
            ...state.segmentData,
            data: [],
            // skipCount: 0,
            // count: "",
            // limitCount: 10,
            isReachedToEnd: true,
            apiInProgress: false,
            availableRecords: isEmpty(action.payload.count) ? 0 : action.payload.count
          },
        }
      }
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isShowLoader: false,
          isSegmentsLoaded: true,
          segmentData: {
            ...state.segmentData,
            data: (action.payload.skipCount === 0)
                ? action.payload.segmentations : state.segmentData.data.concat((isEmpty(action.payload.segmentations) ? [] : action.payload.segmentations)),
            skipCount: action.payload.skipCount,
            limitCount: action.payload.limitCount,
            isReachedToEnd: (isEmpty(action.payload.segmentations) || (action.payload?.segmentations?.length < action.payload.limitCount)),
            count: action.payload.count,
            apiInProgress: action.payload.apiInProgress,
            availableRecords: action.payload.count
          }
        };
      }
    },
    resetSegmentData(state) {
      return {
        ...state,
        segmentData: {
          data: [],
          skipCount: 0,
          count: null,
          limitCount: 10,
          isReachedToEnd: false,
          apiInProgress: false,
          availableRecords: 0
        },
        segmentFilter: {
          type: "ALL",
          keyword: "",
          dataEmpty: false
        }
      };
    },
    setSegmentLimitCountData(state, action) {
      return {
        ...state,
        segmentData: {
          ...state.segmentData,
          limitCount: action?.payload?.limitCount,
        }
      };
    },
    setKeywordFilterData(state, action) {
      let screen = state.screen + "Filter";
      state[screen] = {
        ...state[screen],
        keyword: action.payload
      }
    },
    setStatsKeywordFilterData(state, action) {
      let screen = "campaignStatsFilter";
      state[screen] = {
        ...state[screen],
        keyword: action.payload
      }
    },
    setSegmentScrollPositionData(state, action) {
      let screen = "segmentListingScroll";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.scrollFromTop,
        elementSelected: action.payload.elementSelected
      }
    },
    setListingScrollPositionData(state, action) {

      let screen = "campaignStatsFilter";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }

    },
    setcampaignListingScrollPositionData(state, action) {
      let screen = "campaignListingScroll";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }

    },
    setImportHistoryScrollPositionData(state, action) {
      let screen = "importHistoryListingScroll";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }

    },
    setScrollPositionData(state, action) {
      let screen = "campaignStatsFilter";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }
    },
    resetScrollPositionData(state) {
      let screen = "campaignStatsFilter";
      state[screen] = {
        ...state[screen],
        limitcount: '',
        scrollFromTop: '',
        elementSelected: '',
      }
    },
    setStatsReportScrollPositionData(state, action) {
      let screen = "campaignReportsScroll";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
        reportType: action.payload.reportType
      }
    },
    setEmailTemplateScrollPositionData(state, action) {
      let screen = "emailTemplateFilter";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }
    },
    resetEmailTemplateScrollPositionData(state) {
      let screen = "emailTemplateFilter";
      state[screen] = {
        ...state[screen],
        limitcount: '',
        scrollFromTop: '',
        elementSelected: '',
      }
    },
    setContentScrollPositionData(state, action) {
      let screen = "contentFilter";
      state[screen] = {
        ...state[screen],
        limitcount: action.payload.currentSkipcount,
        scrollFromTop: action.payload.currentCursorFromTop,
        elementSelected: action.payload.elementSelected,
      }
    },

    resetContentScrollPositionData(state) {
      let screen = "contentFilter";
      state[screen] = {
        ...state[screen],
        limitcount: '',
        scrollFromTop: '',
        elementSelected: '',
      }
    },

    statisticsFilterChangeData(state, action) {
      state.statisticsFilter = {
        ...state.statisticsFilter,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.columnName,
      }
    },
    statisticsFilterCountData(state, action) {
      state.FilterCount = action.payload
    },

    // setCampaignKeywordSearchFilterData(state, action) {
    //   state.campaignListingFilter = {
    //     ...state.campaignListingFilter,
    //     keyword: action.payload
    //   }
    // },

    setCampaignKeywordSearchFilterData(state, action) {
      state.campaignListingFilter = {
        ...state.campaignListingFilter,
        [action.payload.name]: action.payload.value
      }
    },

    resetCampaignKeywordSearchFilterData(state) {
      state.campaignListingFilter = {
        keyword: '',
        status: '',
        campaignType:'ALL',
        campaignMode:'ALL'
      };
    },
    setSortByData(state, action) {
      let screen = state.screen + "Filter";
      state[screen] = {
        ...state[screen],
        sortBy: action.payload
      }

    },
    setTemplateScreenData(state, action) {
      state.screen = action.payload
    },
    resetTemplateFilterData(state) {
      state.emailTemplateFilter = {
        ...state.emailTemplateFilter

      };
      state.campaignFilter = {
        keyword: '',
        sortBy: 'recentlyUpdated',
      }
    },
    resetEmailTemplateFilterData(state) {
      state.emailTemplateFilter = {
        keyword: '',
        sortBy: 'recentlyUpdated'
      };
      state.campaignFilter = {
        ...state.campaignFilter,

      }
    },
    userLogoutData4(state) {
      state.screen = '';
      state.emailTemplateFilter = {
        keyword: '',
        sortBy: 'recentlyUpdated',
      };
      state.campaignFilter = {
        keyword: '',
        sortBy: 'recentlyUpdated',
      };
      state.campaignListingFilter = {
        keyword: '',
        status: '',
        type:'ALL',
        campaignMode:'ALL'
      };
      state.statisticsFilter = {
        sortBy: '',
        sortOrder: ''
      };
      state.FilterCount = 0;
      state.isShowLoader = false;
      state.isSegmentsLoaded = false;
      state.segmentData = {
        data: [],
        skipCount: 0,
        count: 0,
        limitCount: 10,
        isReachedToEnd: false,
        apiInProgress: false,
        availableRecords: 0
      };
      state.segmentFilter = {
        type: "ALL",
        keyword: "",
        dataEmpty: false
      };
      state.rankey = ""

    },
    refreshSegmentsData(state) {
      state.isShowLoader = false;
      state.isSegmentsLoaded = false;
      state.segmentData = {
        ...state.segmentData,
        data: [],
        skipCount: 0,
        count: "",
        limitCount: 10,
        isReachedToEnd: false,
        apiInProgress: false,
        availableRecords: 0,
        reset: getRandomId(8)
      }
    },
    segmentTypeFilterData(state, action) {
      state.segmentFilter = {
        ...state.segmentFilter,
        [action.payload.name]: action.payload.value,
        dataEmpty: true
      }
    },
    deleteSegmentFilterData(state) {
      state.segmentFilter = {
        ...state.segmentFilter,
        type: "ALL",
        keyword: "",
        dataEmpty: false
      }
    },
    setSegmentType(state, action) {
      state.segmentFilter = {
        ...state.segmentFilter,
        type:  action.payload,
      }
    },
    customerFilterData(state, action) {
      state.customerFilter = {
        ...state.customerFilter,
        [action.payload.name]: action.payload.value,
      }
    },
    setQuickStartData(state, action) {

      state.quickStartData = {
        ...state.quickStartData,
        isFromQuickStart:action.payload.isFromQuickStart,
        segment:action.payload.segment,
        content:action.payload.content
      }
    },
    resetQuickStartData(state) {

      state.quickStartData = {
        ...state.quickStartData,
        isFromQuickStart:false,
        segment:{},
        content:{}
      }
    },
    renderCampaignViewPage(state, action) {
      state.isCampaignPageRefresh=action.payload.isCampaignPageRefresh;
      state.campaignData=action.payload.campaignData
    },
    setConfigurationsState(state, action) {
      state.configurations=action.payload
    },
    resetCampaignStatsFilter(state, action) {
      state.campaignStatsFilter = {
        keyword: action.payload
      }
    },
    setLatestCreatedSegment(state, action) {
      state.latestSegment = action.payload
    },

    campaignTypeFilterData(state, action) {
      state.campaignListingFilter = {
        ...state.campaignListingFilter,
        [action.payload.name]: action.payload.value,
      }
    },

    campaignModeFilterData(state, action) {
      state.campaignListingFilter = {
        ...state.campaignListingFilter,
        [action.payload.name]: action.payload.value,
      }
    },

    setCampaignTemplateContentType(state,action) {
      let screen = "emailTemplateFilter";
      state[screen] = {
        ...state[screen],
        contentType: action.payload,
      }
    },

    resetCampaignStatsFilterData(state) {
      state.campaignStatsFilter = {
        keyword: '',
        sortBy: 'recentlyUpdated',
        limitcount: 0,
        scrollFromTop: 0,
        elementSelected:'',
        reportType:''
      }
    }
  }
});

export const {
  setSegmentsData, setKeywordFilterData, userLogoutData4, resetCampaignStatsFilterData,
  statisticsFilterChangeData, statisticsFilterCountData, renderCampaignViewPage,
  setCampaignKeywordSearchFilterData, setSortByData, setTemplateScreenData,
  resetTemplateFilterData, resetEmailTemplateFilterData, resetCampaignKeywordSearchFilterData,
  refreshSegmentsData, segmentTypeFilterData, deleteSegmentFilterData, setLatestCreatedSegment,setSegmentLimitCountData,
  customerFilterData, setQuickStartData, resetQuickStartData, resetSegmentData,setStatsKeywordFilterData,setSegmentScrollPositionData,setListingScrollPositionData,resetEmailTemplateScrollPositionData,setImportHistoryScrollPositionData,
  setEmailTemplateScrollPositionData,setContentScrollPositionData,resetContentScrollPositionData,setcampaignListingScrollPositionData,setStatsReportScrollPositionData, setConfigurationsState, setSegmentType, campaignTypeFilterData,
  campaignModeFilterData, setCampaignTemplateContentType
} = appSlice.actions;
export default appSlice.reducer

