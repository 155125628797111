export const glacierContentKeys = [
  {
    "key": "LABEL-NAME-EMPTY-ERROR",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Please enter the content name",
      hi: ""
    }
  },
  {
    "key": "LABEL-NAME-ALPHABET-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter only A - Z characters.",
      hi: ""
    }
  },
  {
    "key": "LABEL-NAME-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter upto 50 characters",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-EMPTY-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please choose the type of the content ",
      hi: ""
    }
  },
  {
    "key": "LABEL-CUSTOMER",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Customer",
      hi: "ग्राहक"
    }
  },
  {
    "key": "LABEL-MASTER",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Master",
      hi: ""
    }
  },
  {
    "key": "LABEL-EVENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Event",
      hi: ""
    }
  },
  {
    "key": "LABEL-SET-UP-CONTENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Set up Content",
      hi: "सामग्री सेट करें"
    }
  },
  {
    "key": "LABEL-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Name",
      hi: "नाम"
    }
  },
  {
    "key": "LABEL-CHOOSE-TYPE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Choose Type",
      hi: "प्रकार चुनें"
    }
  },
  {
    "key": "LABEL-PROCEED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Proceed",
      hi: "प्रकार चुनें"
    }
  },
  {
    "key": "LABEL-CANCEL",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Cancel",
      hi: "रद्द करें"
    }
  },
  {
    "key": "LABEL-CUSTOMER-CONTENT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "A file containing data about your customers",
      hi: "आपके ग्राहकों के बारे में डेटा वाली फ़ाइल"
    }
  },
  {
    "key": "LABEL-CUSTOMER-CONTENT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ex: First Name, Location",
      hi: ""
    }
  },
  {
    "key": "LABEL-MASTER-CONTENT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "A file containing reference/lookup data",
      hi: ""
    }
  },
  {
    "key": "LABEL-MASTER-CONTENT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ex: Product Master, Country Codes etc",
      hi: ""
    }
  },
  {
    "key": "LABEL-EVENT-CONTENT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "A file containing transactional data for your business",
      hi: ""
    }
  },
  {
    "key": "LABEL-EVENT-CONTENT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ex: Sales Transactions, Invoices, Payments etc",
      hi: ""
    }
  },
  //contentList
  {
    "key": "LABEL-CONTENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Content",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-CREATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Create new file",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-FILE-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "File Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-FILE-TYPE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "File Type",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-FILE-RECORDS",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "#Records",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONTENT-FILE-STATUS",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Status",
      hi: ""
    }
  },

  //sourceCreate
  {
    "key": "LABEL-FTP-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "FTP Source",
      hi: ""
    }
  },
  {
    "key": "LABEL-SOURCE-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Source Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCE-IP",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Server Name/IP",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCE-DIRECTORY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Directory",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCE-USER-ID",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "User Id",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCE-PASSWORD",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Password",
      hi: ""
    }
  },
  {
    "key": "LABEL-TEST",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Test",
      hi: ""
    }
  },
  {
    "key": "LABEL-SAVE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Save",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCE-CONTENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to remove this FTP source? ",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Remove",
      hi: ""
    }
  },
  {
    "key": "LABEL-CONFIRMATION",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Confirmation",
      hi: ""
    }
  },

  //sourceList
  {
    "key": "LABEL-DATA-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Data Sources",
      hi: ""
    }
  },
  {
    "key": "LABEL-MANAGE-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Manage Sources",
      hi: ""
    }
  },
  {
    "key": "LABEL-FTP-SOURCES",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "FTP Sources",
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-FTP-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Add FTP Source",
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-FTP-MESSAGE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Set up your first FTP source",
      hi: ""
    }
  },
  {
    "key": "LABEL-SOURCE-NAME-EMPTY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter source name",
      hi: ""
    }
  },
  {
    "key": "LABEL-IP-ADDRESS-EMPTY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: " Please enter valid server name / IP address",
      hi: ""
    }
  },
  {
    "key": "LABEL-DIRECTORY-EMPTY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter directory name",
      hi: ""
    }
  },
  {
    "key": "LABEL-USER-ID-EMPTY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter user id",
      hi: ""
    }
  },
  {
    "key": "LABEL-PASSWORD-EMPTY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter password",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACCESSKEY-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter accesskey id",
      hi: ""
    }
  },
  {
    "key": "LABEL-SECRETE-ACCESSKEY-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter secret accesskey",
      hi: ""
    }
  },
  {
    "key": "LABEL-BUCKET-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter bucket name",
      hi: ""
    }
  },
  {
    "key": "LABEL-REGION-ERROR",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Please enter region",
      hi: ""
    }
  },

  //contentDetails
  {
    "key": "LABEL-ABOUT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "About",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-MESSAGE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to remove ",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-CONTENT?",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: " content?",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-CONTENT-TEXT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "This will remove all the setup instructions for this content as well as the data already loaded, the draft campaigns & created segments. This cannot be undone.",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-DATA-TEXT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to clear the data from ",
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-DATA-TEXT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "All of the previous data from this content will be erased and this action cannot be undone.",
      hi: ""
    }
  },
  {
    "key": "LABEL-TEMPLATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Template",
      hi: ""
    }
  },
  {
    "key": "LABEL-SETUP-TEMPLATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Set up your template",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIVATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Activate",
      hi: ""
    }
  },
  {
    "key": "LABEL-EDIT-TEMPLATE-DIALOG-TEXT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "This will create another draft copy of the template. Your current template remains active till you activate the edited draft copy.",
      hi: ""
    }
  },
  {
    "key": "LABEL-DISCARD-DRAFT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Discard Draft",
      hi: ""
    }
  },
  {
    "key": "LABEL-DISCARD-TEMPLATE-MESSAGE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to discard this template draft?",
      hi: ""
    }
  },
  {
    "key": "LABEL-ACTIVATE-TEMPLATE-MESSAGE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to activate this template?",
      hi: ""
    }
  },
//  csv upload
  {
    "key": "LABEL-",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to activate this template?",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-FILE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Upload File",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-NOW",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Upload now",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "File does not appear to be a valid CSV file",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "File has 34 fields but template has 17 fields",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR3",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Third Field says “Email” but file has “Address” as the third field",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR4",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "“Email” is defined as unique but found duplicate values",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR5",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "“Email” is mandatory but some records do not have values in this field ",
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-ERROR5",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "“Email” is mandatory but some records do not have values in this field ",
      hi: ""
    }
  },
  {
    "key": "LABEL-NO-OF-RECORDS",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Records in file: ",
      hi: ""
    }
  },
  {
    "key": "LABEL-RECORDS-ADDED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Records added: ",
      hi: ""
    }
  },
  {
    "key": "LABEL-RECORDS-UPDATED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Records updated: ",
      hi: ""
    }
  },
  {
    "key": "LABEL-RECORDS-REJECTED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Records rejected: ",
      hi: ""
    }
  },
  {
    "key": "LABEL-VALIDATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Validate",
      hi: ""
    }
  },
  {
    "key": "LABEL-UNIQUE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Unique",
      hi: ""
    }
  },
  {
    "key": "LABEL-MANDATORY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Mandatory",
      hi: ""
    }
  },
  {
    "key": "LABEL-DATA-FORMAT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Date Format",
      hi: ""
    }
  },
  {
    "key": "LABEL-DATA-TYPE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Data Type",
      hi: ""
    }
  },
  {
    "key": "LABEL-BUSINESS-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Business Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-FIRST-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "First Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-IGNORE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ignore",
      hi: ""
    }
  },
  {
    "key": "LABEL-NEW-COLUMN",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "New field",
      hi: ""
    }
  },
  {
    "key": "LABEL-WITH-CSV",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "With CSV",
      hi: ""
    }
  },
  {
    "key": "LABEL-WITH-CSV-TEXT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'You will be able to create your template faster if you have your csv file ready.',
      hi: ""
    }
  },
  {
    "key": "LABEL-MANUAL",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Manual',
      hi: ""
    }
  },
  {
    "key": "LABEL-MANUAL-TEXT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'This option lets you define your template manually.',
      hi: ""
    }
  },
  {
    "key": "LABEL-COLUMN-SETTING",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Field Settings',
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-STRUCTURE-MSG",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Start adding your structure here',
      hi: ""
    }
  },
  {
    "key": "LABEL-DATA-TYPE-BOOLEAN",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Boolean',
      hi: ""
    }
  },

  {
    "key": "LABEL-DATA-TYPE-INTEGER",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Numeric',
      hi: ""
    }
  },

  {
    "key": "LABEL-DATA-TYPE-DOUBLE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Decimal',
      hi: ""
    }
  },

  {
    "key": "LABEL-DATA-TYPE-STRING",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Text',
      hi: ""
    }
  },

  {
    "key": "LABEL-DATA-TYPE-STRING",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Text',
      hi: ""
    }
  },

  {
    "key": "LABEL-DATA-TYPE-DATE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Date',
      hi: ""
    }
  },
  {
    "key": "LABEL-DATA-TYPE-DATETIME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Date Time',
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-DOCUMENTS",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Upload CSV',
      hi: ""
    }
  },
  {
    "key": "LABEL-UPLOAD-DOCUMENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Upload CSV',
      hi: ""
    }
  },
  {
    "key": "ERROR-UPLOAD-FILE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Max file upload limit reached',
      hi: ""
    }
  },
  {
    "key": "LABEL-REMOVE-AWS-MSG",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Are you sure you want to remove this AWS source?',
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-S3-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Add S3 Source',
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-S3-MSG",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Set up your first S3 source',
      hi: ""
    }
  },
  {
    "key": "LABEL-REGION",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Region',
      hi: ""
    }
  },
  {
    "key": "LABEL-BUCKET",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Bucket',
      hi: ""
    }
  },
  {
    "key": "LABEL-ACCESSKEY-ID",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'AccessKey Id',
      hi: ""
    }
  },
  {
    "key": "LABEL-SECRET-ACCESSKEY",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Secret AccessKey',
      hi: ""
    }
  },
  {
    "key": "LABEL-AWS-SOURCES",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'AWS S3 Sources',
      hi: ""
    }
  },
  {
    "key": "LABEL-AWS-SOURCE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'AWS Source',
      hi: ""
    }
  },
  {
    "key": "LABEL-TEST-WITH-FILE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Test with a File',
      hi: ""
    }
  },
  {
    "key": "LABEL-CREATE-UNIQUE-COMBINATION",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: 'Create Unique Combination',
      hi: ""
    }
  },
  {
    "key": "LABEL-EDIT-COLUMN",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Edit field",
      hi: ""
    }
  },
  {
    "key": "LABEL-YES",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Yes",
      hi: ""
    }
  },
  {
    "key": "LABEL-NO",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "No",
      hi: ""
    }
  },
  {
    "key": "DELETE-LABEL-ATTACHMENT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Are you sure you want to delete attachment?",
      hi: ""
    }
  },
  {
    "key": "BTN-DELETE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Delete",
      hi: ""
    }
  },
  {
    "key": "LABEL-ADD-FILES",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Add Files",
      hi: ""
    }
  },
  {
    "key": "LABEL-DELIMITER-TOOLTIP-CONTENT",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Delimiter : A separator for each field and value.",
      hi: ""
    }
  },
  {
    "key": "LABEL-QUOTE-TOOLTIP-CONTENT",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Quote : A character used for escaping quoted values where the delimiter is part of the value.",
      hi: ""
    }
  },
  {
    "key": "LABEL-ESCAPE-TOOLTIP-CONTENT",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Escape : A character used for escaping quotes inside an already quoted value.",
      hi: ""
    }
  },
  {
    "key": "LABEL-HEADER-IN-FIRST-LINE-TOOLTIP-CONTENT",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Header : If there is a header in first line of your CSV file, turn this switch on.",
      hi: ""
    }
  },
  {
    "key": "LABEL-LOGS-VIEW",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Logs View",
      hi: ""
    }
  },
  {
    "key": "LABEL-SERVICE-GLACIER",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Glacier",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-READINESS",
    "areaOfUsage": "Glacier",
    "value": {
      en: "Campaign Readiness",
      hi: "अभियान की तैयारी"
    }
  },
  {
    "key": "LABEL-COMING-SOON",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "coming soon",
      hi: ""
    }
  },
  {
    "key": "LABEL-FIRST-NAME",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "First Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-Last-Name",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Last Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-Full-Name",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Full Name",
      hi: ""
    }
  },
  {
    "key": "LABEL-Email",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Email",
      hi: ""
    }
  },
  {
    "key": "LABEL-Mobile",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Mobile",
      hi: ""
    }
  },
  {
    "key": "LABEL-KNOWLEDGE-BASE",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Knowledge Base",
      hi: ""
    }
  },
  {
    "key": "LABEL-CAMPAIGN-DESTINATION",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Campaign Destination",
      hi: ""
    }
  },
  {
    "key": "LABEL-IMPORT",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Import",
      hi: ""
    }
  },
  {
    "key": "LABEL-PROSPECTS",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Prospect",
      hi: ""
    }
  },
  {
    "key": "LABEL-LEAD",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Lead",
      hi: ""
    }
  },
  {
    "key": "LABEL-PROSPECTS-CONTENT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "A file containing data about qualified leads",
      hi: ""
    }
  },
  {
    "key": "LABEL-PROSPECTS-CONTENT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ex: First Name, Location",
      hi: ""
    }
  },
  {
    "key": "LABEL-LEAD-CONTENT1",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "A file containing data about leads",
      hi: ""
    }
  },
  {
    "key": "LABEL-LEAD-CONTENT2",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Ex: First Name, Location",
      hi: ""
    }
  },
  {
    "key": "LABEL-SALUTATION",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Salutation",
      hi: ""
    }
  },
  {
    "key": "LABEL-NOT_VERIFIED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Not verified",
      hi: ""
    }
  },
  {
    "key": "LABEL-VERIFIED",
    "areaOfUsage": "SCUIDO",
    "value": {
      en: "Verified",
      hi: ""
    }
  },
];
