import React from 'react';

function HomePage() {
    return (
        <div/>
    );
}

HomePage.propTypes = {

};

export default HomePage;