import {
    getAppEnvironmentVariables, getAuthorization, getAuthorizationToken,
    httpDelete,
    httpGet,
    httpPost,
    httpPut
} from "../utils/httpHelper";
import store from "../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import * as log from "loglevel";
import {isEmpty} from "../utils/validations";
import {
    customerFilterData,
    refreshSegmentsData,
    resetEmailTemplateFilterData,
    resetQuickStartData,
    resetTemplateFilterData,
    setCampaignKeywordSearchFilterData,
    setKeywordFilterData,
    setQuickStartData,
    setSegmentsData,
    setSortByData,
    setTemplateScreenData,
    statisticsFilterChangeData,
    setStatsKeywordFilterData,
    setListingScrollPositionData,
    setEmailTemplateScrollPositionData,
    setcampaignListingScrollPositionData,
    resetEmailTemplateScrollPositionData,
    setImportHistoryScrollPositionData,
    setStatsReportScrollPositionData,
    setContentScrollPositionData,
    resetContentScrollPositionData,
    setSegmentLimitCountData,
    statisticsFilterCountData,
    resetSegmentData,
    setSegmentScrollPositionData,
    setConfigurationsState, setSegmentType, segmentTypeFilterData, campaignTypeFilterData, campaignModeFilterData,
    setCampaignTemplateContentType
} from "./campaignReducer";
import {setNotAccessible, userLoggedOut} from "../user/userReducer";

export const createEmailTemplate = (accountId, data, handledError) => {
    return httpPost('/service/channels/email/template', data, accountId, handledError)
        .then(res => {
            return res;
        })
};

// const removeEcapeCharFromString = (data) => {
//   let Tempdata = data.replace(/\"/g,'"')
//   x = x.replace(/\\"/g, '"');
//   return Tempdata;
//
// }

export const editEmailTemplate = (accountId, data) => {
    return httpPut('/service/channels/email/template', data, accountId)
        .then(res => {
            return res;
        })
};


export const getAllEmailTemplatesData = (accountId, urlEndPoint) => {
    return httpGet('/service/channels/email/template/all?' + urlEndPoint, accountId)
        .then(res => {
            return res;
        })
};

export const deleteEmailTemplateData = (accountId, templateId) => {
    return httpDelete('/service/channels/email/template?id=' + templateId, '', accountId)
        .then(res => {
            return res;
        })
};

export const getEmailTemplateById = (accountId, templateId) => {
    return httpGet('/service/channels/email/template?id=' + templateId, accountId)
        .then(res => {
            return res;
        })
};

export const getFunnelChartData = (accountId, campaignId) => {
    return httpGet('/service/campaignManagement/campaign/statistics?campaignId=' + campaignId, accountId)
        .then(res => {
            return res;
        })
};


export const setKeywordFilter = (value) => dispatch => {
    dispatch(setKeywordFilterData(value))
};

export const setTemplateContentTypeFilter = (value) => dispatch => {
    dispatch(setCampaignTemplateContentType(value))
};

export const setStatsKeywordFilter = (value) => dispatch => {
    dispatch(setStatsKeywordFilterData(value))
};

export const setEmailTemplateScrollPosition = (value) => dispatch => {
    dispatch(setEmailTemplateScrollPositionData(value))
};

export const setListingScrollPosition = (value) => dispatch => {
    dispatch(setListingScrollPositionData(value))
};
export const setStatsReportScrollPosition = (value) => dispatch => {
   dispatch(setStatsReportScrollPositionData(value))
};

export const setcampaignListingScrollPosition = (value) => dispatch => {
    dispatch(setcampaignListingScrollPositionData(value))
};
export const setImportHistoryScrollPosition = (value) => dispatch => {
    dispatch(setImportHistoryScrollPositionData(value))
};

export const resetEmailTemplateScrollPosition = () => dispatch => {
    dispatch(resetEmailTemplateScrollPositionData())
};

export const setContentScrollPosition = (value) => dispatch => {
    dispatch(setContentScrollPositionData(value))
};

export const resetContentScrollPosition = () => dispatch => {
    dispatch(resetContentScrollPositionData())
};

export const setSegmentLimitCount = (value) => dispatch => {
    dispatch(setSegmentLimitCountData(value))
};

// export const setCampaignKeywordFilter = (value) => dispatch => {
//   dispatch(setCampaignKeywordSearchFilterData(value))
// };


export const setCampaignKeywordFilter = (name, value) => dispatch => {

    dispatch(setCampaignKeywordSearchFilterData(
        {
            name:name,
            value:value
        }
    ))

};

export const setChangeSort = (value) => dispatch => {
    dispatch(setSortByData(value))
};

export const changeTemplateScreen = (value) => dispatch => {
    dispatch(setTemplateScreenData(value))
};

export const resetTemplateFilter = (screen) => dispatch => {
    dispatch(resetTemplateFilterData(screen))
};

export const resetEmailTemplateFilter = (screen) => dispatch => {
    dispatch(resetEmailTemplateFilterData(screen))
};
export const setSegmentScrollPosition = (screen) => dispatch => {
    dispatch(setSegmentScrollPositionData(screen))
};

export const setConfigState = (data) => dispatch => {
    dispatch(setConfigurationsState(data))
};


export const uploadEmailFiles = (blob) => {
    let {REACT_APP_HOST_URL} = getAppEnvironmentVariables();

    let newFile = new File([blob], blob.name, {"type": blob.type});
    let formData = new FormData();
    formData.append('file', newFile);

    return fetch(REACT_APP_HOST_URL + '/service/operations/emailMgmt/media/upload/image', {
        method: 'POST',
        headers :{
            ...getAuthorization(),
        },
        body: formData
    })
        .then(response => response.json())
        .then(result => {
            return result
            // editor.AssetManager.add('https://poc.unicom.deccanlogic.com/upload/' + result.imagePath);
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const uploadEmailFilesPreview = (blob) => {
    let {REACT_APP_HOST_URL} = getAppEnvironmentVariables();

    let newFile = new File([blob], blob.name, {"type": blob.type});
    let formData = new FormData();
    formData.append('file', newFile);
    let statusCode;
    return fetch(REACT_APP_HOST_URL + '/service/operations/emailMgmt/media/upload/preview/image', {
        method: 'POST',
        headers :{
            ...getAuthorization(),
        },
        body: formData
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                return data
            }

            if (statusCode === 204) {
                return ''
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return data
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return ''
            }
            // return result
            // editor.AssetManager.add('https://poc.unicom.deccanlogic.com/upload/' + result.imagePath);
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const uploadEmailFiles2 = (b64Data, fileName) => {

    let {REACT_APP_HOST_URL} = getAppEnvironmentVariables();

    let newFile = new File([b64Data], fileName, {"type": b64Data.type});
    let formData = new FormData();
    let statusCode;
    formData.append('file',newFile, fileName);

    return fetch(REACT_APP_HOST_URL + '/service/operations/emailMgmt/media/upload/image', {
        method: 'POST',
        headers :{
            ...getAuthorization(),
        },
        body: formData
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                return data
            }

            if (statusCode === 204) {
                return ''
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return data
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return ''
            }
            // return result
            // editor.AssetManager.add('https://poc.unicom.deccanlogic.com/upload/' + result.imagePath);
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const uploadImageUrl = (data) => {

    return httpPost('/service/operations/emailMgmt/media/upload/imageUrl', data)
        .then(res => {
            if (res?.statusCode === 200) {
            } else {
                log.debug({
                    function: 'uploadImageUrl',
                    response: res.data,
                    errorDescription: 'Error caught in default case'
                });
                store.dispatch(showSystemErrorBlock());
            }
            return res.data;
        }).catch(err => {
            log.error({function: 'uploadImageUrl', error: err, errorDescription: 'Error caught in Catch'});
            store.dispatch(showSystemErrorBlock());
            return null;

        });
};

export const uploadImageUrlPreview = (data) => {
    return httpPost('/service/operations/emailMgmt/media/upload/preview/imageUrl', data)
        .then(res => {
            return res;
        })
};


export const sendTemplateEmail = (accountId, data) => {
    return httpPost('/service/channels/email/send', data, accountId)
        .then(res => {
            return res;
        })
};

export const getAllCampaignData = (accountId,urlEndPoint, handleSystemErrorInComponent) => {
    let url;
    if (!isEmpty(urlEndPoint)){
        url='/service/campaignManagement/campaign/all?' + urlEndPoint;
    }else {
        url='/service/campaignManagement/campaign/all';
    }

    return httpGet(url, accountId, false, handleSystemErrorInComponent).then((res) => {
        if (res?.statusCode === 200){
            return {
                allCampaigns : res.data?.campaigns,
                allCampaignsCount : res.data?.count,
                responseStatus : true,
                campaignUIStatus : 'CAMPAIGN_EXIST'
            }
        }
        if (res?.statusCode === 204){
            return {
                allCampaigns : '',
                allCampaignsCount : 0,
                responseStatus : true,
                campaignUIStatus : 'CAMPAIGN_NOT_EXIST'
            }
        }
        return res;
    })
};

export const getAllCampaignStatusCount = (accountId) => {
    let url='/service/campaignManagement/campaign/count';
    return httpGet(url, accountId).then((res) => {
        return res
    })
};


export const campaignReady = (accountId, handleErrorInComponent) => {
    let url='/service/campaignManagement/campaign/isReady';
    return httpGet(url, accountId, handleErrorInComponent).then((res) => {
        return res;
    })
};

export const getCampaignById = (campaignId,accountId) => {
    let url='/service/campaignManagement/campaign?id='+campaignId;
    return httpGet(url, accountId).then((res) => {
        return res;
    })
};

export const createCampaignData = (data, accountId, handleErrorInComponent) => {
    return httpPost('/service/campaignManagement/campaign', data, accountId, handleErrorInComponent)
        .then((res) => {
            return res
        })
};

export const deleteCampaign = (campaignId,accountId) =>{
    let url='/service/campaignManagement/campaign?id='+campaignId;
    return httpDelete(url,'',accountId)
        .then((res)=>{
            return res;
        })
};

export const copyCampaign = (accountId,data) =>{
    let url='/service/campaignManagement/campaign/copy?id='+accountId;
    return httpPost(url,data,accountId)
        .then((res)=>{
            return res;
        })
};

export const stopCampaign = (accountId,data) =>{
    let url='/service/campaignManagement/campaignSchedule/stop';
    return httpPost(url,data,accountId)
        .then((res)=>{
            switch (res?.statusCode) {
                case 200:
                    break;
                case 204:
                    return res;
                case 400:
                case 401:
                    store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
            }
        }).catch((err)=>{
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const deleteCampaignTemplate = (campaignId,BaseCampaigns,accountId) =>{
    let url='/service/campaignManagement/campaign/template?campaignId=' + campaignId + '&baseCampaignId='+BaseCampaigns;

    return httpDelete(url,'',accountId)
        .then((res)=>{
            return res;
        })
};

export const editCampaign = (accountId,data) =>{
    let url='/service/campaignManagement/campaign';
    return httpPut(url,data,accountId)
        .then((res)=>{
            return res;
        })
};

export const activateCampaign = (data, accountId) => {

    return httpPost('/service/campaignManagement/campaign/activate', data, accountId, true)
        .then((res) => {
            return res;
        })
};

export const createScheduleData = (data, accountId) => {
    return httpPost('/service/campaignManagement/campaignSchedule', data, accountId)
        .then((res) => {
            return res;
        })
};


export const updateScheduleData = (data, accountId) => {
    return httpPut('/service/campaignManagement/campaignSchedule', data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                case 401:
                case 403:
                case 409:
                    store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
            }
            return res;
        }).catch((err)=>{
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const editEntityLinking = (data, accountId) => {
    return httpPut('/service/customerData/entity/linking', data, accountId)
        .then((res) => {
            return res;
        })
};
export const sendTestEmail = (data, accountId) => {
    return httpPost('/service/campaignManagement/campaign/sendTestEmail', data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                case 401:
                    store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
            }
            return res;
        }).catch((err)=>{
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const getStatistics = (campaignId, accountId) =>{
    let url='/service/campaignManagement/campaign/statistics?campaignId='+campaignId;
    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getAllNodes = (accountId) => {

    let url='/service/customerData/entity/linking/list';

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getAllColumns = (contentId, accountId) => {

    let url='/service/customerData/entity/linking/columns?entityId=' + contentId;

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getAllLinking = (accountId) => {

    let url='/service/customerData/entity/linking/all';

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};
export const deleteEntityLinking = (deleteIds, accountId) =>{
    let url='/service/customerData/entity/linking?fromEntityId='+deleteIds.fromEntityId+'&toEntityId='+deleteIds.toEntityId;

    return httpDelete(url,'',accountId)
        .then((res)=>{
            return res;
        })
};


export const saveEntityLinking = (data, accountId) => {
    return httpPost('/service/customerData/entity/linking', data, accountId)
        .then((res) => {
            return res;
        })
};

export const setStatisticsFilter = (name,value) => dispatch => {

    dispatch( statisticsFilterChangeData({
        columnName:name,
        sortBy:value,
    }));
};

export const handleStatisticsFilterCount = (count) => dispatch => {
    dispatch(statisticsFilterCountData(count))
};


export const createSegment = (data, accountId) => {
    let url='/service/segmentation/segment';
    return httpPost(url, data, accountId)
        .then((res) => {
            return res;
        })
};


export const setSegments = (res) => dispatch => {
    dispatch(setSegmentsData(res)
    )
};

export const refreshSegments = () => dispatch => {
    dispatch(refreshSegmentsData())
};
export const getHitmapData = (accountId, data) => {
    let url;
    if(isEmpty(data)){
        url='/service/campaignManagement/campaign/statistics/report/week?skipCount=0&limitCount=10';
    }else{
        url='/service/campaignManagement/campaign/statistics/report/week?campaignId='+data+'&skipCount=0&limitCount=10'
    }

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};


export const generateUrlEndpointsForCampignListing = (campaignFilter) => {
    let urlBuilder = [];
    if (isEmpty(campaignFilter.keyword)){
        // urlBuilder.push('customerType='+'CUSTOMER')
    }else {
        urlBuilder.push('keyword=' + campaignFilter.keyword);
    }
    if (isEmpty(campaignFilter.status)){
        // urlBuilder.push('status='+'CUSTOMER')
    }else {
        urlBuilder.push('status=' + campaignFilter.status);
    }

    if (isEmpty(campaignFilter.campaignType) || campaignFilter.campaignType === 'ALL'){
        //do nothing
    }else {
        urlBuilder.push('campaignType=' + campaignFilter.campaignType);
    }

    if (isEmpty(campaignFilter.campaignMode) || campaignFilter.campaignMode === 'ALL'){
        //do nothing
    }else {
        urlBuilder.push('mode=' + campaignFilter.campaignMode);
    }

    return {
        endpointUrl: urlBuilder.join('&')
    }
};

export async function uploadAttachments(files,accountId,campaignID) {
    let res = [];
    if (files) {
        // store.dispatch(setAppLoader(true));
        // for (let index = 0; index < files.length; index++) {
        if (typeof document != 'undefined') {
            // I'm on the web!
            const formData = new FormData();
            formData.append('file', files);
            let filePathVal;
            if( typeof files === 'string'){
                filePathVal = files;
            } else {
                filePathVal = await uploadFile(formData, files.type, accountId,campaignID);
            }
            if (filePathVal) {
                res.push(filePathVal);
            } else {
                // store.dispatch(showToastMessage('danger',files.type+" Type not supported"));
                res = false;
                // break;
            }
        }
        // }
    }
    // store.dispatch(setAppLoader(false));
    return res;
}

const uploadFile = async (binaryFile, fileType,accountId,campaignID) =>  {
    for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL;
    if (fileType.includes('image')) {
        url += '/service/campaignManagement/campaign/media/upload/image?campaignId='+campaignID;
    }else{
        url += '/service/campaignManagement/campaign/media/upload/document?campaignId='+campaignID;
    }

    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {

            if (res) {

                if (statusCode === 200) {
                    return {
                        fileName: (res.fileName) ? res.fileName : '',
                        originalFileName: (res?.originalFileName) ? res?.originalFileName : '',
                    }
                } else {
                    store.dispatch( showToastMessage('danger',res.__error));
                    return null;
                }
            }
        }).catch(() => {
            store.dispatch(showSystemErrorBlock());
        });
};


export const getUserDetails = (accountId,customerDetailObj,modeType) => {

    let firstName=isEmpty(customerDetailObj?.firstName) ? null : customerDetailObj?.firstName;
    let emailId=isEmpty(customerDetailObj?.emailId) ? null :  customerDetailObj?.emailId;
    let lastName=isEmpty(customerDetailObj?.lastName) ? null :customerDetailObj?.lastName;
    let salutation=isEmpty(customerDetailObj?.salutation) ? null : customerDetailObj?.salutation;
    let phoneNumber=isEmpty(customerDetailObj?.phoneNumber) ? null : encodeURIComponent(customerDetailObj?.phoneNumber);
    let fullName=isEmpty(customerDetailObj?.fullName) ? null : customerDetailObj?.fullName;

    let url='/service/campaignManagement/campaign/drillDown/user?emailId='+emailId+
        '&firstName=' +firstName+
        '&lastName=' +lastName+
        '&salutation=' +salutation+
        '&phoneNumber='+phoneNumber+
        '&fullName='+fullName+
        '&mode='+modeType;

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getUserDevicesDetails = (accountId,customerDetailObj,modeType) => {

    let firstName=isEmpty(customerDetailObj?.firstName) ? null : customerDetailObj?.firstName;
    let emailId=isEmpty(customerDetailObj?.emailId) ? null :  customerDetailObj?.emailId;
    let lastName=isEmpty(customerDetailObj?.lastName) ? null :customerDetailObj?.lastName;
    let salutation=isEmpty(customerDetailObj?.salutation) ? null : customerDetailObj?.salutation;
    let phoneNumber=isEmpty(customerDetailObj?.phoneNumber) ? null : customerDetailObj?.phoneNumber;
    let fullName=isEmpty(customerDetailObj?.fullName) ? null : customerDetailObj?.fullName;
    let url='/service/campaignManagement/campaign/drillDown/devices?emailId='+emailId+
        '&firstName=' +firstName+
        '&lastName=' +lastName+
        '&salutation=' +salutation+
        '&phoneNumber='+phoneNumber+
        '&fullName='+fullName+
        '&mode='+modeType;

    return httpGet(url,accountId)
        .then(res => {
            return res;
        })
};

export const setCustomerFilter = (name,value)=> {
    store.dispatch(customerFilterData(
        {
            name:name,
            value:value
        }
    ))
};
export const setQuickStart = (data)=> {

    store.dispatch(setQuickStartData(
        {
            isFromQuickStart:data.isFromQuickStart,
            segment:data.segment,
            content:data.content
        }
    ))
};


export const resetQuickStart = () => {
    store.dispatch(resetQuickStartData())
};

export const resetSegmentListingData = () => {
    store.dispatch(resetSegmentData())
};

export const setSegmentTypeValue = (e) => {
    store.dispatch(setSegmentType(e))
};

export const CampaignTypeFilter = (name,value) => dispatch => {
    dispatch(campaignTypeFilterData(
        {
            name:name,
            value:value
        }
    ))
};
export const CampaignModeFilter = (name,value) => dispatch => {
    dispatch(campaignModeFilterData(
        {
            name:name,
            value:value
        }
    ))
};

export const sendImmediately = (data, accountId) => {
    return httpPost('/service/campaignManagement/campaign/quickActivate', data, accountId)
        .then((res) => {
            return res;
        })
};

export const getAllCampaignMetricsCount = (accountId,campaignId) => {
    let url='/service/campaignManagement/campaign/metrics?campaignId='+campaignId;
    return httpGet(url, accountId).then((res) => {
        return res
    })
};

export const getSelectedContentFeilds = (accountId, contentType) => {
    return httpGet('/service/customerData/content/personalizedAttributes?contentType='+contentType, accountId)
        .then(res => {
            return res;
        })
};

export const getConfigurations = (accountId) => {
    return httpGet('/service/campaignManagement/campaign/application/config', accountId)
        .then(res => {
            return res;
        })
};

export const createWhatsAppTemplate = (accountId, data) => {
    return httpPost('/service/channels/whatsApp/template', data, accountId, true)
        .then(res => {
            return res;
        })
};

export const sendTestWhatsappMessage = (data, accountId) => {
    return httpPost('/service/campaignManagement/campaign/sendTestWhatsAppMessage', data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 201:
                case 204:
                    break;
                case 400:
                case 401:
                    store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
            }
            return res;
        }).catch((err)=>{
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const handleGenerateTemplate = (accountId, data) => {
    return httpPost('/service/campaignManagement/campaign/generateAutoTemplate', data, accountId, true)
        .then(res => {
            return res;
        })
};

export const validateEmailTemplate = (accountId, data) => {
    return httpPost('/service/channels/email/validate', data, accountId, true)
        .then(res => {
            return res;
        })
};


export const validateWhatsAppTemplate = (accountId, data) => {
    return httpPost('/service/channels/whatsapp/validate', data, accountId, true)
        .then(res => {
            return res;
        })
};
