import React, {useEffect, useState} from 'react';
import ResAppBarView from "./ResAppBarView";
import {connect} from "react-redux";
import {logoutUser} from "../../user/userActions";
import {withRouter} from "react-router-dom";
import {
    getAllAccounts,
    getCreateAccountValidation, getMyPendingInvitation, onYearToMonthChange,
    resetAccountData,
    resetAccountsList
} from "../../accountManagement/accountManagementActions";
import store from "../../store";
import {showToastMessage} from "../actions/appActions";
import {refreshWindowScrollElement} from "../../utils/helper";
import {refreshSegmentsData} from "../../campaign/campaignReducer";
import {resetEmailTemplateFilter, resetQuickStart} from "../../campaign/campaignAction";
import {isEmpty} from "../../utils/validations";
import {setAccountsCallFired} from "../../accountManagement/accountManagementReducer";

const ResAppBar = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSettings, setAnchorSettings] = useState(false);
    const [isAccountDialogOpen, setIsAccountDialogOpen] = useState(false);
    const [showCreatAccountButton, setshowCreatAccountButton] = useState(false);
    const [showChooseAccount, setShowChooseAccount] = useState(false);

    useEffect(() => {

        if (props.userAuthStatus === 'AUTH_STATUS_AUTHORIZED') {
            props.getAllAccounts().then(res => {
                props.setAccountsCallFired(true)
                if (!isEmpty(res.data)) {
                    setShowChooseAccount(true)
                } else {
                    getMyPendingInvitation()
                        .then((res) => {
                            if (!isEmpty(res.data?.invitations)) {
                                setShowChooseAccount(true)
                            } else {
                                setShowChooseAccount(false)
                            }
                        });
                }
            });
        }
    }, [props.userAuthStatus, props.isUpdateAccounts]);

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemsOpen = event => {
        setAnchorSettings(event.currentTarget);
    };

    const handleMenuItemsClose = () => {
        setAnchorSettings(null)
    };

    const [isDeleteDialogOpen] = useState(false);

    return (
        <ResAppBarView
            {...props}
            anchorEl={anchorEl}
            anchorSettings={anchorSettings}
            handleItemOpen={handleItemOpen}
            showChooseAccount={showChooseAccount}
            handleItemClose={handleItemClose}
            handleMenuItemsOpen={handleMenuItemsOpen}
            handleMenuItemsClose={handleMenuItemsClose}
            refreshWindowScrollElement={refreshWindowScrollElement}
            resetQuickStart={resetQuickStart}
        />
    );
};


ResAppBar.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    account: state.accountManagementState.account,
    accounts: state.accountManagementState.accounts,
    myPendingInvitations: state.accountManagementState.myPendingInvitations,
    isUpdateAccounts: state.accountManagementState.isUpdateAccounts,
    accountLoaded: state.accountManagementState.accountLoaded,
    profile: state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    isXLView: state.appState.deviceInfo.isXLView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation,
    userStatus: state.userState.userStatus,
    isLoggedOutScreen: state.userState.isLoggedOutScreen,
});


export default connect(mapStateToProps, {
    logoutUser,
    resetAccountsList,
    resetAccountData,
    getCreateAccountValidation,
    getAllAccounts,
    refreshWindowScrollElement,
    refreshSegmentsData,
    resetEmailTemplateFilter,
    setAccountsCallFired
})(withRouter(ResAppBar))
