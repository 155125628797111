import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {Provider} from "react-redux";
import store from "./store";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import {getAppEnvironmentVariables} from "./utils/httpHelper";
import * as log from 'loglevel';
import {DLThemeProvider} from 'dlcomponent';
import AppNavigator from "./AppNavigator";
import appTheme from "./assets/appTheme";
import {debounce} from "./utils/helper";
import {AppResize, setMobileDevice} from "./core/reducers/appReducer";
import TagManager from 'react-gtm-module';

// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
let windowInnerWidth = 0;

const muiTheme = createTheme({
    typography: {
        fontSize: appTheme.default.fontSize
    },
    palette: {
        primary: {
            main: appTheme.appColor.primary,
        },
        secondary: {
            main: appTheme.appColor.secondary,
        },
        darkBlue: {
            main: appTheme.appColor.darkBlue,
        },
        success: {
            main: appTheme.appColor.success,
        },
        warning: {
            main: appTheme.appColor.warning,
        },
        danger: {
            main: appTheme.appColor.danger,
        },
        error: {
            main: appTheme.appColor.danger,
        },
        gray: {
            main: appTheme.appColor.gray,
        },
        grey: {
            main: appTheme.appColor.grey,
        },
        black: {
            main: appTheme.appColor.black,
        },
        white: {
            main: appTheme.appColor.white,
        },
        info: {
            main: appTheme.appColor.info,
        },
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '12px',
                color: '#fff',
                backgroundColor: '#000',
            },
        },
        MuiAccordionSummary: {
            root: {
                // backgroundColor: '#f1eded',
                '&$expanded': {
                    boxShadow:
                      '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
                    // backgroundColor: '#f1eded',
                    // fontFamily: "'Poppins', sans-serif",
                    fontFamily: "Roboto",
                    color: '#000',
                    // padding:'0 10px !important',
                    minHeight: 'unset',
                    '& .MuiAccordionSummary-expandIcon': {
                        color: '#000',
                    },
                },
            },
            content: {
                '&$expanded': {
                    margin: '0px 0px',
                },
            },
        },

        MuiAccordionDetails: {
            root: {
                padding: '0 !important',
            }
        },

        MuiAccordion: {
            root: {
                '&:before': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiToolbarRegular: {
            minHeight: '44px'
        },

        MuiSelect: {
            select: {
                minWidth: '120px !important',
                padding: '7px 10px !important',
                textAlign: 'left !important',
                fontSize: '14px !important',
            },

        }

    },
});

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
};

const App = () => {
    const setMobileView = debounce(() => {

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

        const currentWindowInnerWidth = window.innerWidth;
        store.dispatch(AppResize({
            isXLView: window.innerWidth > 1200,
            isLGView: window.innerWidth >= 992 && window?.innerWidth <= 1200,
            isMDView: window.innerWidth >= 768 && window?.innerWidth <= 992,
            isSMView: window.innerWidth >= 576 && window?.innerWidth <= 768,
            isMobileView: window.innerWidth <= 992,
            isXSView: window.innerWidth < 576,
            windowHeight:window.innerHeight
        }));
        //reduce height as per window height(while search bar is open and close)
        if (windowInnerWidth === 0 || currentWindowInnerWidth !== windowInnerWidth){
            if (isMobile || isIpad){
                let vh = window.innerHeight ;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
        }

        if (isMobile || isIpad){
            store.dispatch(setMobileDevice(true))
        }else {
            store.dispatch(setMobileDevice(false))
        }

    }, 300);

    const zoomOutMobile=()=>{
        var viewport = document.querySelector('meta[name="viewport"]');

        if (!viewport) {
            return;
        }

        if (window.innerWidth >= 384) {
            return;
        }

        let initScale = window.innerWidth / 384;
        initScale = Math.round(initScale * 100) / 100;

        viewport.content = "initial-scale=" + initScale;
    };

    useEffect(()=>{
        zoomOutMobile();
        setMobileView();//for the first time
        window.addEventListener('resize', setMobileView);
        TagManager.initialize(tagManagerArgs);

        return ()=>{
            window.removeEventListener('resize', setMobileView);
        }
    },[]);



    const [isThemeLoaded, setIsThemeLoaded] = useState(false);

    useEffect(() => {
        const {REACT_APP_ENVIRONMENT} = getAppEnvironmentVariables();
        if (REACT_APP_ENVIRONMENT === "DEVELOPMENT" || REACT_APP_ENVIRONMENT === "QA") {
            log.setLevel("DEBUG");
        }

        setTimeout(() => {
            setIsThemeLoaded(true)
        }, 100)
    }, []);

    const renderMainContent = () => {

        if (!isThemeLoaded) {
            return <></>
        }

        return (<div className='selectionRemove'>
            <AppNavigator/>
        </div>)
    };

    return (
      <DLThemeProvider theme={appTheme}>
          <div style={{...appTheme.default}}>
              <ThemeProvider theme={muiTheme}>
                  <I18nextProvider i18n={i18n}>
                      <Provider store={store}>
                          <Router basename={'/app'}>
                              {renderMainContent()}
                          </Router>
                      </Provider>
                  </I18nextProvider>
              </ThemeProvider>
          </div>
      </DLThemeProvider>
    );
};
export default App;
