import React, {useEffect, useState} from 'react';
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../../../utils/validations";
import {NavLink} from "react-router-dom";
import DLAccordion from "dlcomponent/components/DLAccordion/DLAccordion";
import store from "../../../store";
import {changeDrawerWidth2} from "../../actions/appActions";
import {APP_CONST} from "../../AppConstants";
import {isShowAccountRelatedUI} from "../../../accountManagement/accountManagementActions";
import {refreshWindowScrollElement} from "../../../utils/helper";


function SideMenuOptionsView(props) {

    const [section, setSection] = useState({});
    const {handleOtherOptions, isOtherOptionsOpen, isXSView, account} = props;

    useEffect(()=>{
        expand()
    },[]);

    const expand = (selectedSection) => {
        let isSectionOpen = false;
        if(section[selectedSection + 'Expanded'] === undefined){
            isSectionOpen = false;
        } else {
            isSectionOpen = !section[selectedSection + 'Expanded'];
        }

        setSection({
            ...section,
            [selectedSection + 'Expanded']: isSectionOpen
        });
    };

    const checkReadOnlyAccess = (btn) => {
        const {account} = props;
        let memberRole = account?.memberRole;

        if (isEmpty(memberRole)){
            return false
        }

        switch (memberRole?.name) {
            case 'IT'://campaign, segment, email template, account overview, billing and invoice, subscritpion
                return btn === 'Campaigns' || btn === 'Segments' || btn === 'Templates' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription';
            case 'ANALYST'://campaign ,  setting, email templates, account overview, subscription, billing and invoice
                return btn === 'Campaigns' || btn === 'Templates'|| btn === 'DataSetup' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription' || btn === 'Settings';
            case 'DESIGNER': //campaign, segment, data setup, account overview, billing and invoice, setting, subscription
                return btn === 'Campaigns' || btn === 'Segments' || btn === 'DataSetup' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription' || btn === 'Settings';
            case 'MARKETING'://account overview, billing and invoice, subscritpion
                return btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription';
            case 'VIEWER'://account overview, billing and invoice, subscritpion
                return btn === 'BillingInvoice' ;
            default:
                return false
        }

    };

    const getMenuOptions = () => {
        const {account, resetQuickStart, refreshWindowScrollElement} = props;
        return [
            {
                label: 'Home',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false)
                },
                route: isEmpty(account?.id) ? '/accounts/dashboard' : '/accounts/' + account?.id + '/dashboard',
                icon: (<i className="fa-sharp fa-solid fa-house-chimney"/>),
            },
            {
                label: 'Campaigns',
                isDisabled: checkReadOnlyAccess('Campaigns'),
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                    resetQuickStart()
                },
                route: '/accounts/' + account?.id + '/campaigns',
                icon: (<i className={'fas fa-bullhorn '}/>),
            },
            {
                label: 'Segments',
                isDisabled: checkReadOnlyAccess('Segments'),
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                    props.refreshSegmentsData()
                },
                route: '/accounts/' + account?.id + '/segments',
                icon: (<i className={'fas fa-server '}/>),
            },
            {
                label: 'Contents',
                isDisabled: checkReadOnlyAccess('DataSetup'),
                section: 'CustomerData',
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                },
                icon: (<i className="fa-solid fa-database"/>),
                route: '/accounts/' + account?.id + '/contents',

            },
        ];
    };


    const getOtherOptions = () => {
        const {account} = props;
        return [
            {
                label: 'Account Overview',
                isDisabled: checkReadOnlyAccess('AccountOverview'),
                onClick: () => {
                    refreshWindowScrollElement();
                    store.dispatch(changeDrawerWidth2(APP_CONST.FULL_DRAWER_WIDTH));
                },
                route: '/accounts/' + account.id + '/overview',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'User Management',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                    store.dispatch(changeDrawerWidth2(APP_CONST.FULL_DRAWER_WIDTH));
                },
                route: '/accounts/' + account.id + '/users-roles-groups',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Billing & Invoice',
                isDisabled: checkReadOnlyAccess('BillingInvoice'),
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account.id + '/billing',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Support',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route:  '/user/contact-us',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Templates',
                isDisabled: checkReadOnlyAccess('Templates'),
                icon: (<i className={'   fas fa-border-all '}/>),
                section: 'Templates',
                subSections: [
                    {
                        label: 'Email Templates',
                        icon: (<div className={'bullet-dot'}
                                    style={{marginRight: '14px'}}/>),
                        route: '/accounts/' + account?.id + '/email-templates  ',
                        onClick: () => {
                            refreshWindowScrollElement();
                        }
                    },
                ]
            },
            {
                label: 'Settings',
                section: 'Settings',
                isDisabled: checkReadOnlyAccess('Settings'),
                icon: (<i className={'  fas fa-cogs '}/>),
                route: '/accounts/' + account?.id + '/settings/sender-emails'
            },


        ];
    };

    const renderMobileDrawerBtn = () => {
        return (
            <div className='cursor' onClick={() => handleOtherOptions(!isOtherOptionsOpen)}>
                <div className='d-flex flex-column justify-content-center cursor align-items-center pe-1 mb-1 mt-2'>
                            <span>
                            <i className={'fas fa-bars icon-size'} style={{fontSize: 18}}/>
                            </span>
                    <div className={'sideMenuLabel'} style={{fontSize:'12px',color:'#0B407D'}}>
                        {'More'}
                    </div>
                </div>
            </div>
        );
    };

    const renderMenuItemUi = (item) => {

        return (
            <div className={'px-2 menu-item py-1'}>
                <DLText
                    id={''}
                    type={"normal"}
                    isInline={true}
                    customWrapperStyle={
                        {textAlign: 'center'}
                    }
                    marginBottom={"none"}
                    customTextStyle={{
                        color: !item?.isDisabled ? '#C8C8C8' : '#8B8B8B'
                    }}
                    text={item?.label}
                    icon={!isEmpty(item?.icon) && <span style={{color: !item?.isDisabled ? 'white' : '#8B8B8B',marginRight:10}}>{item?.icon}</span>}
                />
            </div>
        );
    };

    const getRoute = (item) =>{
        if (item?.isDisabled){
            return props.currentPathname
        }
        return item?.route
    };

    return (
        <div className={'d-flex flex-column justify-content-between'}
             style={{backgroundColor:'#fff',color:'#0B407D',height:'calc(100% - 54px)'}}>
            {
                getMenuOptions().map((item) => {
                    return (
                        <NavLink to={getRoute(item)}
                                 exact
                                 className={item?.isDisabled ? 'disableCursor' : '' }
                                 style={{flex:!isXSView && 1, display:'flex', alignItems:'center',justifyContent:'center'}}
                                 activeClassName={(isOtherOptionsOpen || item.isDisabled) ? ' horizontal-line' :  "active-side-option-link  horizontal-line"}>

                            <div onClick={() => {
                                handleOtherOptions(false);
                                item?.onClick();
                            }}>
                                <div>
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <span >
                                               <DLText id={''}
                                                       text={item?.icon}
                                                       fontSize={'xs'}
                                                       marginBottom={'none'}
                                                       fontColor={item?.isDisabled ? 'gray' : isOtherOptionsOpen ? 'black' : 'white'}
                                               />
                                            </span>
                                        <span className={'sideMenuLabel'} >
                                               <DLText id={''}
                                                       text={item?.label}
                                                       fontSize={'xs'}
                                                       marginBottom={'none'}
                                                       fontColor={item?.isDisabled ? 'gray' : isOtherOptionsOpen ? 'black' : 'white'}
                                               />
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </NavLink>
                    )
                })
            }

            <div style={{flex:!isXSView && 1}} className={isOtherOptionsOpen ? 'flex-1 d-flex justify-content-center align-items-center active-side-option-link px-3' :
                'flex-1 d-flex justify-content-center align-items-center px-3'}>
                {renderMobileDrawerBtn()}
            </div>

            {isOtherOptionsOpen &&
            <>
                {/*<i className="fa-solid fa-caret-left"></i>*/}
                <div className=' fixed-bottom' style={{
                    position: 'fixed',
                    left: 63,
                    minWidth: 150,
                    maxWidth: 180,
                    maxHeight:'240px',
                    overflow:'auto',
                    backgroundColor: '#212529',
                }}>

                    {getOtherOptions().map((item, index) => {
                        if (isEmpty(item?.subSections) || item.isDisabled) {
                            return (
                                <div >
                                    <NavLink to={{
                                        pathname:getRoute(item),
                                        state: { accountId:account?.id }
                                    }}
                                             exact
                                             className={item?.isDisabled ? 'disableCursor' : '' }
                                             activeClassName={item.isDisabled ? '' : "active-side-option-link"}>
                                        <div key={'section-' + index} onClick={()=>{
                                            if(!item.isDisabled){
                                                 handleOtherOptions(false)
                                            }
                                        }
                                        }>
                                            <div onClick={() => item?.onClick ? item?.onClick() : {}}>
                                                {renderMenuItemUi(item)}
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            );
                        }

                        return (
                            <div key={'section-' + index} className="left-panel-submenus menuAccordion">
                                <DLAccordion
                                    id={'about-drawer-opt3'}
                                    containerPadding={"none"}
                                    showIcon={!props.isDrawerWidth}
                                    color={'white'}
                                    title={(<>{renderMenuItemUi(item)}</>)}
                                    isExpanded={(section[item?.section + 'Expanded'] === true) }
                                    headerPadding={'none'}
                                    onChange={() => expand(item?.section)}
                                    background={'none'}>

                                    {item?.subSections?.map((subItem, subindex) => {
                                        return (
                                            <div key={'subSection-' + subindex}
                                                 onClick={() =>handleOtherOptions(false)}
                                                 className=''>

                                                <NavLink to={subItem?.route}
                                                         exact
                                                         activeClassName="active-link">

                                                    <div onClick={() => subItem?.onClick ? subItem?.onClick() : {}}>
                                                        {renderMenuItemUi(subItem, true)}
                                                    </div>

                                                </NavLink>
                                            </div>

                                        );
                                    })}

                                </DLAccordion>
                            </div>
                        )

                    })}

                </div>
            </>
            }
        </div>
    )
}

SideMenuOptionsView.propTypes = {};

export default SideMenuOptionsView;
