
export const isEmpty = value =>
    value === undefined ||
    value === 'undefined' ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);


export const compareSDGArrays = (arr1, arr2) => {
    if (!arr1 || !Array.isArray(arr1)) {
        return false;
    }

    if (!arr2 || !Array.isArray(arr2)) {
        return false;
    }

    if (arr1.length !== arr2.length) {
        return false;
    }

    let isMatchingArray = true;

    for (let i = 0; i <= arr1.length; i++) {

        let SDGFound = arr2.find(obj2 => {
            return arr1[i]?.goalNum === obj2?.goalNum
        });

        if (!SDGFound) {
            isMatchingArray = false;
            break;
        }
    }

    return isMatchingArray;

};
