export const userConst = {
    AUTH_STATUS_UNAUTHORIZED: 'AUTH_STATUS_UNAUTHORIZED',
    AUTH_STATUS_AUTHORIZED: 'AUTH_STATUS_AUTHORIZED',
    AUTH_STATUS_LOGGED_OUT: 'AUTH_STATUS_LOGGED_OUT',
    ACCESS_DENIED: 'ACCESS_DENIED',
};

export const emailVerificationConst = {
    CODE_VERIFY: 'CODE_VERIFY',
    CODE_VERIFY_SUCCESS: 'CODE_VERIFY_SUCCESS',
    CODE_VERIFY_FAIL: 'CODE_VERIFY_FAIL'
};


export const signupConst = {
    SIGN_UP: 'SIGN_UP',
    USER_EXISTS: 'USER_EXISTS'
};

export const loginConst = {
    LOGIN: 'LOGIN',
    USER_NOT_EXIST: 'USER_NOT_EXIST',
    LOGIN_FAILED: 'LOGIN_FAILED',
};

export const forgotPasswordConst = {
    ENTER_EMAIL: 'ENTER_EMAIL',
    EMAIL_NOT_EXISTS: 'EMAIL_NOT_EXISTS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    RESET_LINK_SENT:'RESET_LINK_SENT'
};
export const resetPasswordConst = {
    NEW_PASSWORD: 'NEW_PASSWORD',
    RESET_LINK_EXPIRED: 'RESET_LINK_EXPIRED',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED:'RESET_PASSWORD_FAILED'
};
