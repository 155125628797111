import React, {lazy, Suspense, useEffect, useState} from 'react';
import ResAppBar from "./core/layout/ResAppBar";
import {connect} from "react-redux";
import {checkForSideMenuAvailableOrNot} from "./glacier/glacierActions";
import {Route, Switch, withRouter} from "react-router-dom";
import {getAuthorizationToken, getMetaTagUi} from "../src/utils/httpHelper";
import {isEmpty} from "./utils/validations";
import {getMyProfile} from "./user/userActions";
import {
    setBetaRequestResponse,
    setCurrentPathname,
    setPrevLocation,
    showSystemErrorBlock
} from "./core/actions/appActions";
import Modules from "./Modules";
import pageNotFound from "./core/components/PageNotFound/pageNotFound";
import DLToastMessage from "./core/components/DLToastMessage/DLToastMessage";
import DLLoader from "./core/components/DLLoader/DLLoader";
import PostLoginRoute from "./core/webRouteWrapper/PostLoginRoute";
import {isMobileInLandscape, isShowAppbar, refreshWindowScrollElement} from "./utils/helper";
import SideMenuOptions from "./core/layout/SideMenuOptions/SideMenuOptions";
import {userConst} from "./user/userConsts";
import {resetLoggedOutState} from "./user/userReducer";
import store from "./store";
import InboxDetail from "./inbox/inboxDetail/InboxDetail";
import PreLoginRoute from "./core/webRouteWrapper/PreLoginRoute";
import HomePage from "./content/components/HomePage";
import LoggedOutUser from "./core/components/LoggedOutUser/LoggedOutUser";
import {getSeoData} from "./core/actions/appActions"
import {getBetaRequestData} from "./accountManagement/accountManagementActions";
import PrePostLoginRoute from "./core/webRouteWrapper/PrePostLoginRoute";
import PageNotAccessible from "./core/components/PageNotAccessible/PageNotAccessible";
import {hideSystemErrorBlockData} from "./core/reducers/appReducer";
import {resetContentCreateState} from "./glacier/glacierReducer";
import BetaRequestApproved from "./beta/betaRequestApproved/BetaRequestApproved";
import SiteUnderMaintenance from "./siteUnderMaintenance/SiteUnderMaintenance";

const List = lazy(() => import('./accountManagement/account/accountListing/AccountListing'));

let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

const AppNavigator = (props) => {

    const [isShowResAppbar, setIsShowResAppbar] = useState(true);
    const [isSiteUnderMaintenance, setIsSiteUnderMaintenance] = useState(false);
    const [accountObj, setAccountObj] = useState({});
    const [isOtherOptionsOpen, setIsOtherOptionsOpen] = useState(false);
    const [isSettingOptionShow, setIsSettingOptionShow] = useState(false);
    const [seoMetaDataState, setSeoMetaDataState] = useState({
        seoMetaData: null,
        isMetaDataCallFired: false,
    });
    const [isProfileSet, setIsProfileSet] = useState(true);
    let authResponse = getAuthorizationToken();

    useEffect(()=>{
        if (props.userAuthStatus ===  userConst.AUTH_STATUS_AUTHORIZED && authResponse.status && !isEmpty(authResponse.token)) {
            getSeoData().then((res) => {
                if (res.success) {
                    setSeoMetaDataState((prevState) => ({
                        ...prevState,
                        seoMetaData: res.data?.SEO,
                        isMetaDataCallFired: true
                    }))
                }
            });
            if (props.location.pathname !== "/beta-request/approved") {
                getBetaRequestData(true).then((res) => {
                    setBetaRequestResponse(res.data);
                });
            }
        }
    },[props.userAuthStatus]);

    useEffect(() => {
        if (authResponse.status && !isEmpty(authResponse.token)) {
            getMyProfile().then((res) => {
                if (res.success) {
                    setIsProfileSet(true);
                } else {
                    if (res.statusCode === 401 || res.statusCode === 403) {
                        setIsProfileSet(true)
                    } else {
                        setIsProfileSet(false);
                        store.dispatch(showSystemErrorBlock());
                        setIsSiteUnderMaintenance(true)
                    }
                }
            })
        }
    }, []);

    const resetReduxStates = () =>{
        setCurrentPathname(props.location.pathname);
        props.setPrevLocation(props.location);
        store.dispatch(hideSystemErrorBlockData());
        store.dispatch(resetContentCreateState());
    };

    useEffect(() => {
        setCurrentPathname(props.location.pathname);
        resetReduxStates();
        checkShowAppbar();
        refreshWindowScrollElement();
        if (isEmpty(profile)) {
            return
        }
        // Store an entry in localstorage for remembering the last used account.
        // The key for this will include emailId so that multiple logins on the same machine will also work well.
        let accountObj = localStorage.getItem("account_" + [profile?.email]);
        accountObj = JSON.parse(accountObj);
        setAccountObj(accountObj);

    }, [props.location.pathname, props.account, props.userAuthStatus]);

    useEffect(() => {
        const {account} = props;

        if (props.userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
        if (props.userStatus === userConst.ACCESS_DENIED) {
            if (isEmpty(account?.id)) {
                props.history.push('/accounts/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            } else {
                props.history.push('/accounts/' + account?.id + '/access-denied', {
                    fullUrl: window.location.href,
                    prevPath: props.location.pathname
                });
            }
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);

    // const checkBetaPlanStatus = (betaObject) => {
    //     // if (!isEmpty(betaObject)) {
    //     //     if ((betaObject?.BetaResponse?.status === "APPROVED") && isEmpty(betaObject?.BetaResponse?.BetaAccount)) {
    //     //         props.history.push('/beta-request/approved',{betaResponseId: betaObject.id});
    //     //     }
    //     // }
    // };

    const checkShowAppbar = () => {
        const {pathname} = props.location;
        if (props.userAuthStatus === 'AUTH_STATUS_UNAUTHORIZED'
            || props.location.pathname === '/'
            || props.location.pathname === '/features/campaigns'
            || props.location.pathname === '/features/email-templates'
            || props.location.pathname === '/tnc'
            || props.location.pathname === '/privacy-policy'
            || props.location.pathname === '/features/segmentation'
            || props.location.pathname === '/features/data-setup'
            || props.location.pathname === '/use-cases/e-commerce'
            || props.location.pathname === '/use-cases/education'
            || props.location.pathname === '/about-us'
        ) {
            setIsShowResAppbar(false);
            return;
        }

        setIsShowResAppbar(isShowAppbar(pathname));
    };

    const checkSideMenuOptionsAvailable = () => {
        const {userAuthStatus, account, isLoggedOutScreen} = props;

        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED
            || isEmpty(account)
            || isLoggedOutScreen
            || isIpad) {
            return false;
        }

        return isMobileInLandscape();

    };

    const renderContent = () => {
        const {seoMetaData} = seoMetaDataState;
        const {userAuthStatus} = props;
        let isAuthenticated = userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED;
        return (
            <div className="w-100 d-flex bg-white" id=""
                 style={{
                     height: '100%',
                     marginLeft: checkSideMenuOptionsAvailable() ? 57 : 0
                 }}
                 onClick={() => {
                     setIsSettingOptionShow(false)
                 }}>

                {getMetaTagUi(props.location.pathname, seoMetaData, isAuthenticated, props.account)}

                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PreLoginRoute exact path="/" component={HomePage}/>
                        <PostLoginRoute exact path={"/accounts"} component={List}/>
                        <PostLoginRoute exact path="/emails/:messageId" component={InboxDetail}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>
                        <Route exact path={"/accounts/access-denied"} component={PageNotAccessible}/>
                        <Route exact path={"/beta-request/approved"} component={BetaRequestApproved}/>
                        <Route exact path={"/logoutAction"} component={HomePage}/>
                        {Modules.map(module => {
                            return (<Route key={module.name} {...module.routeProps} />);
                        })}

                        <Route path="*" component={pageNotFound}/>
                    </Switch>
                </Suspense>
                <DLToastMessage/>

            </div>
        );
    };

    const renderSideMenu = () => {
        const {isMobileView, account, userAuthStatus} = props;

        if (checkForSideMenuAvailableOrNot(props.location)) {
            if (userAuthStatus === 'AUTH_STATUS_AUTHORIZED') {
                return (
                    <div style={{position: 'fixed', top: 54, height: '100%', zIndex: 100}}>
                        <SideMenuOptions history={props.history}
                                         isMobileView={isMobileView}
                                         account={account}
                                         handleOtherOptions={handleOtherOptions}
                                         isOtherOptionsOpen={isOtherOptionsOpen}
                        />
                    </div>
                )
            }
        }
    };

    const handleSettingIconClick = (value) => {
        setIsSettingOptionShow(value);
    };

    const renderAppBar = () => {
        return (
            <ResAppBar
                accountObj={accountObj}
                isSettingOptionShow={isSettingOptionShow}
                handleSettingIconClick={handleSettingIconClick}
            />
        );
    };

    const handleOtherOptions = (otherOptionOpen) => {
        setIsOtherOptionsOpen(otherOptionOpen);
        setIsSettingOptionShow(false)
    };

    const {profile, isProfileCallFired} = props;

    if(isSiteUnderMaintenance){
        return <SiteUnderMaintenance {...props}/>
    }

    //TODO: need to check for network error here and handle navigation
    if (authResponse.status && !isEmpty(authResponse.token)) {
        if (!isProfileCallFired && isEmpty(profile)){
            return
        }

        if (!isProfileSet) {
            return (
                <>
                    <DLToastMessage/>
                </>
            )
        }
    }

    return (
        <div style={{
            height: '100dvh'
        }}>
            {renderAppBar()}
            <div className='d-flex' style={{
                paddingTop: isShowResAppbar ? '54px' : '0px',
                height: '100%'
            }}>
                {checkSideMenuOptionsAvailable() &&
                renderSideMenu()
                }
                {renderContent()}
            </div>
        </div>

    );
};


AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    userStatus: state.userState.userStatus,
    profile: state.userState.profile,
    isProfileCallFired: state.userState.isProfileCallFired,
    isProfileLoaded: state.userState.isProfileLoaded,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    windowHeight: state.appState.deviceInfo.windowHeight,
    account: state.accountManagementState.account,
    isDrawerOpen: state.appState.isDrawerOpen,
    isBetaRequestLoaded: state.appState.isBetaRequestLoaded,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AppNavigator));

