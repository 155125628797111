import {userTypes} from "../user/userTypes";

const initialState = {

};

export default function accountService(state=initialState,action) {
  if (action.type === userTypes.USER_LOGOUT) {
    return {
      ...initialState,
    };
  } else {
    return {
      ...state
    }
  }
}
