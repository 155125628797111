import * as log from "loglevel";
import {isEmpty} from "./validations";
import store from "../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {setNotAccessible, userLoggedOut} from "../user/userReducer";
import Helmet from 'react-helmet';
import React from "react";

const API_URL = process.env.REACT_APP_API_URL;

let controller = new AbortController();
let signal = controller.signal;


export function getAuthorizationToken() {
    let token = null;
    try {
        let fromStorage = '';
        if (localStorage.hasOwnProperty('jwtToken')) {
            token = localStorage.getItem('jwtToken');
            fromStorage = 'localStorage';
        } else if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
            token = sessionStorage.getItem('jwtSessionToken');
            fromStorage = 'sessionStorage';
        } else {
            token = null;
        }
        if (token) {
            return {
                status: true,
                token: token,
                fromStorage: fromStorage
            };
        } else {
            return {
                status: false,
                err: 'no token available',
            };
        }
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }
}

export function setAuthorizationToken(token, toRemember) {
    try {

        if (toRemember) {
            localStorage.setItem('jwtToken', token);
            // if there is any session stored by mistake remove it
            if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
                sessionStorage.removeItem('jwtSessionToken');
            }

        } else {
            sessionStorage.setItem('jwtSessionToken', token);
// if there is any local key stored by mistake remove it
            if (localStorage.hasOwnProperty('jwtToken')) {
                localStorage.removeItem('jwtToken');
            }
        }
        return {
            status: true,
        };
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }
}

export function removeAuthorizationToken() {
    try {
        if (localStorage.hasOwnProperty('jwtToken')) {
            localStorage.removeItem('jwtToken');
        }
        if (sessionStorage.hasOwnProperty('jwtSessionToken')) {
            sessionStorage.removeItem('jwtSessionToken');
        }
        // for safari push message
        if (localStorage.hasOwnProperty('pushNotificationSubscribed')) {
            localStorage.removeItem('pushNotificationSubscribed');
        }

        // if user logout remove the date limit will reset
        if (localStorage.hasOwnProperty('push_notification_date_limit')) {
            localStorage.removeItem('push_notification_date_limit');
        }

        return {
            status: true,
        };
    } catch (err) {
        return {
            status: false,
            err: err,
        };
    }

}

export const httpGet = (url, accountId, handleErrorInComponent, handleSystemErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }
    return fetch(API_URL + url, {
        method: 'GET',
        headers: headers,
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response;
            } else {
                return response.json();
            }

        })
        .then(data => {

            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent) {
                    } else {
                        if (statusCode === 403) {
                            store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                if (handleSystemErrorInComponent) {
                    return {data: null, statusCode: statusCode, success: false}
                }else {
                    store.dispatch(showSystemErrorBlock());
                    return {data: null, statusCode: statusCode, success: false}
                }
            }
        })
        .catch((error) => {
            if (handleSystemErrorInComponent) {
                return {data: null, statusCode: statusCode, success: false}
            }else{
                logAPIError({statusCode: statusCode, error: error, api: url, apiMethod: 'GET'});
                store.dispatch(showSystemErrorBlock());
                return {statusCode: statusCode, error: error, success: false}
            }
        });
};

export const httpPost = (url, data, accountId, handleErrorInComponent) => {
    let statusCode;
    let headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }

    return fetch(API_URL + url, {
        method: 'POST',
        headers: headers,
        body: isEmpty(data) ? '' : JSON.stringify(data),
    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;
            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (handleErrorInComponent) {

                    } else {
                        if (statusCode === 403) {
                            store.dispatch(setNotAccessible())
                        } else {
                            store.dispatch(showToastMessage('warning', data.__error))
                        }
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'POST'});
            controller = new AbortController();  // reset abort controller to continue further requests
            setSignal();
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpPut = (url, data, accountId) => {
    let statusCode;
    return fetch(API_URL + url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        },
        body: JSON.stringify(data),
    })
        .then(response => {
            statusCode = response.status;
            // return response
            // return response.json()

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {

            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'PUT'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });
};

export const httpDelete = async (url, data, accountId) => {

    let statusCode, headers;
    if (isEmpty(accountId)) {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...getAuthorization(),
        }
    } else {
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-account-id': accountId,
            ...getAuthorization(),
        }
    }

    return fetch(API_URL + url, {
        method: 'DELETE',
        headers: headers,

    })
        .then(response => {
            // capture the status code if the response to json conversion call breaks
            statusCode = response.status;

            if (response.status === 204) {
                return response
            } else {
                return response.json()
            }
        })
        .then(data => {
            if (statusCode === 200 || statusCode === 201) {
                if (Array.isArray(data)) {
                    return {data: data, statusCode: statusCode, success: true}
                }
                if (typeof data === 'object') {
                    return {data: data, statusCode: statusCode, success: true}
                }
                return {data: data, statusCode: statusCode, success: true}
            }

            if (statusCode === 204) {
                return {data: null, statusCode: statusCode, success: true}
            }

            if (statusCode >= 400 && statusCode < 500) {
                if (statusCode === 401) {
                    store.dispatch(userLoggedOut())
                } else {
                    if (statusCode === 403) {
                        store.dispatch(setNotAccessible())
                    } else {
                        store.dispatch(showToastMessage('warning', data.__error))
                    }
                }
                return {...data, statusCode: statusCode, success: false}
            }

            if (statusCode >= 500) {
                store.dispatch(showSystemErrorBlock());
                return {data: null, statusCode: statusCode, success: false}
            }
        })
        .catch((error) => {
            logAPIError({statusCode: statusCode, error: error, api: url, payload: data, apiMethod: 'DELETE'});
            store.dispatch(showSystemErrorBlock());
            return {statusCode: statusCode, error: error, success: false}
        });

};

export const getAppEnvironmentVariables = () => {
    return process.env;
};

export const getAppPlatform = () => {
    return 'WEB';
};

export const getAuthorization = () => {
    if (getAuthorizationToken().token) {
        return {
            'Authorization': 'Bearer ' + getAuthorizationToken().token,
        }
    }
    return {}
};

export const logAPIError = (errorData) => {
    // add the api status codes for which error logging is not required
    if (errorData?.statusCode === 409) {
    } else {
        log.error(errorData);
    }

};


export const abortWebFetch = () => {
    controller.abort();
    setSignal();
};

export const setSignal = () => {
    signal = controller.signal;
};

export const contentTypeName = (contentType) => {
    switch (contentType) {
        case 'CUSTOMER':
            return 'All Customers';
        case 'LEAD':
            return 'All Leads';
        case 'PROSPECTS':
            return 'All Prospects';
    }
};

export const contentType = (contentType) => {
    switch (contentType) {
        case 'CUSTOMER':
            return 'Customers';
        case 'LEAD':
            return 'Leads';
        case 'PROSPECTS':
            return 'Prospects';
    }
};

export const getMetaTagUi = (pathname, seoMetaData, isAuthenticated, account) => {
    let pageTitle = getPageTitle(pathname, account);

    let genericMetaTag = (
        <Helmet>
            {
                (process.env.REACT_APP_ENVIRONMENT === 'QA') &&
                <title>{!isEmpty(pageTitle) ? "QA - "+pageTitle : "QA - SCuiDO"}</title>
            }
            {
                (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') &&
                <title>{!isEmpty(pageTitle) ? "DEV - "+pageTitle : "DEV - SCuiDO"}</title>
            }
            {
                (process.env.REACT_APP_ENVIRONMENT === 'PROD') &&
                <title>{!isEmpty(pageTitle) ? pageTitle : "SCuiDO"}</title>
            }
            {/*<meta name="description"*/}
            {/*      content='SCuiDo App'/>*/}
            {/*<meta name="keywords" content="vegan, plant based"/>*/}

        </Helmet>
    );

    if (isAuthenticated) {
        return genericMetaTag;
    }

    if (pathname === '' || pathname === '/' || pathname === '/home') {
        pathname = '/'
    }

    if (isEmpty(seoMetaData)) {

        if (pathname === '/') {
            return genericMetaTag;
        }

        return;

    }
    let data = {};
    if (pathname === '/') {
        data = seoMetaData.find(item => {
            return (item.url === API_URL + pathname || item.url === API_URL)
        });
    } else {
        data = seoMetaData.find(item => {
            return item.url.includes(pathname)
        });
    }

    if (isEmpty(data)) {
        return genericMetaTag
    }

    let prefix = "";

    if (process.env.REACT_APP_ENVIRONMENT === 'QA') {
        prefix = "QA ";
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') {
        prefix = "DEV ";
    }

    let SEOAttributes = data?.SEOAttributes;
    let SEONameAttributes = [];
    let SEOPropertyAttributes = [];

    SEOAttributes.map((obj) => {
        if (obj.SEOMasterType === 'name') {
            SEONameAttributes.push(obj);
        } else if (obj.SEOMasterType === 'property') {
            SEOPropertyAttributes.push(obj);
        }
    })

    return (
        <Helmet>
            <title>{`` + prefix + data?.title}</title>
            {
                SEONameAttributes.map((obj) => {
                    return (
                        <meta name={obj?.SEOMasterKey} content={obj?.value}/>
                    );
                })
            }
            {
                SEOPropertyAttributes.map((obj) => {
                    return (
                        <meta property={obj?.SEOMasterKey} content={obj?.value}/>
                    );
                })

            }
        </Helmet>
    );

};

export const getPageTitle = (pathName, account) => {
    let accountId = account?.id;

    if (pathName === "/accounts/" + accountId + "/dashboard") {
        return "SCuiDO - Home";    //done
    }
    if (/\/accounts\/\d+\/campaigns$/.test(pathName)) {   //  /accounts/"+accountId+"/campaigns
        return "SCuiDO - Campaign Listing";  //done
    }
    if(/\/accounts\/\d+\/campaigns\/\d+\/stats\/summary$/.test(pathName)){
       return  "SCuiDO - Campaign Stats Summary";  //Approval pending
    }
    if(/\/accounts\/\d+\/campaigns\/\d+\/stats\/details\/date\/\d+$/.test(pathName)){ // /accounts/49/campaigns/435/stats/details/date/2023-08-24
        return  "SCuiDO - User Activity";  //Approval pending
    }
    if(/\/accounts\/\d+\/customer-activities$/.test(pathName)){ // /accounts/49/customer-activities
        return  "SCuiDO - User Activity Details";  //Approval pending
    }
    if (/\/accounts\/\d+\/campaigns\/\d+\/template\/select$/.test(pathName)) {  // /accounts/"+accountId+"/campaigns/144/template/select
        return "SCuiDO - Campaign Template Editor";    ///////done
    }
    if (/\/accounts\/\d+\/campaigns\/\d+\/templates$/.test(pathName)) {  // /accounts/"+accountId+"/campaigns/144/templates
        return "SCuiDO - Choose Template"  //done
    }
    if(/\/accounts\/\d+\/campaigns\/\d+\/preview$/.test(pathName)){  // /accounts/21/campaigns/156/preview
        return "SCuiDO - Campaigns Preview"  //Approval Pending
    }
    if (/\/accounts\/\d+\/campaigns\/\d+$/.test(pathName)) {  // /accounts/"+accountId+"/campaigns/144
        return "SCuiDO - Campaign";    //done
    }
    if (/\/accounts\/\d+\/segments$/.test(pathName)) {   // /accounts/"+accountId+"/segments
        return "SCuiDO - Segment Listing";  //done
    }
    if (/\/accounts\/\d+\/segment\/\d+$/.test(pathName)) { // /accounts/"+accountId+"/segment/148
        return "SCuiDO - Segment";     //done
    }
    if (/\/accounts\/\d+\/contents\/\d+\/templates\/\d+$/.test(pathName)) {     ///app/accounts/"+accountId+"/contents/718/templates/836
        return "SCuiDO - Data Setup Draft";    //done
    }
    if(/\/accounts\/\d+\/contents\/\d+\/templates\/\d+\/validate-file$/.test(pathName)){ // /accounts/21/contents/84/templates/1090/validate-file
        return "SCuiDO - Data Setup Validate File";    //Approval pending
    }
    if (/\/accounts\/\d+\/contents\/\d+\/import-history$/.test(pathName)) {       ///accounts/"+accountId+"/contents/718"
        return "SCuiDO - View History";    //done
    }
    if (/\/accounts\/\d+\/contents\/\d+\/upload$/.test(pathName)) {    // /accounts/"+accountId+"/contents/718/upload
        return "SCuiDO - Manual Upload";   //done
    }
    if (/\/accounts\/\d+\/contents\/\d+\/data$/.test(pathName)) {     ///accounts/"+accountId+"/contents/718/data
        return "SCuiDO - Data Setup Data";    //done
    }
    if (/\/accounts\/\d+\/contents$/.test(pathName)) {  // /accounts/"+accountId+"/contents
        return "SCuiDO - Data Setup Listing";    //done
    }
    if (/\/accounts\/\d+\/contents\/\d+$/.test(pathName)) {       ///accounts/"+accountId+"/contents/718"
        return "SCuiDO - Data Setup";    //done
    }
    if (/\/accounts\/\d+\/email-templates\/\d+$/.test(pathName)) {  // /accounts/"+accountId+"/email-templates/713
        return "SCuiDO - Plain Text Editor";    //done
    }
    // if(/\/accounts\/\d+\/email-templates\/\d+$/.test(pathName)){  // /accounts/21/email-templates/153
    //     return "SCuiDO - Plain Text Editor";    // Approval Pending
    // }
    if (/\/accounts\/\d+\/email-templates\/\d+\/preview$/.test(pathName) ||
        /\/accounts\/\d+\/campaigns\/\d+\/template\/preview$/.test(pathName)) {   // /accounts/53/email-templates/428/preview
        return "SCuiDO - Template Preview";     //done
    }
    if (/\/accounts\/\d+\/contents\/\d+\/import-history\//.test(pathName)) {     // /accounts/"+accountId+"/users/103
        return "SCuiDO - Import Logs";      //done
    }
    if(/\/accounts\/\d+\/roles\/\d+$/.test(pathName)){  // /accounts/21/roles/144
        return "SCuiDO - Roles";     // Approval Pending
    }
    if(/\/accounts\/\d+\/users\/\d+$/.test(pathName)){ // /accounts/21/users/115
        return "SCuiDO - Users";     // Approval Pending
    }
    if(pathName === "/accounts/" + accountId + "/virtual-inbox"){
        return "SCuiDO - Virtual Inbox";     // Approval Pending
    }
    if (pathName === "/accounts/" + accountId + "/contents/all/import-history") {
        return "SCuiDO - Import History";     //done
    }
    if (pathName === "/accounts/" + accountId + "/kyc") {
        return "SCuiDO - KYC";       //done
    }
    if(pathName === "/accounts/" + accountId + "/contents/linking"){
        return "SCuiDO - Content Linking";       //Approval Pending
    }
    if (pathName === "/user/change-password") {
        return "SCuiDO - Change Password";      //done
    }
    if (pathName === "/user/contact-us") {
        return "SCuiDO - Contact Us";  //done
    }
    if (pathName === "/user/profile") {     // /accounts/"+accountId+"/users/103
        return "SCuiDO - Profile";      //done
    }
    if(pathName === "/accounts/" + accountId + "/groups/create"){
        return "SCuiDO - Create Group";      // Approval Pending
    }
    if (pathName === "/accounts/" + accountId + "/upgrade") {
        return "SCuiDO - Plans";    //done
    }
    if (pathName === "/accounts/" + accountId + "/email-templates") {
        return "SCuiDO - Templates Listing";   //done
    }
    if (pathName === "/accounts/" + accountId + "/settings/data-sources") {
        return "SCuiDO - Data Sources";  //done
    }
    if (pathName === "/accounts/" + accountId + "/settings/date-time-formats") {
        return "SCuiDO - Date And Time Formats";  //done
    }
    if (pathName === "/accounts/" + accountId + "/settings/test-mode") {
        return "SCuiDO - Test Mode";    //done
    }
    if (pathName === "/accounts/" + accountId + "/settings/push-notifications") {
        return "SCuiDO - Push Notifications";   //done
    }
    if (pathName === "/accounts/" + accountId + "/users-roles-groups") {
        return "SCuiDO - User Management";     //done
    }
    if (pathName === "/accounts/" + accountId + "/overview") {
        return "SCuiDO - Account Overview";    //done
    }
    if (pathName === "/accounts/" + accountId + "/contents/create") {
        return "SCuiDO - Data Setup Create";    //done
    }
    if (pathName === "/accounts/" + accountId + "/billing") {
        return "SCuiDO - Billing And Invoices";   //done
    }
    if (pathName === "/accounts/" + accountId + "/settings/sender-emails") {
        return "SCuiDO - Sender Emails";   //done
    }
    return "";
};
