import React, {Component} from 'react';
import PageNotFoundView from './pageNotFoundView'
class PageNotFound extends Component {
    render() {
        return (
            <PageNotFoundView
                {...this.props}
            />
        );
    }
}

PageNotFound.propTypes = {};

export default PageNotFound;
