import {
    getAppPlatform,
    httpDelete,
    httpGet,
    httpPost,
    httpPut,
    removeAuthorizationToken,
    setAuthorizationToken
} from "../utils/httpHelper";
import store from "./../store";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {GetMyProfileData, profileCallFired, resetLoggedOutState, userLogoutData} from "./userReducer";
import {userLogout2} from "../accountManagement/accountManagementReducer";
import {userLogout} from "../core/reducers/appReducer";
import {userLogoutData3} from "../glacier/glacierReducer";
import {userLogoutData4} from "../campaign/campaignReducer";
import {
  acceptNotification,
  connect,
  disconnect,
  leaveAccountRoom,
  leaveRoom
} from "../utils/socket";
import {clearAccountIdForGAEvent} from "../utils/helper";

export const registerUser = (obj) => {
  return httpPost('/service/provisioning/signup/local/request', obj)
};

export const getRegisteredUserEmail = (id) => {
  return httpGet('/service/provisioning/signup/request?id=' + id);
};

export const sendSignUpRequest = (code, signup_id) => {
  return httpPost('/service/provisioning/signup/local/confirm', {
    code: code,
    requestId: signup_id
  },'',true);
};

export const resendCodeRequest = (requestId) => {
  return httpPost('/service/provisioning/signup/local/resendVerificationCode', {
    requestId: requestId
  });
};

export const userLogin = (data) => {
  return httpPost('/service/provisioning/signIn/local', data,'',true);
};

export const validateEmail = (email) => {
  return httpPost('/service/provisioning/user/local/password/sendResetLink', email, '', true)
};

export const resetPassword = (password, userId, code) => {
  return httpPost('/service/provisioning/user/local/password', {
    password: password,
    userId: userId,
    code: code
  });
};

export const validateResetLink = (id) => {
  return httpPost('/service/provisioning/user/local/password/validateResetLink', {code: id})
};

export const setTokenAfterLogin = async (res, rememberMe) => {
  let authResponse = await setAuthorizationToken(res.token, rememberMe);
  return !!authResponse.status;
};

export const logoutUser = (accountId) => (dispatch) => {
  removeAuthorizationToken();
  const {socketStatus} = store.getState().appState.socket;
  if (socketStatus === 'CONNECTED') {
    leaveRoom();
    leaveAccountRoom(accountId);
    disconnect();
  }
  dispatch(userLogoutData());
  dispatch(userLogout());
  dispatch(userLogout2());
  dispatch(userLogoutData3());
  dispatch(userLogoutData4());
  dispatch(resetLoggedOutState());
  clearAccountIdForGAEvent();
  return true;
};


export const getMyProfile = () => {
  return httpGet('/service/provisioning/user/profile')
    .then(res => {
        store.dispatch(profileCallFired());
        if (res.success){
            store.dispatch(GetMyProfileData(res.data));
            store.dispatch(afterLoginActions(res.data));
        }
        return res;
        // Ashwaghosh: -> commented as after login actions should be called only once
        // dispatch(afterLoginActions());
      }
    ).catch(()=>{
        store.dispatch(showSystemErrorBlock())
      })
};

export const deleteProfile = () => {
  let url = '/service/provisioning/user';

  return httpDelete(url)
    .then((res) => {
      return res;
    })
};

export const changeWebPushMessage = () => {

  // if(obj?.debugMessage){
  //     console.log(obj.debugMessage);
  // }
  //
  // if (obj.openAskPushMessageDialog) {
  //     setTimeout(() => {
  //         dispatch({
  //             type: userTypes.CHANGE_WEB_PUSH_MESSAGE,
  //             payload: obj
  //         })
  //     }, isEmpty(obj?.timeout) ? 4000 : obj.timeout)
  //     return;
  // }
  //
  // dispatch({
  //     type: userTypes.CHANGE_WEB_PUSH_MESSAGE,
  //     payload: obj
  // })
};


export const onChangePassword = (data) => {
  return httpPut('/service/provisioning/user/local/password', data)
    .then(res => {
      return res;
    })
};


export const submitPhoneNumber = (data) => {

    return httpPut('/service/provisioning/user', data)
        .then(res => {
            return res;
        })
};

//  this function should be called only once
export const afterLoginActions = () => () => {

  // commented as : not opening push message for all users
  // if (extras?.rememberMe) {
  //     setPushNotificationConfiguration()
  //         .then((res) => {
  //             updateBadgeCountFromServer();
  //             if (res?.success) {
  //                 console.log('PUSH-NOTIFICATION -> ', res?.message);
  //             } else {
  //                 console.log('PUSH-NOTIFICATION -> ', res?.message);
  //             }
  //         });
  // }
  // STARTS connect the socket
  connect();
  acceptNotification();
  // joinRoom();
  // ENDS connect the socket
};

export const processWebGoogleSignUp = (data, callBackFunc) => dispatch => {
  let obj = {
    socialType: 'GOOGLE',
    payload:data.credential
  };

  httpPost('/service/provisioning/signup/social/google', obj)
    .then(res => {
      if (res.data.token) {
          setTokenAfterLogin(res.data, true)
            .then((isTokenSet) => {
                if (isTokenSet) {
                    store.dispatch(GetMyProfileData(res.data?.user));
                     if (callBackFunc) {
                        callBackFunc();
                    }
                    store.dispatch(afterLoginActions(res.data));
                } else {
                    dispatch(showToastMessage('danger', 'Failed to sign up.'));
                }
            })
      } else {
          dispatch(showToastMessage('danger', res.data.message));
      }
    })
};

export const processWebGoogleSignIn = (data, callBackFunc) => dispatch => {
  let obj = {
      token:data.credential
  };

  let clientType = getAppPlatform();

  let url = '/service/provisioning/signin/social/google/'+clientType;

  httpPost(url, obj)
    .then(res => {
      console.log('after getting response processGoogleLogin', res.data);
      if (res.data?.token) {
          setTokenAfterLogin(res.data, true)
            .then((isTokenSet) => {
                if (isTokenSet) {
                    store.dispatch(GetMyProfileData(res.data?.user));
                     if (callBackFunc) {
                        callBackFunc();
                    }
                    store.dispatch(afterLoginActions(res.data));
                } else {
                    dispatch(showToastMessage('danger', 'Failed to sign in.'));
                }
            })
      } else {
          dispatch(showToastMessage('danger', res.data.message));
      }
    })
};
