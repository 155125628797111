import store from '../../store';
import {

    changeDrawerStatusData,
    changeDrawerWidthData,
    clearPostLoginUrlData,
    setAppLoaderData, setBetaData,
    setCurrentPathNameData, setPostLoginUrlData, setPrevLocationData,
    showSystemErrorBlockData, showToastMessageData,

} from "../reducers/appReducer";
import {httpGet} from "../../utils/httpHelper";

// export const setAppLanguage = async(lng) => {
//     let response = await httpGet('/api/language/displayText/all?language=' + lng,{hideLoader: true});
//
//     if (response && response.success) {
//         let val  = {};
//         response.data.forEach((e)=>{
//             val[e.key] = e.value;
//         });
//         i18n.addResourceBundle(lng, 'translations', val, true, true);
//         // let status = await i18n.changeLanguage(lng);
//         store.dispatch(setActiveLanguage(lng);
//         return true;
//     } else {
//         return false;
//     }
// };

export const showToastMessage = (type,message) => (dispatch) => {
    // type should be one of 'info', 'success', 'warning', 'danger'
    let types=['info', 'success', 'warning', 'danger'];
    let newType = type;
    if(types.includes(type)){
        //
    }
    else {
        newType = 'danger'
    }
    dispatch(
      showToastMessageData({
          type: newType,
          message: message
      })
    )
};


export const setAppLoader = (isVisible) => (dispatch) => {
    dispatch(setAppLoaderData({
        type: "screen",
        isVisible:isVisible
    }))
};


export const showSystemErrorBlock = (errorObject) => dispatch => {
    dispatch(setAppLoader(false));
    dispatch(
      showSystemErrorBlockData(errorObject)
    );
};


export const setPrevLocation = (location) => dispatch => {

    dispatch(
      setPrevLocationData(location)
    )
};

export const setPostLoginUrl = (url, urlProps) => dispatch => {
    dispatch(setPostLoginUrlData({
        postLoginUrl: url,
        postLoginUrlProps: {
            openAsPopUp: true,
            ...urlProps,
        }
    }))
};

export const clearPostLoginUrl = () =>dispatch => {
    dispatch(
      clearPostLoginUrlData()
    )
};


export const changeDrawerWidth2 = (drawerWidth) => {
    changeDrawerWidthData(drawerWidth)
};


export const changeDrawerStatus = (isOpen) => {
    changeDrawerStatusData(isOpen)
};


export const setCurrentPathname = (pathname) => {
    store.dispatch(
      setCurrentPathNameData(pathname)
    )
};

export const setPrevRoutes = (routes) => dispatch => {
    dispatch({
        type: "SET_PREV_ROUTES",
        payload: routes,
    })
};

export const getSeoData = () => {
    let url = '/service/operations/seo/prelogin/all?&skipCount=0&limitCount=999';

    return httpGet(url)
        .then((res) => {
            return res;
        })
};

export const setBetaRequestResponse = (betaData) => {
    store.dispatch(setBetaData(betaData))
};