
import {userConst} from "./userConsts";

import {createSlice} from '@reduxjs/toolkit'


const appSlice = createSlice({
    name: 'appReducer',
    initialState: {
        userAuthStatus: userConst.AUTH_STATUS_UNAUTHORIZED,
        userStatus: '',
        profile: null,
        isProfileLoaded: false,
        isDrawerWidth:false,
        isLoggedOutScreen:false,
        isProfileCallFired:false
    },
    reducers: {
        GetMyProfileData(state, action) {
            state.profile= action.payload;
            state.isProfileLoaded=true;
            state.userAuthStatus=userConst.AUTH_STATUS_AUTHORIZED
        },
        profileCallFired(state, action) {
            state.isProfileCallFired = true
        },
        userLogoutData(state) {
            state.userAuthStatus= userConst.AUTH_STATUS_UNAUTHORIZED;
            state.profile= null;
            state.isLoggedOutScreen = false,
            state.isProfileCallFired = false
        },
        userLoggedOut(state) {
            state.userStatus= userConst.AUTH_STATUS_LOGGED_OUT;
            state.isLoggedOutScreen = true
        },
        setNotAccessible(state) {
            state.userStatus= userConst.ACCESS_DENIED
        },
        SetDrawerWidthData(state, action) {
            state.isDrawerWidth=action.payload
        },
        resetLoggedOutState(state){
            state.userStatus = ''
        }
    }
});

export const {GetMyProfileData,userLogoutData, userLoggedOut, profileCallFired, setNotAccessible, resetLoggedOutState} = appSlice.actions;
export default appSlice.reducer
