import React, {useEffect, useRef, useState} from 'react';
import DLText from "dlcomponent/components/DLText/DLText";
import {isEmpty} from "../../../utils/validations";
import {NavLink} from "react-router-dom";
import DLAccordion from "dlcomponent/components/DLAccordion/DLAccordion";
import {isShowAccountRelatedUI, setSwitchButton} from "../../../accountManagement/accountManagementActions";
import {refreshWindowScrollElement} from "../../../utils/helper";
import useOnClickOutside from "../../../glacier/contentCreate2/UseClickOutside";


function BottomMenuOptionView(props) {

    const [section, setSection] = useState({});
    const [isOtherOptionsOpen, setIsOtherOptionsOpen] = useState(false);
    const {isXSView, account} = props;

    const ref = useRef();

    useOnClickOutside(ref, () => {
        setIsOtherOptionsOpen(false)
    });

    useEffect(() => {
        expand()
    }, []);

    const handleOtherOptions = (otherOptionOpen) => {
        setIsOtherOptionsOpen(otherOptionOpen);
    };

    const expand = (selectedSection) => {
        let isSectionOpen;
        if (section[selectedSection + 'Expanded'] === undefined) {
            isSectionOpen = false;
        } else {
            isSectionOpen = !section[selectedSection + 'Expanded'];
        }

        setSection({
            ...section,
            [selectedSection + 'Expanded']: isSectionOpen
        });
    };

    const checkReadOnlyAccess = (btn) => {
        const {account} = props;
        let memberRole = account?.memberRole;

        if (isEmpty(memberRole)) {
            return false
        }

        switch (memberRole?.name) {
            case 'IT'://campaign, segment, email template, account overview, billing and invoice, subscritpion
                return btn === 'Campaigns' || btn === 'Segments' || btn === 'Templates' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription';
            case 'ANALYST'://campaign ,  setting, email templates, account overview, subscription, billing and invoice
                return btn === 'Campaigns' || btn === 'Templates' || btn === 'DataSetup' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription' || btn === 'Settings';
            case 'DESIGNER': //campaign, segment, data setup, account overview, billing and invoice, setting, subscription
                return btn === 'Campaigns' || btn === 'Segments' || btn === 'DataSetup' ||
                    btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription' || btn === 'Settings';
            case 'MARKETING'://account overview, billing and invoice, subscritpion
                return btn === 'AccountOverview' || btn === 'BillingInvoice' || btn === 'Subscription';
            case 'VIEWER'://account overview, billing and invoice, subscritpion
                return btn === 'BillingInvoice';
            default:
                return false
        }

    };

    const getMenuOptions = () => {
        const {account, refreshWindowScrollElement} = props;
        return [
            {
                label: 'Home',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false)
                },
                route: isEmpty(account?.id) ? '/accounts/dashboard' : '/accounts/' + account?.id + '/dashboard',
                icon: (<i className="fa-sharp fa-solid fa-house-chimney"/>),
            },
            {
                label: 'Segments',
                isDisabled: checkReadOnlyAccess('Segments'),
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                    props.refreshSegmentsData()
                },
                route: '/accounts/' + account?.id + '/segments',
                icon: (<i className={'fas fa-server '}/>),
            },
            {
                label: 'Campaigns',
                isDisabled: checkReadOnlyAccess('Campaigns'),
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                },
                route: '/accounts/' + account?.id + '/campaigns',
                icon: (<i className={'fas fa-bullhorn '}/>),
            },
            {
                label: 'Contents',
                section: 'CustomerData',
                isDisabled: checkReadOnlyAccess('DataSetup'),
                onClick: () => {
                    refreshWindowScrollElement();
                    handleOtherOptions(false);
                },
                icon: (<i className="fa-solid fa-database"/>),
                route: '/accounts/' + account?.id + '/contents',

            },
        ];
    };


    const getOtherOptions = () => {
        const {account} = props;
        return [
            {
                label: 'Account Overview',
                isDisabled: checkReadOnlyAccess('AccountOverview'),
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account.id + '/overview',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'User Management',
                isDisabled: false,
                onClick: () => {
                    setSwitchButton('User');
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account.id + '/users-roles-groups',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Billing & Invoice',
                isDisabled: checkReadOnlyAccess('BillingInvoice'),
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account.id + '/billing',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Support',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/user/contact-us',
                isVisible: isShowAccountRelatedUI(true),
            },
            {
                label: 'Templates',
                isDisabled: checkReadOnlyAccess('Templates'),
                icon: (<i className={'   fas fa-border-all '}/>),
                section: 'Templates',
                subSections: [
                    {
                        label: 'Email Templates',
                        icon: (<div className={'bullet-dot'}
                                    style={{marginRight: '14px'}}/>),
                        route: '/accounts/' + account?.id + '/templates',
                        type: 'EMAIL',
                        onClick: () => {
                            refreshWindowScrollElement();
                        }
                    },
                    {
                        label: 'WhatsApp Templates',
                        icon: (<div className={'bullet-dot'}
                                    style={{marginRight: '14px'}}/>),
                        route: '/accounts/' + account?.id + '/templates',
                        type: 'WHATSAPP',
                        onClick: () => {
                            refreshWindowScrollElement();
                        }
                    },
                ]
            },
            {
                label: 'Settings',
                isDisabled: checkReadOnlyAccess('Settings'),
                section: 'Settings',
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account?.id + '/settings/sender-emails',
                icon: (<i className={'  fas fa-cogs '}/>)
            },

            {
                label: 'Virtual Inbox',
                isDisabled: false,
                onClick: () => {
                    refreshWindowScrollElement();
                },
                route: '/accounts/' + account?.id + '/virtual-inbox',
                isVisible: isShowAccountRelatedUI(true),
            }
        ];
    };

    const renderMobileDrawerBtn = () => {
        return (
            <div className='cursor activatedMenu bottom-menu' onClick={() => handleOtherOptions(!isOtherOptionsOpen)}>
                <div className='d-flex flex-column justify-content-center cursor align-items-center pe-1 mb-1 mt-2'>
                            <span>
                            <i className={'fas fa-bars icon-size'} style={{fontSize: 18}}/>
                            </span>
                    <DLText id={''}
                            text={'More'}
                            marginBottom={'none'}
                            fontSize={'xs'}
                    />
                </div>
            </div>
        );
    };

    const renderMenuItemUi = (item) => {

        return (
            <div className={'px-3 menu-item py-2'}>
                <DLText
                    id={''}
                    type={"normal"}
                    isInline={true}
                    customWrapperStyle={
                        {textAlign: 'center'}
                    }
                    marginBottom={"none"}
                    customTextStyle={{
                        color: !item?.isDisabled ? '#C8C8C8' : '#8B8B8B'
                    }}
                    text={item?.label}
                    icon={isEmpty(item?.icon) ? <></> : <span
                        style={{color: !item?.isDisabled ? 'white' : '#8B8B8B', marginRight: 10}}>{item?.icon}</span>}
                />
            </div>
        );
    };

    const getRoute = (item) => {

        if (item?.isDisabled) {
            return props.currentPathname
        }
        return item?.route
    };

    return (
        <div className={'d-flex justify-content-between  border-top w-100 bg-white'} style={{color: '#333333'}}>
            {
                getMenuOptions().map((item, index) => {
                    return (
                        <NavLink to={getRoute(item)}
                                 exact
                                 key={index}
                                 style={{flex: !isXSView && 1}}
                                 className={item?.isDisabled ? 'disableCursor' : ''}
                                 activeClassName={(isOtherOptionsOpen || item.isDisabled) ? '' : "active-bottom-option-link "}>

                            <div className={'activatedMenu horizontal-line'} onClick={() => {
                                handleOtherOptions(false);
                                if (!item.isDisabled) {
                                    item?.onClick();
                                }
                            }}>
                                <div className={'py-1 bottom-menu-rippleclass'}
                                     style={{
                                         paddingRight: item?.label === 'Home' ? 12 : 10,
                                         paddingLeft: (item?.label === 'Home') ? 12 : 10
                                     }}>
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <span>
                                                <DLText id={''}
                                                        text={item?.icon}
                                                        fontSize={'sm'}
                                                        marginBottom={'none'}
                                                        fontColor={item?.isDisabled ? 'gray' : 'black'}
                                                />
                                            </span>
                                        <DLText id={''}
                                                text={item?.label}
                                                fontSize={'xs'}
                                                marginBottom={'none'}
                                                fontColor={item?.isDisabled ? 'gray' : 'black'}
                                        />
                                    </div>
                                </div>
                            </div>

                        </NavLink>
                    )
                })
            }

            <div style={{flex: !isXSView && 1}}
                 className={isOtherOptionsOpen ? 'flex-1 d-flex justify-content-center align-items-center active-bottom-option-link px-3 bottom-menu' :
                     'flex-1 d-flex justify-content-center align-items-center px-3 bottom-menu'}>
                {renderMobileDrawerBtn()}
            </div>

            {isOtherOptionsOpen &&
            <div className='border-rounded' style={{
                position: 'fixed',
                bottom: 57,
                right: 10,
                width: 200,
                backgroundColor: '#212529',
            }} ref={ref}>

                {getOtherOptions().map((item, index) => {
                    if (isEmpty(item?.subSections) || item.isDisabled) {
                        return (
                            <div key={index}>
                                <NavLink to={{
                                    pathname: getRoute(item),
                                    state: {accountId: account?.id}
                                }}
                                         exact
                                         className={item?.isDisabled ? 'disableCursor' : ''}
                                         activeClassName={item.isDisabled ? '' : "active-bottom-option-link"}>
                                    <div className={''} key={'section-' + index}
                                         onClick={() => {
                                             if (!item.isDisabled) {
                                                 handleOtherOptions(false)
                                             }
                                         }
                                         }>
                                        <div onClick={() => item?.onClick ? item?.onClick() : {}}>
                                            {renderMenuItemUi(item)}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        );
                    }

                    return (
                        <div key={'section-' + index} className="left-panel-submenus">
                            <DLAccordion
                                id={'about-drawer-opt3'}
                                containerPadding={"none"}
                                showIcon={!props.isDrawerWidth}
                                color={'white'}
                                iconSize={'xs'}
                                title={(<>{renderMenuItemUi(item)}</>)}
                                isExpanded={(section[item?.section + 'Expanded'] === true)}
                                headerPadding={'none'}
                                onChange={() => expand(item?.section)}
                                background={'none'}>

                                {item?.subSections?.map((subItem, subindex) => {
                                    return (
                                        <div key={'subSection-' + subindex}
                                             onClick={() => handleOtherOptions(false)}
                                             className=''>

                                            <NavLink to={{
                                                pathname: subItem?.route,
                                                state: subItem?.type
                                            }}
                                                     exact
                                                     activeClassName="active-link">

                                                <div onClick={() => subItem?.onClick ? subItem?.onClick() : {}}>
                                                    {renderMenuItemUi(subItem, true)}
                                                </div>

                                            </NavLink>
                                        </div>

                                    );
                                })}

                            </DLAccordion>
                        </div>
                    )

                })}

            </div>
            }
        </div>
    )
}

BottomMenuOptionView.propTypes = {};

export default BottomMenuOptionView;
