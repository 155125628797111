import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {userConst} from "../../user/userConsts";
import {isEmpty} from "../../utils/validations";

const PreLoginRoute = ({component: Component, userAuthStatus, prevLocation, postLoginUrl, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            let authStatus = (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED);
            let uiContent;
            if (authStatus) {
                if (isEmpty(postLoginUrl)) {
                    uiContent = (<Redirect to={{pathname: '/user/subscriptions'}}/>);
                } else {
                    uiContent = (<Component {...props} prevLocation={prevLocation}/>);
                }
            } else {
                if (props.location.pathname === '/') {
                    uiContent = (<Redirect to={{pathname: '/user/login'}}/>);
                } else {
                    uiContent = (<Component {...props} prevLocation={prevLocation}/>);
                }
            }
            return uiContent;
        }
        }
    />
);

PreLoginRoute.propTypes = {};

const mapStateToProps = state => ({
    userAuthStatus: state.userState.userAuthStatus,
    prevLocation: state.appState.prevLocation,
    postLoginUrl: state.appState.postLoginUrl,
});

export default connect(mapStateToProps)(PreLoginRoute);
