import {createSlice} from "@reduxjs/toolkit";

const allFilter = {
    keyword: ''
};

const appSlice = createSlice({
    name: 'contentReducer',
    initialState: {
        screen: '',
        contentFilter: {
            ...allFilter
        },
    },
    reducers: {
        contentSearchFilter(state, action) {
            state.contentFilter = {
                ...state.contentFilter,
                [action.payload.name]: action.payload.value,
            }
        },
        resetSearchFilter(state){
            state.contentFilter = {
                ...allFilter
            }
        }
    }
});

export const {
    contentSearchFilter,
    resetSearchFilter
} = appSlice.actions;

export default appSlice.reducer