import {httpGet, httpPost} from "../utils/httpHelper";
import store from "../store";
import * as log from "loglevel";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";


export const getAccounts = () => {
    let url = '/service/operations/inbox/account/list';

    return httpGet(url)
        .then(res => {
            return res;

        })
};


export const getTemplateData = (messageId) => {
    let url = '/service/operations/inbox/?messageId='+messageId;


    return httpGet(url)
        .then(res => {
            switch (res?.statusCode) {
                case 200:
                case 204:
                    break;
                case 403:
                    store.dispatch(showToastMessage('danger', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'getTemplateData',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;

        }).catch(err => {
                store.dispatch(showSystemErrorBlock());
            }
        );
};

export const handleSelectedFilter = (name,value) => dispatch => {

    dispatch({
        type: "INBOX_FILTER_CHANGE",
        payload:{
            name:name,
            value:value,
        }
    })
};
export const handleFilterCount = (count) => dispatch => {
    dispatch({
        type: "FILTER_COUNT",
        payload:count
    })
};

export const resetInboxFilter = () => dispatch => {
    dispatch({
        type: "RESET_INBOX_FILTER",
    })
};

export const getMailStatus = (messageId,action) => {
    let url = '/service/operations/inbox/'+action;

    return httpPost(url, messageId).then((res) => {
        switch (res?.statusCode) {
            case 201:
            case 204:
            case 401:
                break;
            case 400:
            case 409:
                store.dispatch(showToastMessage('danger', res?.__error));
                break;
            default:
                log.debug({function: 'createContent', response: res, errorDescription: 'Error caught in default case'});
                store.dispatch(showSystemErrorBlock());
                break;
        }
        return res;
    })
        .catch(err => {
                log.error({function: 'acceptInvite', error: err, errorDescription: 'Error caught in Catch'});
                store.dispatch(showSystemErrorBlock());
            }
        );
};

export const SetFilterMails = (res) => dispatch => {
    dispatch({
            type: "SET_FILTER_EMAILS",
            payload:res
        }
    )
};
export const refreshFilterMails = () => dispatch => {
    dispatch({
            type: "REFRESH_FILTER_EMAILS",
        }
    )
};

