import {lazy} from "react";
const UserRoutes = lazy(() => import('./user/userRoutes'));
const otherRoutes = lazy(() => import('./otherRoutes'));
const PaymentRoutes = lazy(() => import('./accountService/paymentRoutes'));
const AccountManagementRoutes = lazy(() => import('./accountManagement/accountManagementRoutes'));

const modules = [
    {
        routeProps: {
            path: '/user',
            component: UserRoutes,
        },
        name: 'User',
    },
    {
        routeProps: {
            path: '/subscription',
            component: PaymentRoutes,
        },
        name: 'Payment',
    },
    {
        routeProps: {
            path: '/accounts/:accountId',
            component: AccountManagementRoutes,
        },
        name: 'account',
    },
    {
        routeProps: {
            path: '',
            component: otherRoutes,
        },
        name: 'Other',
    }
];

export default modules;