import React from 'react';
import BottomMenuOptionView from "./BottomMenuOptionView";
import {connect} from "react-redux";
import {resetEmailTemplateFilter} from "../../../campaign/campaignAction";
import {setImportHistoryFilter} from "../../../glacier/glacierActions";
import {changeDrawerStatus} from "../../actions/appActions";
import {refreshSegmentsData} from "../../../campaign/campaignReducer";
import {withRouter} from "react-router-dom";
import {refreshWindowScrollElement} from "../../../utils/helper";
import {userConst} from "../../../user/userConsts";
import {isEmpty} from "../../../utils/validations";


function BottomMenuOption(props) {

    const {account, isLoggedOutScreen, userAuthStatus} = props;

    if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED || isEmpty(account) || isLoggedOutScreen) {
        return;
    }

    return (
        <BottomMenuOptionView {...props} refreshWindowScrollElement={refreshWindowScrollElement}/>
    );
}

BottomMenuOption.propTypes = {

};

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    currentPathname: state.appState.currentPathname,
    userAuthStatus: state.userState.userAuthStatus
});

export default connect(mapStateToProps, {
    resetEmailTemplateFilter,
    setImportHistoryFilter,
    changeDrawerStatus,
    refreshSegmentsData,
    refreshWindowScrollElement
})(withRouter(BottomMenuOption));