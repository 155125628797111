
export const accountContentKeys = [
    {
        "key": "ACCOUNT_LABEL_CANCEL",
        "areaOfUsage": "Account",//needs to decide
        "value": {
            en: 'Cancel',
            hi: ' रद्द करें '
        }
    },
    {
        "key": "ACCOUNT_LABEL_CREATE",
        "areaOfUsage": "Account",//needs to decide
        "value": {
            en: 'Create',
            hi: ' निर्माण करें '
        }
    },
    {
        "key": "ACCOUNT_LABEL_ACCOUNT_NAME",
        "areaOfUsage": "Account",//needs to decide
        "value": {
            en: 'Account Name',
            hi: ' खाते का नाम '
        }
    },
    {
        "key": "ACCOUNT_LABEL_DESCRIPTION",
        "areaOfUsage": "Account",//needs to decide
        "value": {
            en: 'Description',
            hi: ' वर्णन '
        }
    },
    {
        "key": "ACCOUNT_LABEL_TEAM_SIZE",
        "areaOfUsage": "Account",//needs to decide
        "value": {
            en: 'Team Size',
            hi: ' संख्या '
        }
    },
    {
        "key": "ACCOUNT_LABEL_ACCOUNT_NAME_ERROR",
        "areaOfUsage": "Account",
        "value": {
            en: 'Account name should not be empty',
            hi: ' खाते का नाम खाली नहीं होना चाहिए '
        }
    },
    {
        "key": "ACCOUNT_LABEL_ERROR_LIMIT_50",
        "areaOfUsage": "User",
        "value": {
            en: "Account name should be within 50 characters",
            hi: "कखाता विवरण 50 वर्णों के भीतर होना चाहिए"
        }
    },
    {
        "key": "ACCOUNT_LABEL_ERROR_LIMIT_500",
        "areaOfUsage": "Account",
        "value": {
            en: 'Please enter upto 500 characters',
            hi: 'खाता विवरण 500 वर्णों के भीतर होना चाहिए'
        }
    },

    {
        "key": "ACCOUNT_ROLE_LABEL_ROLES",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Roles',
            hi: 'भूमिका'
        }
    },
    {
        "key": "ACCOUNT_CREATE_ROLE_LABEL",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Create a role',
            hi: 'भूमिका तयार करा'
        }
    },
    {
        "key": "ACCOUNT_ROLE_LABEL_ROLE_TITLE",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Role title',
            hi: 'भूमिकेचे शीर्षक'
        }
    },
    {
        "key": "ACCOUNT_ROLE_LABEL_USERS",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Users',
            hi: 'वापरकर्ते'
        }
    },
    {
        "key": "ACCOUNT_ROLE_LABEL_USERS_GROUPS",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Groups',
            hi: 'गट'
        }
    },
    {
        "key": "ACCOUNT_ROLE_LABEL_ACTION",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Actions',
            hi: 'क्रिया'
        }
    },
    {
        "key": "ROLE_LABEL_ROLE_NAME_ERROR",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Role title should not be empty',
            hi: 'भूमिका का नाम खाली नहीं होना चाहिए '
        }
    },
    {
        "key": "ROLE_LABEL_ROLE_NAME_LIMIT_ERROR",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Please enter upto 30 characters',
            hi: 'कमाल 30 वर्णांना अनुमती आहे'
        }
    },
    {
        "key": "ROLE_LABEL_ERROR_LIMIT_500",
        "areaOfUsage": "AccountRole",
        "value": {
            en: 'Please enter upto 500 characters',
            hi: 'कमाल ५०० वर्णांना अनुमती आहे'
        }
    },

    {
        "key": "LABEL-CREATE-GROUP",
        "areaOfUsage": "Group",
        "value": {
            en: 'Create a group',
            hi: 'एक गट बनाएं'
        }
    },
    {
        "key": "LABEL-GROUP-TITLE",
        "areaOfUsage": "Group",
        "value": {
            en: 'Group title',
            hi: 'गट का नाम'
        }
    },
    {
        "key": "LABEL-DESCRIPTION",
        "areaOfUsage": "Group",
        "value": {
            en: 'Description',
            hi: ' विवरण '
        }
    },
    {
        "key": "LABEL-CREATE",
        "areaOfUsage": "Group",
        "value": {
            en: 'Create',
            hi: ' निर्माण करें '
        }
    },
    {
        "key": "LABEL-CANCEL",
        "areaOfUsage": "Group",
        "value": {
            en: 'Cancel',
            hi: ' रद्द करें '
        }
    },
    {
        "key": "LABEL-GROUP-TITLE-ERROR-EMPTY",
        "areaOfUsage": "Group",
        "value": {
            en: 'Enter group title',
            hi: ' गट का नाम खाली नहीं होना चाहिए '
        }
    },
    // {
    //     "key": "LABEL-GROUP-TITLE-ERROR-MIN-LIMIT-5",
    //     "areaOfUsage": "Group",
    //     "value": {
    //         en: "Please enter at least 5 characters",
    //         hi: "गट का नाम 30 वर्णों के भीतर होना चाहिए"
    //     }
    // },
    {
        "key": "LABEL-GROUP-TITLE-ERROR-MAX-LIMIT-30",
        "areaOfUsage": "Group",
        "value": {
            en: "Please enter upto 30 characters",
            // en: "Group title should be within 30 characters",
            hi: "गट का नाम 30 वर्णों के भीतर होना चाहिए"
        }
    },
    {
        "key": "LABEL-GROUP-DESCRIPTION-ERROR-MAX-LIMIT-500",
        "areaOfUsage": "Group",
        "value": {
            en: 'Please enter upto 500 characters',
            // en: 'Group description should be within 500 characters',
            hi: 'गट विवरण 500 वर्णों के भीतर होना चाहिए'
        }
    },
    {
        "key": "USER-SEARCH-NO-OPT-MESSAGE",
        "areaOfUsage": "Account",
        "value": {
            en: 'No option found',
            hi: 'कोई विकल्प नहीं मिला'
        }
    },
    {
        "key": "LABEL-SCUIDO-BASIC",
        "areaOfUsage": "Account",
        "value": {
            en: 'SCuiDO - Basic',
            hi: ''
        }
    },
    {
        "key": "LABEL-SCUIDO-PRO",
        "areaOfUsage": "Account",
        "value": {
            en: 'SCuiDO - Pro',
            hi: ''
        }
    },
    {
        "key": "LABEL-ACTIVE-STATUS",
        "areaOfUsage": "Account",
        "value": {
            en: 'Active',
            hi: ''
        }
    },
    {
        "key": "LABEL-ACTIVE",
        "areaOfUsage": "Account",
        "value": {
            en: 'Active',
            hi: ''
        }
    },
    {
        "key": "LABEL-INACTIVE",
        "areaOfUsage": "Account",
        "value": {
            en: 'Expired',
            hi: ''
        }
    },
    {
        "key": "LABEL-BASIC",
        "areaOfUsage": "Account",
        "value": {
            en: 'Basic',
            hi: ''
        }
    },
    {
        "key": "LABEL-PRO",
        "areaOfUsage": "Account",
        "value": {
            en: 'Pro',
            hi: ''
        }
    },
    {
        "key": "LABEL-ENTERPRISE",
        "areaOfUsage": "Account",
        "value": {
            en: 'Enterprise',
            hi: ''
        }
    },
    {
        "key": "LABEL-BUSINESS",
        "areaOfUsage": "Account",
        "value": {
            en: 'Business',
            hi: ''
        }
    },
    {
        "key": "LABEL-SCUIDO-BUSINESS",
        "areaOfUsage": "Account",
        "value": {
            en: 'SCuiDO - Business',
            hi: ''
        }
    },
    {
        "key": "LABEL-SCUIDO-ENTERPRISE",
        "areaOfUsage": "Account",
        "value": {
            en: 'SCuiDO - Enterprise',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-ADMIN",
        "areaOfUsage": "Member",
        "value": {
            en: 'Admin',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-ANALYST",
        "areaOfUsage": "Member",
        "value": {
            en: 'Analyst',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-DESIGNER",
        "areaOfUsage": "Member",
        "value": {
            en: 'Designer',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-IT",
        "areaOfUsage": "Member",
        "value": {
            en: 'IT',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-MARKETING",
        "areaOfUsage": "Member",
        "value": {
            en: 'Marketing',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-OWNER",
        "areaOfUsage": "Member",
        "value": {
            en: 'Owner',
            hi: ''
        }
    },
    {
        "key": "LABEL-ROLE-VIEWER",
        "areaOfUsage": "Member",
        "value": {
            en: 'Viewer',
            hi: ''
        }
    },
    {
        "key": "LABEL-KYC-PENDING-MESSAGE",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'KYC for this account is incomplete. To complete KYC, Contact account owner ',
            hi: ''
        }
    },
    {
        "key": "LABEL-KYC-PENDING-MESSAGE-FOR-OWNER",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Your KYC is incomplete. To complete your KYC ',
            hi: ''
        }
    },
    {
        "key": "LABEL-DISABLE-UNAVAILABLE",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'This option is not available. There are no customers who have no interaction/connection with ',
            hi: ''
        }
    },
    {
        "key": "LABEL-ENABLE-UNAVAILABLE",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Opt for the “unavailable” option to include customers who have no interaction/connection with ',
            hi: ''
        }
    },
    {
        "key": "LABEL-SELECTED-UNAVAILABLE",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Take out the “unavailable” option to exclude customers who have no interaction with ',
            hi: ''
        }
    },
    {
        "key": "LABEL-INVERT",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Flip your selection: switch all choices. Everything selected becomes unselected, and vice versa.',
            hi: ''
        }
    },
    {
        "key": "LABEL-INVERT-DISABLED",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Flip option is not available.',
            hi: ''
        }
    },
    {
        "key": "LABEL-ENABLE-RESET",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Reset your current selection and zoom level.',
            hi: ''
        }
    },
    {
        "key": "LABEL-DISABLE-RESET",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'Reset option is not available – you’re already at square one.',
            hi: ''
        }
    },
    {
        "key": "LABEL-MONTH",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'month',
            hi: ''
        }
    },
    {
        "key": "LABEL-ANNUAL",
        "areaOfUsage": "ACCOUNT PAGES",
        "value": {
            en: 'year',
            hi: ''
        }
    }

];

