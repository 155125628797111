import {createSlice} from "@reduxjs/toolkit";


let allFilter = {
    activeStoredTab: 'email'
};

const appSlice = createSlice({
    name: 'templateReducer',
    initialState: {
        templateFilter: {
            ...allFilter
        }
    },
    reducers: {
        setCurrentTabData(state, action) {
            console.log("state",state,'action',action)
            state.templateFilter ={
                ...state.templateFilter,
                activeStoredTab: action.payload
            }
        }
    }
});

export const {
    setCurrentTabData
} = appSlice.actions;
export default appSlice.reducer
