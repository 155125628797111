import React, {useEffect, useState} from 'react';
import InboxDetailView from "./InboxDetailView";
import {getAppPlatform} from "../../utils/httpHelper";
import {getMailStatus, getTemplateData,} from "../InboxActions";
import {isEmpty} from "../../utils/validations";
import moment from "moment";
import {InboxConstant} from "../InboxConstant";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PageNotFound from "../../core/components/PageNotFound/pageNotFound";

const InboxDetail = (props) => {

    const [inboxDetailListData, setInboxDetailListData] = useState({
        templateData:[],
        UIState: "",
        pageUrls:[],
        action:{
            markAsOpen:"markAsOpen",
            markAsClick:"markAsClick"
        },
    });

    useEffect(() => {
        let messageId = getAccountId();
        getTemplate(messageId);
    }, []);

    useEffect(() => {
    }, [inboxDetailListData.UIState]);

    const  getUrl=(text)=> {
        if(isEmpty(text))
        {
            return
        }
        let urlRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
        // let urlRegex=/(((https?:\/\/)|(www\.))[^\s]+)/g;
        let arr=[];
        text.replace(urlRegex, function(url) {
            arr.push(url) ;

        });
        setInboxDetailListData(() => ({
            pageUrls:arr
        }));

    };

    const getAccountId = () => {
        let id;
        if (getAppPlatform() === "WEB") {
            id = props.match?.params?.messageId
        }
        return id


    };

    const  getTemplate=(messageId)=> {

        getTemplateData(messageId).then((res) => {
            if (res?.statusCode === 200 || res?.statusCode === 204) {
                getReadStatus(inboxDetailListData.action.markAsOpen).then(() => {});
                setInboxDetailListData(() => ({
                    templateData: res.data,
                    UIState:InboxConstant.TEMPLATE_LOADED,
                }));
                return
            }
            setInboxDetailListData(inboxList => ({
                ...inboxList,
                UIState: InboxConstant.CONTENT_UNAVAILABLE
            }));
        })
    };

    const  dateFormat=(date)=>{
        if(isEmpty(date))
        {
            return
        }
        return moment(date).format('DD-MMM-YYYY LT');
    };

    const getReadStatus= async (action)=>{
        let id={
            messageId:getAccountId()
        };
        await getMailStatus(id,action)
            .then((res) => {
                if (res?.statusCode === 200 || res?.statusCode === 204) {
                    setInboxDetailListData(inboxList => ({
                        ...inboxList,
                        UIState:InboxConstant.TEMPLATE_LOADED,
                    }));
                }

            })

    };

    if (isEmpty(inboxDetailListData.UIState)) {
        return <DLLoader type={'screen'} isVisible={true}/>
    }

    if (inboxDetailListData.UIState ===  InboxConstant.CONTENT_UNAVAILABLE) {
        return <PageNotFound {...props} />
    }


    return(
        <div className={'w-100'}>
            <InboxDetailView {...props}
                             {...inboxDetailListData}
                             getUrl={getUrl}
                             dateFormat={dateFormat}
                             getAccountId={getAccountId}
                             getReadStatus={getReadStatus}/>
        </div>
    );
};

InboxDetail.propTypes = {};

const mapStateToProps = (state) => ({
    account: state.inboxState.inboxFilter,
    isXSView: state.appState.deviceInfo.isXSView,
    FilterCount:state.inboxState.FilterCount
});


export default connect(mapStateToProps, {

})(withTranslation('translations')(InboxDetail));
