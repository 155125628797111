import React, {useEffect, useState} from 'react';
import {
    createBetaAccount,
    getBetaRequestData,
} from "../../accountManagement/accountManagementActions";
import store from "../../store";
import {updateAccounts} from "../../accountManagement/accountManagementReducer";
import BetaRequestApprovedView from "./BetaRequestApprovedView";
import {isEmpty} from "../../utils/validations";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

function BetaRequestApproved(props) {
    const [betaRequestData, setBetaRequestData] = useState({
        betaRequest: false,
        betaPageUI: '',
        betaResponseId: '',
        betaResponse:{}
    });

    useEffect(() => {
        getBetaRequestData(true).then((res) => {
            checkBetaRequestStatus(res.data);
            setBetaRequestData(subscriptionsState => ({
                ...subscriptionsState,
                betaResponse: res
            }));
        });
    }, []);


    const checkBetaRequestStatus = (betaResponse) => {
        setBetaRequestData(subscriptionsState => ({
            ...subscriptionsState,
            betaRequest: betaResponse
        }));

        if (isEmpty(betaResponse?.BetaResponse)) {                  //BetaResponse = null -pending
            setBetaRequestData((previousState) => ({
                ...previousState,
                UIState: 'pending',
                betaResponseId: betaResponse?.id
            }));
        } else if (betaResponse.BetaResponse.status === "DENIED") { //betaResponse denied
            setBetaRequestData((previousState) => ({
                ...previousState,
                UIState: 'declined',
                betaResponseId: betaResponse?.id
            }));
        } else if ((betaResponse.BetaResponse.status === "APPROVED") && isEmpty(betaResponse?.BetaResponse?.BetaAccount)) {
            setBetaRequestData((previousState) => ({
                ...previousState,
                UIState: 'approved',
                betaResponseId: betaResponse?.id
            }));
        }

    };

    const getBetaResponseId = () =>{
        return props.location.state?.betaResponseId;
    };

    const onStartUsing = () =>{

        let betaResponseId = getBetaResponseId();
        createBetaAccount(betaResponseId).then((res)=>{
            if (res.success){
                store.dispatch(updateAccounts());
                props.history.replace('/accounts/'+res.data?.accountId+'/dashboard')
            }
        });
    };

    return (
        <BetaRequestApprovedView {...props}
                                 {...betaRequestData}
                             onStartUsing={onStartUsing}/>
    );
}

BetaRequestApproved.propTypes = {

};

const mapStateToProps = (state) => ({
        account: state.accountManagementState.account,
        accountLoaded: state.accountManagementState.accountLoaded,
        isAccountAdmin: state.accountManagementState.isAccountAdmin,
        profile: state.userState.profile,
        isYearlyPlan: state.accountManagementState.isYearlyPlan,
        userAuthStatus: state.userState.userAuthStatus,
        isLGView: state.appState.deviceInfo.isLGView,
        isSMView: state.appState.deviceInfo.isSMView,
        isXSView: state.appState.deviceInfo.isXSView,
        isMobileView: state.appState.deviceInfo.isMobileView,
        betaResponse: state.appState.betaResponse,
        isBetaRequestLoaded: state.appState.isBetaRequestLoaded,
    }
);

export default connect(mapStateToProps, {

})(withTranslation('translations')(BetaRequestApproved));