import {
    getAppEnvironmentVariables, getAppPlatform,
    getAuthorizationToken,
    httpDelete,
    httpGet,
    httpPost,
    httpPut
} from "../utils/httpHelper";
import store from "../store";
import * as log from "loglevel";
import {showSystemErrorBlock, showToastMessage} from "../core/actions/appActions";
import {isEmpty} from "../utils/validations";
import {
    createUniqueCombinationData,
    getAllRegionData,
    setFilter,
    setImportHistoryFilterUrlData
} from "./glacierReducer";
import {numberWithCommas} from "../utils/helper";

export async function validateDataSetupFile(files, accountId) {
    const {REACT_APP_API_URL} = getAppEnvironmentVariables();
    let url = REACT_APP_API_URL + "/service/customerData/templateInference/validateFile";

    let auth = await getAuthorizationToken();

    if (files) {
        if (getAppPlatform() === 'WEB') {
            let newFile = files[0];
            let formData = new FormData();
            formData.append('file', newFile);
            let statusCode;
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                    'x-account-id': accountId,
                },
                body: formData
            })
                .then(response => {
                    statusCode = response.status;
                    return response.json()
                })
                .then(data => {
                    switch (statusCode) {
                        case 200:
                            break;
                        case 403:
                        case 401:
                        case 400:
                           break;
                        default:
                            log.debug({
                                function: 'ChangePassword',
                                response: data,
                                errorDescription: 'Error caught in default case'
                            });
                    }
                    return data.__error;
                })
                .catch(err => console.error("ReadableStream Error is", err));
        }
    }

}


export const getAddTestMode = (accountId,data) => {
       return httpPut('/service/provisioning/account/emailMode' ,data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 204:
                    break;
                case 409:
                case 403:
                case 401:
                case 400:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'editTemplate',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'editTemplate',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const getAllContentsData = (accountId, handleErrorInComponent) => {
    let url = '/service/customerData/content/list';

    return httpGet(url, accountId, handleErrorInComponent)
        .then(res => {
            return res
        })
};

export const getPlanDetails = (accountId, handleErrorInComponent, handleSystemErrorInComponent) => {
    let url = '/service/provisioning/account/dashboard/plans';

    return httpGet(url, accountId, handleErrorInComponent, handleSystemErrorInComponent)
        .then(res => {
            return res;
        })
};

export const createContent = (data, accountId) => {
    let url = '/service/customerData/content';

    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getTemplateById = (accountId, templateId) => {

    let url = '/service/customerData/template?id=' + templateId;

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};


export const reInferStructure = (data, accountId) => {

    let url = '/service/customerData/template/reInference';

    return httpPost(url, data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 400:
                    store.dispatch(showToastMessage('danger', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'reInferStructure',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }
            return res;
        })
        .catch(err => {
                log.error({function: 'reInferStructure', error: err, errorDescription: 'Error caught in Catch'});
                store.dispatch(showSystemErrorBlock());
            }
        );
};


export const editTemplate = (accountId, templateId, data) => {
    return httpPut('/service/customerData/template?id=' + templateId, data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 409:
                case 403:
                case 401:
                case 400:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'editTemplate',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'editTemplate',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const editCSVInstruction = (accountId, data) => {
    // Status Code: 401 - UnAuthorized
    //
    // Status Code: 403 - Forbidden.
    //
    //Status Code: 400 - Bad Request - Validation Errors
    return httpPut('/service/customerData/templateCSVInstruction', data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                case 403:
                case 401:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'editCSVInstruction',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'editCSVInstruction',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const getContentById = (contentId, accountId) => {
    let url = '/service/customerData/content?id=' + contentId;

    return httpGet(url, accountId)
        .then(res => {
            return res;

        })
};

export const editContent = (data, accountId) => {
    return httpPut('/service/customerData/content', data, accountId)
        .then(res => {
            return res;
        })
        .catch(err => {
            log.debug({
                function: 'editContent',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const getAllTemplates = (contentId, accountId) => {

    // Status Code: 200 - OK (success)
    // Status Code: 204 - No Content (success) (empty templates)
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - BAD request → validation error

    let url = '/service/customerData/template/all?contentId=' + contentId;

    return httpGet(url, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 200:
                case 204:
                    break;
                case 401:
                case 400:
                    store.dispatch(showToastMessage('danger', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'getAllTemplates',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    // store.dispatch(showSystemErrorBlock());
            }

            return res;

        })
};

export const createTemplate = (data, accountId) => {

    // Status Code: 201 - Created (success)
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - Bad Request - Validation Errors
    // Status Code: 409 - Conflict when another content with same name exists in same account

    let url = '/service/customerData/template';

    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const discardTemplate = (templateObj, accountId) => {

    // Status Code: 204 - NO-CONTENT (success)
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - Bad Request - Validation Errors

    let url = '/service/customerData/template?id=' + templateObj?.id;
    return httpDelete(url, '', accountId)
        .then(res => {
            return res;
        })
};

export const removeContent = (contentId, accountId) => {
    let url = '/service/customerData/content?id=' + contentId;
    return httpDelete(url, '', accountId)
        .then(res => {
            return res;
        })
};

export const abortContent = (contentProcessId, accountId) => {
    let url = '/service/customerData/content/abort?contentProcessId=' + contentProcessId;
    return httpDelete(url, '', accountId)
        .then(res => {
            return res;
        })
};

export const clearContentData = (contentId, accountId) => {

//api is pending for clear data
    let url = '/service/customerData/content/truncateData?id=' + contentId;
    return httpDelete(url, '', accountId)
        .then(res => {
            return res;
        })
        .catch(err => {
            log.debug({
                function: 'clearContentData',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const addTemplateItem = (accountId, data) => {
    // Status Code: 401 - UnAuthorized
    //
    // Status Code: 403 - Forbidden.
    //
    // Status Code: 400 - Bad Request - Validation Errors
    //
    // Status Code: 409 - Conflict when another content with same name exists in same account
    return httpPost('/service/customerData/templateItem', data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 201:
                    break;
                case 400:
                case 401:
                case 403:
                case 409:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'addTemplateItem',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'addTemplateItem',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const insertItem = (accountId, data) => {
    return httpPost('/service/customerData/templateItem/insertAt', data, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 403:
                case 400:
                case 409:
                case 401:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'addTemplateItem',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'addTemplateItem',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const editTemplateItem = (accountId, data) => {
    // Status Code: 401 - UnAuthorized
    //
    // Status Code: 403 - Forbidden.
    //
    //   Status Code: 400 - Bad Request - Validation Errors
    //
    // Status Code: 409 - Conflict when another with same name exists in same account
    return httpPut('/service/customerData/templateItem', data, accountId)
        .then(res => {
            return res;
        })
};

export const deleteTemplateItem = (templateItemId, accountId) => {
    let url = '/service/customerData/templateItem?id=' + templateItemId;

    return httpDelete(url, '', accountId)
        .then(res => {
            return res
        })
};

export const createFtpSource = (data, accountId) => {
    let url = '/service/customerData/source/ftp';
    return httpPost(url, data, accountId, true)
        .then((res) => {
            switch (res?.statusCode) {
                case 201:
                    break;
                case 401:
                case 403:
                case 400:
                case 409:
                    break;
                default:
            }
            return res;
        })
        .catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        });
};

export const testFtpSource = (data, accountId) => {
    let url = '/service/customerData/source/ftp/testConnection';
    return httpPost(url, data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 500:
                case 504:
                case 530:
                case 550:
                    break;
                default:
                    break;
            }
            return res;
        })
        .catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        });
};

export const getSourceById = (id, accountId) => {
    let url = '/service/customerData/source/ftp?sourceId=' + id;
    return httpGet(url, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 401:
                    store.dispatch(showToastMessage('warning', res.__error));
                    break;
                case 403:
                    store.dispatch(showToastMessage('warning', res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const updateFtpSource = (data, accountId) => {
    let url = '/service/customerData/source/ftp';
    return httpPut(url, data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                case 401:
                case 403:
                    break;
                default:
                    break;
            }
            return res;
        })
        .catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const getAllSources = (accountId) => {
    return httpGet('/service/customerData/source/all', accountId)
        .then((res) => {
            return res;
        })
};

export const deleteFtpSource = (id, accountId) => {
    let url = '/service/customerData/source/ftp?sourceId=' + id;
    return httpDelete(url, '', accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                    store.dispatch(showToastMessage('danger', res.__error));
                    break;
                case 401:
                    store.dispatch(showToastMessage('warning', res.__error));
                    break;
                case 403:
                    store.dispatch(showToastMessage('warning', res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const activateTemplate = (templateObj, accountId, handleErrorInComponent) => {

    let url = '/service/customerData/template/activate?id=' + templateObj?.id;

    return httpPost(url, null, accountId, handleErrorInComponent).then((res) => {
        return res;
    })
};

export const getCampaignProfile = (contentId, accountId) => {
    let url = '/service/customerData/mapping/autoPopulate';

    return httpPost(url, contentId, accountId)
        .then((res) => {
        return res;
    })
};

export const createAwsSource = (data, accountId) => {
    let url = '/service/customerData/source/s3';
    return httpPost(url, data, accountId, true)
        .then((res) => {
            switch (res?.statusCode) {
                case 201:
                    break;
                case 400:
                case 401:
                case 403:
                case 409:
                    break;
                default:
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const updateAwsSource = (data, accountId) => {
    let url = '/service/customerData/source/s3';
    return httpPut(url, data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                case 401:
                case 403:
                    break;
                default:
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const getS3SourceById = (sourceId, accountId) => {
    let url = '/service/customerData/source/s3?sourceId=' + sourceId;
    return httpGet(url, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 400:
                    store.dispatch(showToastMessage("warning", res.__error));
                    break;
                case 401:
                    store.dispatch(showToastMessage("warning", res.__error));
                    break;
                case 403:
                    store.dispatch(showToastMessage("warning", res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const deleteS3Source = (id, accountId) => {
    let url = '/service/customerData/source/s3?sourceId=' + id;
    return httpDelete(url, "", accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 204:
                    break;
                case 400:
                    store.dispatch(showToastMessage("danger", res.__error));
                    break;
                case 401:
                    store.dispatch(showToastMessage("warning", res.__error));
                    break;
                case 403:
                    store.dispatch(showToastMessage("warning", res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const editTemplateToDraft = (templateId, accountId) => {
    let url = '/service/customerData/template/draft?id=' + templateId;


    return httpPost(url, null, accountId).then((res) => {
        return res;
    })
};

export const testAwsSource = (data, accountId) => {
    let url = "/service/customerData/source/s3/testConnection";
    return httpPost(url, data, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 403:
                    break;
                default:
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const getContentSource = (contentId, accountId) => {
    let url = '/service/customerData/contentSource?contentId=' + contentId;

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getContentImportSummaryById = (runId, contentId, accountId) => {

    let url = '/service/customerData/contentImport?runId=' + runId + '&contentId=' + contentId;

    // 400 : Validation Error
    // 403 :Forbidden
    // 200 Ok  :

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const getS3Regions = () => {
    let url = '/service/customerData/source/s3/regions';
    return httpGet(url)
        .then((res) => {
            if (res.statusCode === 200){
                store.dispatch(
                    getAllRegionData(res.data?.regions));

            }
            return res;
        })
};

export const updateContentSource = (contentId, data, accountId) => {
    let url = '/service/customerData/contentSource';

    // Status Code : 204 No Content
    // Errors:
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - Bad Request - Validation Errors

    return httpPut(url, data, accountId)
        .then((res) => {
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const getContentSourceSchedule = (contentId, accountId) => {
    let url = '/service/customerData/contentSchedule?contentId=' + contentId;

    // Status Code : 204 No Content
    // Errors:
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - Bad Request - Validation Errors

    return httpGet(url, accountId)
        .then((res) => {
            return res;
        })
};

export const updateContentSourceSchedule = (data, accountId) => {
    let url = '/service/customerData/contentSchedule';

    // Status Code : 204 No Content
    // Errors:
    // Status Code: 401 - UnAuthorized
    // Status Code: 403 - Forbidden.
    // Status Code: 400 - Bad Request - Validation Errors

    return httpPut(url, data, accountId)
        .then((res) => {
            return res;
        })
};

export const deleteContentSourceSchedule = (contentId, accountId) => {

    let url = '/service/customerData/contentSchedule?contentId=' + contentId;

    return httpDelete(url, "", accountId)
        .then((res) => {
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err));
        })
};

export const getHistory = (contentId, accountId) => {
    // Status Code: 401 - UnAuthorized
    //
    // Status Code: 403 - Forbidden.
    //
    //   Status Code: 400 - Bad Request - Validation Errors

    let url = '/service/customerData/contentImport';
    if (contentId === 'all') {
        url = url + '/all'
    } else {
        url = url + '/all?contentId=' + contentId
    }

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const isSampleDataExists = (contentId, accountId) => {
    let url = '/service/customerData/template/dataExists?contentId=' + contentId;

    return httpGet(url, accountId)
        .then(res => {
            return res;

        })
};

export const validateExistingFileData = (modalDefinitionId, dataObj, accountId) => {

    let obj = {
        ...dataObj,
        modelDefinitionId: modalDefinitionId,
        useExistingFile: true
    };

    // 400 -Validation Error
    // 200 - OK
    let url = "/service/customerData/template/verify?modelDefinitionId=" + modalDefinitionId + "&useExistingFile=true";
    return httpPost(url, obj, accountId)
        .then((res) => {
            return res;
        })
};

export const createUniqueCombination = (template) => dispatch => {
    dispatch(createUniqueCombinationData(template))
};

export const getSampleData = (contentId, accountId) => {
    // Status Code: 401 - UnAuthorized
    //
    // Status Code: 403 - Forbidden.
    //
    //   Status Code: 400 - Bad Request - Validation Errors
    let url = '/service/customerData/template/data?contentId=' + contentId;

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const getAllTemplateConstraint = (templateId, accountId) => {

    // 400 : Validation Error
    // 204 : No Content
    // 200 : OK

    let url = '/service/customerData/templateConstraint/all?modelDefinitionId=' + templateId;

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const createTemplateConstraint = (dataObj, accountId) => {

    // 400 : Validation Error
    // 204 : No Content
    // 201 : Created

    let url = "/service/customerData/templateConstraint";
    return httpPost(url, dataObj, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                case 201:
                case 409:
                    break;
                case 400:
                    // store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    // store.dispatch(showToastMessage('warning',res.__error));
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        })
};

export const deleteTemplateConstraint = (constraintId, accountId) => {

    // 400 : Validation Error
    // 200 : OK

    let url = "/service/customerData/templateConstraint?id=" + constraintId;
    return httpDelete(url, '', accountId)
        .then((res) => {
            return res;
        })
};

export const checkForSideMenuAvailableOrNot = (location) =>{
    return !(location.pathname.includes('quickstart')
        || location.pathname === '/subscription/plans'
        || location.pathname === '/subscription/payment'
        || location.pathname === '/subscription/payment/progress');
};

export const updateContentSourceScheduleStatus = (data, status, accountId) => {

    let url = "/service/customerData/contentSchedule";

    if (status === 'ACTIVE') {
        url = url + '/resume'
    } else {
        url = url + '/pause'
    }

    return httpPost(url, data, accountId)
        .then((res) => {
            return res;
        })
};


export const createSelectedField = (data, accountId) => {
    let url = '/service/customerData/mapping';

    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getColumnMappings = (accountId, contentId, contentType) => {
    let url = '/service/customerData/mapping/all?contentType=' + contentType;

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const editColumnValues = (accountId, data) => {
    return httpPut('/service/customerData/mapping', data, accountId)
        .then(res => {
            return res;
        })
};
export const getColoumnData = (accountId, contentType) => {
    let url = '/service/customerData/template/columns?contentType=' + contentType;

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const getContentImportErrorDataById = (runId, contentId, accountId) => {

    let url = '/service/customerData/contentImport/errorLogs?runId=' + runId + '&contentId=' + contentId;

    // 400 : Validation Error
    // 403 :Forbidden
    // 200 Ok  :

    return httpGet(url, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                case 201:
                case 204:
                    break;
                case 400:
                case 403:
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};


export const contentImport = (data, accountId, handleErrorInComponent) => {

    let url = '/service/customerData/contentImport';

    // 204 : no content
    // 400 : bad request
    // 200 Ok  :

    return httpPost(url, data, accountId, handleErrorInComponent)
        .then((res) => {
            return res;
        })
};

export const changeAutomaticImport = (data, accountId) => {

    let url = '/service/customerData/content/automaticImport';

    // 204 : no content
    // 400 : bad request
    // 200 Ok  :

    return httpPut(url, data, accountId)
        .then((res) => {
            return res;
        }).catch((err) => {
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const validateCSVFileData = (templateId, dataObj, accountId) => {

    let obj = {
        ...dataObj,
        modelDefinitionId: templateId,
        useExistingFile: false
    };

    // 400 -Validation Error
    // 200 - OK
    let url = "/service/customerData/template/verify?modelDefinitionId=" + templateId + "&useExistingFile=false";

    return httpPost(url, obj, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 200:
                    break;
                case 400:
                    // store.dispatch(showToastMessage('warning',res.__error));
                    break;
                default:
                    // store.dispatch(showToastMessage('warning',res.__error));
                    break;
            }
            return res;
        }).catch((err) => {
            log.debug({function: 'validateCSVFile', response: err, errorDescription: 'Error caught in default case'});
            store.dispatch(showSystemErrorBlock(err))
        })
};


export const deleteColumnMappings = (id, accountId) => {
    let url = '/service/customerData/mapping?id=' + id;

    return httpDelete(url, '', accountId)
        .then(res => {
            return res;

        })
};


export const getAllSenderEmails = (accountId) => {
    let url = '/service/customerData/settings/senderEmail/all';
    return httpGet(url, accountId)
        .then(res => {
            return res;

        })
};

export const verifySenderEmails = (dataObj, accountId) => {
    // 400 -Validation Error
    // 201 - OK
    let url = "/service/customerData/settings/senderEmail";

    return httpPost(url, dataObj, accountId)
        .then((res) => {
            return res;
        })
};

export const deleteSenderEmails = (dataObj, accountId) => {

    // Status Code: 204 - NO-CONTENT (success)
    // Status Code: 400 - Bad Request - Validation Errors

    let url = '/service/customerData/settings/senderEmail?emailId=' + dataObj.emailId;
    return httpDelete(url, dataObj, accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 204:
                    store.dispatch(showToastMessage('success', dataObj?.emailId + ' is removed.'));
                    break;
                case 401:
                case 403:
                case 400:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'deleteSenderEmails',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const resendSenderEmails = (dataObj, accountId) => {
    // 400 -Validation Error
    // 201 - OK
    let url = "/service/customerData/settings/senderEmail/resendEmail";

    return httpPost(url, dataObj, accountId)
        .then((res) => {
            switch (res?.statusCode) {
                case 201:
                case 200:
                    break;
                case 400:
                    store.dispatch(showToastMessage('warning', res.__error));
                    break;
                default:
                    store.dispatch(showSystemErrorBlock());
                    break;
            }
            return res;
        }).catch((err) => {
            log.debug({
                function: 'verifySenderEmails',
                response: err,
                errorDescription: 'Error caught in default case'
            });
            store.dispatch(showSystemErrorBlock(err))
        })
};

export const getDateTimeFormats = (accountId) => {
    let url = '/service/customerData/settings/dateFormat/all';
    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const deleteDateTime = (dateId, accountId) => {
    let url = '/service/customerData/settings/dateFormat?id=' + dateId?.id;
    return httpDelete(url, '', accountId)
        .then(res => {
            switch (res?.statusCode) {
                case 204:
                    store.dispatch(showToastMessage('success', dateId?.format + ' Format is deleted.'));
                    break;
                case 401:
                case 403:
                case 400:
                    store.dispatch(showToastMessage('warning', res?.__error));
                    break;
                default:
                    log.debug({
                        function: 'deleteDateTime',
                        response: res,
                        errorDescription: 'Error caught in default case'
                    });
                    store.dispatch(showSystemErrorBlock());
            }

            return res;
        })
        .catch(err => {
            log.debug({
                function: 'discardTemplate',
                error: err,
                errorDescription: 'Error caught in catch'
            });
            store.dispatch(showSystemErrorBlock());
        })
};

export const createFormat = (data, accountId) => {
    let url = '/service/customerData/settings/dateFormat';

    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const updateStatus = (data, accountId) => {
    let url = '/service/customerData/settings/senderEmail/updateStatus';

    return httpPost(url, data, accountId).then((res) => {
        switch (res?.statusCode) {
            case 200:
            case 204:
                break;
            case 400:
                store.dispatch(showToastMessage('danger', res?.__error));
                break;
            default:
                store.dispatch(showSystemErrorBlock());
                break;
        }
        return res;
    })
        .catch(err => {
                store.dispatch(showSystemErrorBlock(err));
            }
        );
};

export const CampaignStatusEdit = (data, accountId) => {
    let url = '/service/campaignManagement/campaign/changeMode';
    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const setImportHistoryFilter = (value) => dispatch => {
    dispatch(setFilter(value));
    dispatch(generateImportHistoryFilterUrl());
};

export const generateImportHistoryFilterUrl = () => dispatch => {

    let filter = store.getState().glacierState.importHistoryFilter;
    const {
        filterUrl, typeFilter
    } = filter;

    let urlBuilder = [];

    if (!isEmpty(typeFilter)) {
        if (isEmpty(typeFilter.contentId)) {
            urlBuilder.push('contentType=' + typeFilter.contentType);
        }
        if (isEmpty(typeFilter.contentType)) {
            urlBuilder.push('contentId=' + typeFilter.contentId);
        }
    }

    let urlEndpoints = '' + urlBuilder.join('&');
    let isFilterApplied = !isEmpty(urlEndpoints);


    if (filterUrl !== urlEndpoints) {
        dispatch(setImportHistoryFilterUrlData({
            filterUrl: urlEndpoints,
            isFilterApplied: isFilterApplied,
        }));
    }
};

export default async function displayRazorpay(object, profile, callback) {
    console.log('RAZORPAY_KEY_ID', process.env.RAZORPAY_KEY_ID);

    console.log('input object', object);
    const options = {
        key: process.env.RAZORPAY_KEY_ID,
        currency: object.currency,
        // name: "Deccanlogic Pvt Ltd",
        description: "Payment Request",
        // amount:object?.amount.toString(),
        // callback_url: '/accounts/plan/subscribe/successful',
        // image: "http://localhost:1337/logo-deccanlogic.png",
        order_id: object.order_id,
        handler: function (response) {
            callback({
                status: 'SUCCESS',
                ...response
            });
        },
        prefill: {
            name: object.name,
            email: object.email,
            contact: object?.mobile_no,

        },
        modal: {
            "ondismiss": function(){
                callback({
                    status: 'SUCCESS',
                    isModalClose:true
                });
            }
        }

    };


    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', (response) => {
        callback({
            status: 'FAILED',
            ...response
        })
        // paymentObject.close()
        // paymentObject.modal.dismiss();
        // paymentObject.dis
    });


};

export const verifyOrder = (order, detailsObject) => {
    let data = {
        orderId: order.razorpay_order_id,
        paymentId: order.razorpay_payment_id,
        razorpaySignature: order.razorpay_signature,
        ...order?.billingInfoObject,
        customisation: order?.customisation
    };
    let url = '/service/paymentManagement/payment/verifyOrder';
    return httpPost(url, data, detailsObject?.accountId).then((res) => {
        return res;
    })
};

export const getAmountWithGST = (amount, amountWithGst) => {
    let gstAmount;
    gstAmount = amountWithGst - amount;
    return numberWithCommas(parseFloat(gstAmount).toFixed(2));
};


export const getAllTemplateConstraintUpdate = (constarintId, accountId) => {
    return httpPost('/service/customerData/templateConstraint/markForUpdate?id=' + constarintId, {}, accountId)
        .then(res => {
            return res
        })
};

export const getAllTemplateConstraintDelete = (tempmodelDefId, accountId) => {
    return httpDelete('/service/customerData/templateConstraint/removeFromUpdate?modelDefinitionId=' + tempmodelDefId, {}, accountId)
        .then(res => {
            return res
        })
};


const uploadManualFileData = async (binaryFile, fileType,accountId) =>  {

    for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL+'/service/customerData/upload/document';


    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response =>{
            statusCode=response.status;
            return response.json()
        } )
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        success:true,
                        ...res
                    }
                }
                if (statusCode > 299 && statusCode <500){
                    store.dispatch( showToastMessage('warning',res.__error));
                    return null;
                }
            }
        }).catch(() => {
            store.dispatch(showSystemErrorBlock());
        });
};


export async function ManualUploadFile(data,accountId){

    let res = [];
    let files = data.attachments;
    if (files) {
        for (let index = 0; index < files.length; index++) {

            if (typeof document != 'undefined') {

                // I'm on the web!
                const formData = new FormData();
                formData.append('file', files[index]);

                let filePathVal;
                if( typeof files[index] === 'string'){
                    filePathVal = files[index];
                } else {
                    filePathVal = await uploadManualFileData(formData, files[index].type, accountId, data);
                }
                if (filePathVal) {
                    res.push(filePathVal);
                } else {
                    store.dispatch(showToastMessage('danger',"Failed to upload"));
                    res = false;
                    break;
                }
            }
        }
    }

    return res;
}

export const getEnablePushNotification = (data, accountId) => {

    let url = '/service/channels/push/configuration';
    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getUpdatePushNotification = (data, accountId) => {

    let url = '/service/channels/push/configuration';
    return httpPut(url, data, accountId).then((res) => {
        return res;
    })
};

export const getEnablePushNotificationData = (data, accountId) => {

    let url = '/service/channels/push/configuration';
    return httpGet(url,accountId).then((res) => {
        return res;
    })
};

export const getAddAppleConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/apple';
    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getUpdateAppleConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/apple';
    return httpPut(url, data, accountId).then((res) => {
        return res;
    })
};

export const getAppleConfiguration = (data, accountId) => {
    let url = '/service/channels/push/configuration/apple?id='+data;
    return httpGet(url, accountId).then((res) => {
        return res;
    })
};

export const getAddAndroidConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/google';
    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getUpdateAndroidConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/google';
    return httpPut(url, data, accountId).then((res) => {
        return res;
    })
};

export const getAndroidConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/google?id='+data;
    return httpGet(url, accountId).then((res) => {
        return res;
    })
};

export const getAddWebPushConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/webGoogle';
    return httpPost(url, data, accountId).then((res) => {
        return res;
    })
};

export const getWebPushConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/webGoogle?id='+data;
    return httpGet(url, accountId).then((res) => {
        return res;
    })
};

export const getUpdateWebPushConfiguration = (data, accountId) => {

    let url = '/service/channels/push/configuration/webGoogle';
    return httpPut(url, data, accountId).then((res) => {
        return res;
    })
};

export const getuploadP8File = async (data, accountId, contentType) => {
    let files = data.attachments;
    let res = [];

    if (files) {

        if (typeof document != 'undefined') {
            let filePathVal;
            // I'm on the web!
            const formData = new FormData();
            formData.append('file', files[0]);

            if (typeof files[0] === 'string') {
                filePathVal = files[0];
            } else {
                filePathVal = await uploadFileData(formData, contentType, accountId);
            }
            if (filePathVal) {
                res.push(filePathVal);
            } else {
                // store.dispatch(showToastMessage('danger',"Failed to upload"));
                res = false;
            }
        }
    }

    return res;
};


const uploadFileData = async (binaryFile, fileType, accountId) => {
     for (let key of binaryFile.entries()) {
    }
    // return
    let statusCode;
    let config = getAppEnvironmentVariables();
    let auth = await getAuthorizationToken();
    let url = config.REACT_APP_API_URL;
    if (fileType.includes('image')) {
        url += '/service/channels/push/media/upload/image';
    } else {
        url += '/service/channels/push/media/upload/document';
    }
    // return;

    return fetch(url,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + auth.token,
                'x-account-id': accountId,
            },
            body: binaryFile,
        })
        .then(response => {
            statusCode = response.status;
            return response.json()
        })
        .then((res) => {
            if (res) {
                if (statusCode === 200) {
                    return {
                        filePath: (res.filePath) ? res.filePath : '',
                        fileName: (res.fileName) ? res.fileName : '',
                        p8OriginalFileName: (res.originalFileName) ? res.originalFileName : ''
                    }
                }
                if (statusCode === 400) {
                    store.dispatch(showToastMessage('warning', "Upload only .pdf, .png, .jpeg and .docx format" +
                        " files."));
                    return false;
                } else {
                    store.dispatch(showToastMessage('danger', res.__error));
                    return null;
                }
            }
        }).catch(() => {
            store.dispatch(showSystemErrorBlock());
        });
};

export const getSecreteKey = (accountId,pushConfigId) => {

    let url = '/service/channels/push/configuration/viewScuidoSecretKey?pushConfigId='+pushConfigId;
    return httpGet(url, accountId).then((res) => {
        return res;
    })
};

export const getRegenerateSecreteKey = (data, accountId) => {

    let url = '/service/channels/push/configuration/regenerateAuthCredentials';
    return httpPut(url, data, accountId).then((res) => {
        return res;
    })
};


export const getContentCreateProcessId = (accountId) => {
    let url = '/service/customerData/content/initiateProcess';
    return httpPost(url, '', accountId).then((res) => {
        return res;
    })
};

export const displayRazorpayView = (object) => {
    console.log('RAZORPAY_KEY_ID', process.env.RAZORPAY_KEY_ID);

    console.log('input object', object);
    const options = {
        key: process.env.RAZORPAY_KEY_ID,
        currency: object.currency,
        description: "Payment Request",
        order_id: object.order_id,
        handler: function (response) {
            if (window.opener) {
                console.log('handler event', response);
                window.opener.postMessage({message:'payment_success', response:{...response,...object}}, window.location.origin);
                window.close();
            }
        },
        prefill: {
            name: object.name,
            email: object.email,
            contact: object?.mobile_no,
        },
        modal: {
            "ondismiss": function () {
                if (window.opener) {
                    console.log('ondismiss event');
                    window.opener.postMessage({message:'close_razarpay', object:{...object}}, window.location.origin);
                    window.close()
                }
            }
        }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', () => {
        if (window.opener) {
            console.log('payment failed event');
            window.opener.postMessage({message:'payment_failed', object:{...object}}, window.location.origin);
            window.close()
        }
    });
};