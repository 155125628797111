import React, {useEffect, useState} from 'react';
import PageNotAccessibleView from "./PageNotAccessibleView";
import {connect} from "react-redux";
import {setPrevLocation} from "../../actions/appActions";
import {withRouter} from "react-router-dom";
import {isEmpty} from "../../../utils/validations";
import {getAccountDetails2} from "../../../accountManagement/accountManagementActions";
import DLLoader from "../DLLoader/DLLoader";

const PageNotAccessible = (props) => {

  const [pageNotAccessibleState, setPageNotAccessibleState] = useState({
    fullUrl:'',
    prevPath:'',
    UIState:''
  });

  useEffect(()=>{

    let accountId = props.match.params.accountId;

    if (props.history.action === 'POP'){ //for handling back navigation (dont show again access denied)
      if (isEmpty(accountId)) {
        setPageNotAccessibleState((prev) => ({
          ...prev,
          fullUrl: props.history.location.state.fullUrl,
          prevPath: props.history.location.state.prevPath,
          UIState: 'CONTENT_AVAILABLE'
        }));
        return
      }
      getAccountDetails2(accountId)
          .then(res => {
            if (res.success) {
              props.history.push('/accounts/'+accountId+'/dashboard');
              setPageNotAccessibleState((prev)=>({
                ...prev,
                UIState: 'CONTENT_AVAILABLE'
              }));
            }else {
              props.history.push('/accounts/dashboard');
            }
          }
        )
    }else {
      setPageNotAccessibleState((prev)=>({
        ...prev,
        fullUrl:props.history.location?.state?.fullUrl,
        prevPath:props.history.location?.state?.prevPath,
        UIState: 'CONTENT_AVAILABLE'
      }))

    }
  },[]);

  const onHomeClick = () =>{
    const {account} = props;
    if (isEmpty(account?.id)){
      props.history.push('/accounts/dashboard')
    }else {
      props.history.push('/accounts/'+account?.id+'/dashboard')
    }
  };

  if (isEmpty(pageNotAccessibleState.UIState)){
    return <DLLoader isVisible={true}/>
  }

    return (
      <PageNotAccessibleView
        {...props}
        {...pageNotAccessibleState}
        onHomeClick={onHomeClick}
      />
    );
};

const mapStateToProps = (state) => ({
  account: state.accountManagementState.account,
  isMobileDevice: state.appState.deviceInfo.isMobileDevice,
  isMobileView: state.appState.deviceInfo.isMobileView,

});

export default connect(mapStateToProps, {
  setPrevLocation
})(withRouter(PageNotAccessible));
