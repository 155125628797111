import {createSlice} from "@reduxjs/toolkit";

const initialFilter = {
    filterUrl: "",
    isFilterApplied: false,
    typeFilter: {}
};


const appSlice = createSlice({
    name: 'glacierReducer',
    initialState: {
        s3Regions: [],
        s3RegionsLoaded: false,
        createdUniqueCombinations: [],
        importHistoryFilter: {
            ...initialFilter,
        },
        isContentRender: false,
        importData: {},
        dataImport: false,
        isContentCreated: false,
        content: {},
        isTemplateDefinationCreated: false,
        template: {},
        isdataEdited:false
    },
    reducers: {
        userLogoutData3(state) {
            state.s3Regions = [];
            state.s3RegionsLoaded = false;
            state.createdUniqueCombinations = [];
            state.importHistoryFilter = {
                filterUrl: "",
                isFilterApplied: false,
                typeFilter: {}
            }
        },
        getAllRegionData(state, action) {
            state.s3Regions = action.payload;
            state.s3RegionsLoaded = true;
        },
        createUniqueCombinationData(state, action) {
            state.createdUniqueCombinations = state.createdUniqueCombinations?.concat(action?.payload);

        },
        setFilter(state, action) {
            switch (action.payload.type) {
                case 'CLEAR_FILTER':
                    return {
                        importHistoryFilter: {
                            ...state.importHistoryFilter,
                            ...initialFilter,
                        },
                    };
                case 'typeFilter':
                    return {
                        importHistoryFilter: {
                            ...state.importHistoryFilter,
                            typeFilter: action.payload.value
                        }
                    };

            }
        },
        setImportHistoryFilterUrlData(state, action) {
            state.importHistoryFilter = {
                ...state.importHistoryFilter,
                filterUrl: action.payload.filterUrl,
                isFilterApplied: action.payload.isFilterApplied,
            }
        },
        renderContentListPage: function (state, action) {
            state.isContentRender = action.payload.isContentRender,
            state.importData = action.payload.importData,
            state.dataImport = action.payload.dataImport
        },
        resetContentListPage: function (state, action) {
            state.isContentRender = false,
            state.importData = {},
            state.dataImport = false
        },
        setContentCreated(state, action) {
            state.isContentCreated = action.payload.isContentCreated,
            state.content = action.payload.content
        },
        resetContentCreateState(state, action) {
            state.isContentCreated = false,
            state.content = {}
        },
        setTemplateDefination(state, action) {
            state.isTemplateDefinationCreated = action.payload.isTemplateDefination,
            state.template = action.payload.template
        },
        resetTemplateCreateState(state, action) {
          state.isTemplateDefinationCreated = false,
          state.template = {}
        },
        resetImportHistoryFilterData(state) {
            state.importHistoryFilter = {
                filterUrl: "",
                isFilterApplied: false,
                typeFilter: {}
            };
        },
        setDataEditStatus(state,action) {
            state.isdataEdited = action.payload.isDataTypeEdited
        },
    }
});

export const {
    userLogoutData3, getAllRegionData, createUniqueCombinationData, resetContentListPage, renderContentListPage, setContentCreated, setTemplateDefination,
    setFilter, setImportHistoryFilterUrlData, resetContentCreateState, resetImportHistoryFilterData,setDataEditStatus
} = appSlice.actions;
export default appSlice.reducer

