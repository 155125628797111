import { configureStore } from '@reduxjs/toolkit'
 import appReducer from "../src/core/reducers/appReducer"
import glacierReducer from "./glacier/glacierReducer";
import campaignReducer from "./campaign/campaignReducer";
import userReducer from "./user/userReducer"
import accountManagementReducer from "./accountManagement/accountManagementReducer";
import accountServiceReducer from "./accountService/paymentReducer";
import inboxReducer from "./inbox/inboxReducer";
import contentReducer from "./subscribersContent/contentReducer";
import templateReducer from "./glacier/whatsApp/templateReducer";
import pricingReducer from "./pricingSlider/pricingReducer";


const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    accountManagementState: accountManagementReducer,
    accountServiceState: accountServiceReducer,
    glacierState: glacierReducer,
    inboxState: inboxReducer,
    campaignState: campaignReducer,
    contentState: contentReducer,
    templateState: templateReducer,
    pricingState: pricingReducer
  }
});
export default store;
