import {createSlice} from "@reduxjs/toolkit";

const allFilter = {
    proPlanEmailSliderValue : 10000,
    proPlanPushSliderValue : 0,
    proPlanWhatsAppSliderValue : 0,

    businessPlanEmailSliderValue : 10000,
    businessPlanPushSliderValue : 0,
    businessPlanWhatsAppSliderValue : 0,

    planNameTabValue : "",
    planDurationTabValue : "",

    billingEmailValue: "",
    billingPhoneNumberValue: "",
    billingGstinValue : "",
    billingAddressValue : "" ,
    billingCityValue : "",
    billingStateName : "",
    billingPinValue : "",
    timeZoneValue : ""

};

const appSlice = createSlice({
    name: 'pricingReducer',
    initialState: {
        screen: '',
        pricingFilter: {
            ...allFilter
        },
    },
    reducers: {
        sliderFilter(state, action) {
            state.pricingFilter = {
                ...state.pricingFilter,
                [action.payload.name]: action.payload.value,
            }
        },
        resetSliderFilter(state){
            state.pricingFilter = {
                ...allFilter
            }
        },
        saveData(state, action){
            let tempObj = {
            }
            if(action.payload) {
                tempObj = {
                    ...tempObj,
                    ...action.payload
                }
            }
            state.pricingFilter = {
                ...state.pricingFilter,
                ...tempObj
            }
        }
    }
});

export const {
    sliderFilter, resetSliderFilter, saveData
} = appSlice.actions;

export default appSlice.reducer