import React, {useState} from 'react';
import SideMenuOptionsView from "./SideMenuOptionsView";
import {connect} from "react-redux";
import {resetEmailTemplateFilter, resetQuickStart} from "../../../campaign/campaignAction";
import {setImportHistoryFilter} from "../../../glacier/glacierActions";
import {changeDrawerStatus} from "../../actions/appActions";
import {refreshSegmentsData} from "../../../campaign/campaignReducer";
import {withRouter} from "react-router-dom";
import {refreshWindowScrollElement} from "../../../utils/helper";

SideMenuOptions.propTypes = {

};

function SideMenuOptions(props) {

    return (
        <SideMenuOptionsView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isXSView: state.appState.deviceInfo.isXSView,
    currentPathname: state.appState.currentPathname,
});

export default connect(mapStateToProps, {
    resetEmailTemplateFilter,
    setImportHistoryFilter,
    changeDrawerStatus,
    resetQuickStart,
    refreshSegmentsData,
    refreshWindowScrollElement
})(withRouter(SideMenuOptions));