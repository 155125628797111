import React, {useEffect} from 'react';
import DLText from 'dlcomponent/components/DLText/DLText';
import {isEmpty} from "../../utils/validations";
import PageNotFound from "../../core/components/PageNotFound/pageNotFound"
import appTheme from "../../assets/appTheme";


const InboxDetailView = (props) => {
    const {templateData, dateFormat, isXSView} = props;

    let previewData = templateData.body;
    let text = 'style="box-sizing: border-box;';
    if (!isEmpty(previewData) && isXSView) {
        let result = previewData.includes(text);
        if (result) {
            previewData = previewData.replace(text, 'id="wrapper-1" style="box-sizing: border-box;transform:scaleX(0.7);transform-origin:left;margin:auto')
        }
    }else{
        previewData = previewData.replace(text, 'id="wrapper-1" style="box-sizing: border-box;margin:auto')
    }
    useEffect(() => {
        const templateContent = document.getElementById('template-content');
        if (templateContent) {
            templateContent.addEventListener('click', (e) => handleOtherClick(e));
        }
    }, []);

    const handleOtherClick = (e) => {
        const {getReadStatus} = props;

        if (e.target.nodeName === "A") {
            getReadStatus('markAsClick');
        }
    };


    const getSenderName = () => {
        const {templateData} = props;

        if (isEmpty(templateData?.senderName)) {
            return templateData.from
        }
        return templateData.senderName + " (" + templateData.from + ")"
    };

    const renderAttchmentsUi = () => {

        return(<div className='d-flex justify-content-between border-top mt-2'>
            <div className="image-upload d-flex align-items-start justify-content-start pt-2 mt-1 flex-shrink-0"
                 style={{minHeight: '50px'}}>
                <div>
                    <DLText id={''}
                            text={'Attachment'}
                            isInline={true}
                            fontSize={'md'}
                            marginBottom={"none"}/>
                </div>
            </div>
            <div className={'d-sm-flex w-100 px-2 flex-wrap'}>
                {
                    templateData?.attachments?.map((file, index) => {
                        return (
                            <div key={"campaignDetails" + index}
                                 className={"border border-rounded px-2 my-2 py-2 d-flex justify-content-between align-items-center me-2 flex-wrap"}
                                 style={{maxWidth: isXSView ? "" : "calc((100% - 130px)/" + templateData?.attachments?.length, minWidth:'150px'}}>

                                    <span className="text-truncate cursor"
                                          style={{fontSize: '14px',color:appTheme.linkStyle.color,fontWeight:500}}>
                                        <a href={file?.path}>
                                                {file?.filename}
                                        </a>
                                    </span>


                            </div>
                        )
                    })
                }
            </div>
        </div>)
    };

    const renderEmailList = () => {
        return (
            <>
                <div className='fixedHeader'>
                    <div className='header-container'>
                        <div className='d-flex w-100 align-items-center'>
                            <i className="fa-solid fa-chevron-left cursor pe-2 header-icon"
                               onClick={() => {props.history.goBack();}}/>
                            <div className='pe-2 header-title'>
                                {'Email: '}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pageContainer'>
                        <div className='d-flex'>
                            <div className='label pr-2'>From :</div>
                            <DLText id={"InboxDetailView-From-value"}
                                    fontWeight={"semi-bold"}
                                    text={getSenderName()} isInline={true}
                                    marginBottom={"none"}/>
                        </div>
                        <div className='d-flex'>
                            <div className='label pr-1'> Received :</div>
                            <DLText id={"InboxDetailView-Received-value"}
                                    fontWeight={"semi-bold"}
                                    text={dateFormat(templateData.deliveredOn)} isInline={true}
                                    marginBottom={"none"}/>
                        </div>
                        <div className='d-flex'>
                            <div className='label pr-1'>Recipient :</div>
                            <DLText id={"InboxDetailView-To-value"}
                                    fontWeight={"semi-bold"}
                                    text={templateData?.ownerEmailId}
                                    isInline={true}/>
                        </div>
                        <div className='d-flex'>
                            <div className='label pr-1'>Subject :</div>
                            <DLText id={"InboxDetailView-Subject-Value"}
                                    fontWeight={"semi-bold"}
                                    text={templateData.subject}
                                    isInline={true} marginBottom={"none"}/>
                        </div>
                        <div>
                            {(templateData?.attachments?.length >1 ) && renderAttchmentsUi()}
                        </div>

                        <div className='border-top pb-3 w-100 h-100'/>
                        <iframe scrolling="yes" width={'100%'} id={'template-content2'}
                                style={{height: '80vh'}}
                                srcDoc={previewData}
                        />
                </div>
            </>
        );
    };
    const renderPageNotFound = () => {
        return (
            <div>
                <PageNotFound {...props} />
            </div>
        );
    };
    return (
        <div>
            {(!isEmpty(templateData)) ? renderEmailList() : renderPageNotFound()}
        </div>
    );
};

InboxDetailView.propTypes = {};

export default InboxDetailView;
