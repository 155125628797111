import React from 'react';
import {connect} from "react-redux";
import {DLText} from "dlcomponent";

const s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function SiteUnderMaintenance() {

    return <>
        <div className='d-flex justify-content-center align-items-center'
             style={{height: '100vh'}}>
            <div>
                <div className="d-flex justify-content-center align-item-center" id={'under-maintenance-image'}>
                    <img
                        src={s3BaseURL + '/images/general/underMaintaince.jpg'}
                        alt={'img'}
                        className={'img-fluid'}
                        width={'400px'}
                        height={'300px'}/>
                </div>

                <div className='pt-2'>
                    <DLText id={'under-maintenance-text'}
                            text={'We are under maintenance!'}
                            customWrapperStyle={{textAlign: 'center'}}
                            marginBottom={"sm"}
                            type={"normal"}
                            fontSize={"lg"}
                            fontWeight={"bold"}/>

                    <DLText id={'wil-be-back-text'}
                            text={'We will be back soon!'}
                            customWrapperStyle={{textAlign: 'center'}}
                            marginBottom={"sm"}
                            type={"normal"}
                            fontSize={"md"}
                            fontWeight={"normal"}/>

                </div>
            </div>
        </div>
    </>
}

SiteUnderMaintenance.propTypes = {};

export default connect()(SiteUnderMaintenance)