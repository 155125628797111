const appTheme = {
    appColor: {
        primary: '#1E53BD',
        darkBlue: '#0B407D',
        secondary: '#FEA133',
        success: '#029C48',
        warning: '#E18600',
        danger: '#D72F2F',
        gray: '#999999',
        lightGray: '#c5c5c5',//lightGray
        darkGray: '#666666',
        black: '#333333',
        white: '#fff',
        info: '#03a9f4'
    },
    linkStyle: {
        color: '#0073ff'
    },
    default: {
        fontSize: 14,
        color: '#333333',
        // fontFamily: "'Poppins', sans-serif",
        fontFamily: "Barlow",
        btnBorderType: 'square'
    },
};

export default appTheme;
