import {createSlice} from '@reduxjs/toolkit'

const appSlice = createSlice({
    name: 'appReducer',
    initialState: {
        accounts: [],
        accountsLoaded: false,
        account: {},
        accountLoaded: false,
        isAccountAdmin: false,
        isFirstAccountCallFired : false,
        users: [],
        usersLoaded: false,
        userDetails: {},
        userDetailsLoaded: false,
        userList: [],
        usersListLoaded: false,
        groups: [],
        groupsLoaded: false,
        isGroupsLoaded: false,
        group: {},
        isGroupLoaded: false,
        roles: [],
        rolesLoaded: false,
        myPendingInvitations: [],
        isMyPendingInvitationsLoaded: false,
        isSelected:'User',
        isAccountSelected: false,
        isYearlyPlan: true,
        accountUsers: {},
        accountOwnerName: '',
        isUpdateAccounts:false,
        isAccountsCallFired : false
    },
    reducers: {
        getAllAccountsData(state, action) {
                state.accounts= action.payload.accounts;
               state.accountsLoaded= true
        },
        resetAllAccount(state) {
            state.accounts= [];
            state.accountsLoaded= false
        },
        resetAccount(state) {
                state.account= [];
                state.accountLoaded= false;
                state.isAccountAdmin= false
        },

        getAccountDetailsData(state,action) {
                state.account= action.payload;
                state.accountLoaded= true
         },
        getUserData(state,action) {
                state.userDetails= action.payload;
                state.userDetailLoaded= true
         },

        getAccountDetails2Data(state,action) {
                state.account= action.payload.data;
                state.accountLoaded= true;
                state.isAccountAdmin= action.payload.isAccountAdmin
         },

        setAccountUser(state,action) {
                state.accountUsers= action.payload.data;
                state.accountOwnerName= action.payload.ownerName;
         },

        setAccountAdminData(state) {
                 state.isAccountAdmin= true
        },

        getUserListData(state, action) {
            state.userList= action.payload.members;
            state.usersListLoaded= true
        },
        getRoles(state, action) {
            state.roles= action.payload;
            state.rolesLoaded= true
        },
        getGroups(state, action) {
            state.groups= action.payload.groups;
            state.groupsLoaded= true
        },
        resetGroupListData(state) {
            state.groups= [];
            state.groupsLoaded= false
        },
        getMyPendingInvitationData(state, action) {
             state.myPendingInvitations= action.payload?.invitations;
            state.isMyPendingInvitationsLoaded= true
        },
        resetMyPendingInvitationData(state) {
            state.isMyPendingInvitationsLoaded= false
        },
        getGroupListData(state, action) {
            state.groups= action.payload.groups;
            state.isGroupsLoaded= true
        },
        setSwitchBtn(state, action) {

            state.isSelected= action.payload;
        },
        setAccountSelected(state, action) {
            state.isAccountSelected= action.payload;
        },
        setYearAndMonth(state, action){
            state.isYearlyPlan= action.payload
        },
        updateAccounts(state, action){
            state.isUpdateAccounts = !state.isUpdateAccounts
        },
        userLogout2(state) {
            state.accounts= [];
            state.accountsLoaded= false;
            state.account= {};
            state.accountLoaded= false;
            state.isAccountAdmin= false;
            state.users= [];
            state.usersLoaded= false;
            state.userDetails= {};
            state.userDetailsLoaded= false;
            state.userList= [];
            state.usersListLoaded= false;
            state.groups= [];
            state.groupsLoaded= false;
            state.isGroupsLoaded= false;
            state.group= {};
            state.isGroupLoaded= false;
            state.roles= [];
            state.rolesLoaded= false;
            state.myPendingInvitations= [];
            state.isMyPendingInvitationsLoaded= false;
            state.isYearlyPlan=true;
            state.isAccountSelected=false;
            // state.isSelected=false;
        },
        refreshAccount(state, action) {
            state.fireAccountCall= action.payload;
        },
        setAccountsCallFired(state,action){
            state.isAccountsCallFired= action.payload;
        }
    }
});

export const {getAllAccountsData, updateAccounts, resetAllAccount, setYearAndMonth, resetAccount, getAccountDetails2Data,resetMyPendingInvitationData, setAccountAdminData, getUserListData, getRoles,setAccountSelected,refreshAccount,
                getMyPendingInvitationData, setSwitchBtn, setAccountUser, resetGroupListData, getGroupListData, getUserData,userLogout2, getGroups,setAccountsCallFired } = appSlice.actions;
export default appSlice.reducer

